import {
  CloudUploadOutlined, EditOutlined, ExportOutlined, InboxOutlined
} from '@ant-design/icons';
import {
  Button, Progress, Space, Typography, message
} from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { UploadChangeParam, UploadFile } from 'antd/es/upload/interface';
import Modal from 'antd/lib/modal/Modal';
import { ColumnsType } from 'antd/lib/table';
import { FineUploaderBasic } from 'fine-uploader/lib/core';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import PageTable from 'common/components/PageTable';
import { refreshTokenService } from 'common/services/authenticate';
import { getAccessToken, setAccessToken, setRefreshToken } from 'common/services/common/storage';
import {
  exportFETranslationsService, getFETranslationsService,
  importFETranslationsService, updateFETranslationsService
} from 'common/services/translations';
import { URL_CONST } from 'common/utils/constant';
import roles from 'configs/roles';

type PageProps = {
  id: number;
  group: string;
  keyCode: string;
  text: {
    en?: string;
    vi?: string;
  };
};

type TranslationFormData = {
  id: number;
  vi: string;
  en: string;
};

type FileUploaded = {
  id: string;
  status: 'done' | 'error',
  success?: string;
  error?: string;
};

const FETranslationsManagement: React.FC<ActiveRoles> = ({
  roleUpdate, roleOther
}) => {
  const { t } = useTranslation();
  /* Search Params */
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get('page');

  /* Selectors */
  const { defaultPageSize } = useAppSelector((state) => state.system);
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [uploadList, setUploadList] = useState<FileUploaded[]>([]);
  const [percent, setPercent] = useState<number>(0);
  const queryClient = useQueryClient();

  const queryKey = ['pages', 'fe-translations', currentPage, keyword, currentView];

  const {
    isLoading,
    data: pageData,
  } = useQuery(
    queryKey,
    () => getFETranslationsService({ keyword, page: currentPage, limit: currentView }),
    { keepPreviousData: true, enabled: !!currentPage }
  );

  const { mutate: exportMutate, isLoading: exportLoading } = useMutation(
    ['feTranslation-export'],
    exportFETranslationsService,
    {
      onSuccess(res) {
        if (res) {
          window.open(
            res,
            '_blank',
          );
        }
      },
      onError: () => {
        message.error(t('message.uploadError'));
      }
    }
  );

  const { mutate: importMutate, isLoading: importLoading } = useMutation(
    ['feTranslation-import'],
    importFETranslationsService,
    {
      onSuccess() {
        setImportModal(false);
        queryClient.invalidateQueries(queryKey);
        message.success(t('Import success'));
      },
      onError: () => {
        message.error(t('message.uploadError'));
      }
    }
  );
  const token = getAccessToken();

  const uploader = new FineUploaderBasic({
    autoUpload: false,
    request: {
      endpoint: URL_CONST.MEDIA_FILE_UPLOAD_CHUNK,
      customHeaders: {
        Authorization: `Bearer ${token}`,
      },
    },
    chunking: {
      enabled: true,
      mandatory: true,
      partSize: 1000000, // 1MB,
    },
    callbacks: {
      onTotalProgress(totalUploadedBytes: number, totalBytes: number) {
        if (!!totalUploadedBytes && !!totalUploadedBytes) {
          setPercent(totalUploadedBytes / totalBytes * 100);
        }
      },
      onComplete(id: number, name: string, res: {
        success: boolean;
        data?: Object;
      }, xhr: XMLHttpRequest) {
        const uuid = uploader.getUuid(id);
        if (!res.success) {
          const errors = JSON.parse(xhr.response).errors as ErrorResponse[];
          setUploadList((prev) => [...prev, { id: uuid, status: 'error', error: errors[0].message }]);
        } else {
          setUploadList((prev) => [...prev, { id: uuid, status: 'done', success: 'true' }]);
        }
      },
      onError: async (id: number, name: string, errorReason: string, xhr: XMLHttpRequest) => {
        if (xhr.status === 401) {
          await refreshTokenService()
            .then((data) => {
              setAccessToken(data.accessToken);
              setRefreshToken(data.refreshToken);
              uploader.setCustomHeaders({ Authorization: `Bearer ${data.accessToken}` });
              uploader.retry(id);
            });
        }
      },
    }
  });

  const handleChangeFileList = (info: UploadChangeParam<UploadFile>) => {
    const list: UploadFile[] = [...info.fileList].map((file) => ({ ...file }));
    setPercent(0);
    uploader.addFiles(list.map((ele) => ele.originFileObj));
    uploader.uploadStoredFiles();
  };

  const method = useForm<TranslationFormData>({
    mode: 'onSubmit',
    defaultValues: {
      id: 0,
      vi: '',
      en: '',
    },
  });

  const handleSearch = (val: string) => {
    setKeyword(val);
  };

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() }, { replace: true });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  const columns: ColumnsType<PageProps> = [
    {
      title: 'ID',
      key: 'id',
      width: 75,
      align: 'center',
      render: (_name: string, data: PageProps) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    {
      title: t('system.group'),
      dataIndex: 'group',
      key: 'group',
      sorter: {
        compare: (a: PageProps, b: PageProps) => a.group.localeCompare(b.group),
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: PageProps) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.group}
        </Typography.Text>
      ),
    },
    {
      title: t('system.code'),
      dataIndex: 'key',
      key: 'key',
      sorter: {
        compare: (a: PageProps, b: PageProps) => a.keyCode.localeCompare(b.keyCode),
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: PageProps) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.keyCode}
        </Typography.Text>
      ),
    },
    {
      title: t('system.content'),
      dataIndex: 'text',
      key: 'text',
      render: (_name: string, data: PageProps) => (
        <div>
          <Typography.Text
            style={{ color: '#4a4a4a', cursor: 'pointer' }}
          >
            <strong style={{ color: '#006F3A' }}>
              {'vi: '}
            </strong>
            {data?.text?.vi}
          </Typography.Text>
          <br />
          <Typography.Text
            style={{ color: '#4a4a4a', cursor: 'pointer' }}
          >
            <strong style={{ color: '#006F3A' }}>
              {'en: '}
            </strong>
            {data?.text?.en}
          </Typography.Text>
        </div>
      ),
    },
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, data: PageProps) => (
        <Button
          disabled={!roleUpdate}
          icon={<EditOutlined />}
          onClick={() => {
            method.setValue('id', data.id);
            method.setValue('vi', data?.text?.vi || '');
            method.setValue('en', data?.text?.en || '');
            setIsOpen(true);
          }}
        />
      ),
    },
  ];

  const tableData: PageProps[] = useMemo(
    () => pageData?.data?.map((x) => ({ ...x, keyCode: x.key })) || [],
    [pageData?.data]
  );

  const updateTranslationAction = async (data: TranslationFormData) => {
    try {
      setLoading(true);
      await updateFETranslationsService(data);
      await queryClient.invalidateQueries(queryKey);
      setIsOpen(false);
    } catch (error) {
      // Error
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.feTranslations')}
        rightHeader={
          (
            <Space>
              <Button
                type="default"
                disabled={!roleOther.includes(roles.CMS_TRANSLATION_IMPORT)}
                onClick={() => setImportModal(true)}
              >
                <CloudUploadOutlined />
                {t('system.import')}
              </Button>
              <Button
                type="primary"
                disabled={!roleOther.includes(roles.CMS_TRANSLATION_EXPORT)}
                loading={exportLoading}
                onClick={() => exportMutate()}
              >
                <ExportOutlined />
                {t('system.export')}
              </Button>
            </Space>
          )
        }
      />

      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={loading}
          handleSearch={handleSearch}
          noCheckbox
          tableProps={{
            initShowColumns: ['id', 'group', 'key', 'text', 'action'],
            noBaseCol: true,
            columns,
            pageData: tableData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: pageData?.meta.total,
            noDeleteLanguage: true,
          }}
        />
      </div>

      <Modal
        title={<Typography.Title level={3}>{t('translations.feTitle')}</Typography.Title>}
        visible={isOpen}
        centered
        okText={t('system.ok')}
        cancelText={t('system.cancel')}
        onCancel={() => {
          setIsOpen(false);
          method.reset();
        }}
        onOk={method.handleSubmit(updateTranslationAction)}
        confirmLoading={loading}
      >
        <FormProvider<TranslationFormData> {...method}>
          <form noValidate>
            <div className="t-menuManagement_input">
              <Controller
                name="vi"
                render={({
                  field: { onChange, value, ref },
                }) => (
                  <>
                    <Typography.Text strong>
                      {t('system.languageVi')}
                    </Typography.Text>
                    <Input
                      ref={ref}
                      onChange={onChange}
                      value={value}
                      placeholder={t('system.languageVi')}
                    />
                  </>
                )}
              />
            </div>
            <div className="t-menuManagement_input">
              <Controller
                name="en"
                render={({
                  field: { onChange, value, ref },
                }) => (
                  <>
                    <Typography.Text strong>
                      {t('system.languageEn')}
                    </Typography.Text>
                    <Input
                      ref={ref}
                      onChange={onChange}
                      value={value}
                      placeholder={t('system.languageVi')}
                    />
                  </>
                )}
              />
            </div>
          </form>
        </FormProvider>
      </Modal>
      <Modal
        title={<Typography.Title level={3}>{t('system.import')}</Typography.Title>}
        visible={importModal}
        centered
        okText={t('system.ok')}
        cancelText={t('system.cancel')}
        onCancel={() => {
          setImportModal(false);
          setUploadList([]);
        }}
        onOk={() => {
          if (uploadList.length > 0) {
            importMutate({ fileExtension: 'xlsx', fileUuid: uploadList[0].id });
          }
        }}
        confirmLoading={importLoading}
      >
        {!!percent && (
          <div className="t-uploadModal_progress">
            <Progress percent={Math.round(percent)} size="small" />
          </div>
        )}
        <Dragger
          beforeUpload={() => false}
          onChange={handleChangeFileList}
          accept=".xlsx"
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{t('media.dndText')}</p>
          <p className="ant-upload-hint">
            {t('media.dndSubText')}
          </p>
        </Dragger>
      </Modal>
    </>
  );
};

export default FETranslationsManagement;
