import { MinusCircleOutlined, PlusCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Card, Space, Spin,
  message
} from 'antd';
import React, { useEffect } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import PhasesFormArray from './PhaseFormArray';

import HeaderPage from 'common/components/HeaderPage';
import {
  getSpecialPrizeConfigService, saveSpecialPrizeConfigService
} from 'common/services/configPrizes';
import { PrizeFormData } from 'common/services/configPrizes/types';
import { getProvincesListService } from 'common/services/locations';
import { firstPrizeConfigSchema } from 'common/utils/schemas';

const ConfigSpecialPrize: React.FC<ActiveRoles> = ({ roleCreate, roleUpdate }) => {
  /* Hooks */
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  /* Variables */
  const editable = roleCreate || roleUpdate;
  const method = useForm<PrizeFormData>({
    resolver: yupResolver(firstPrizeConfigSchema),
  });

  const { fields, append, remove } = useFieldArray({ control: method.control, name: 'phases' });

  /* Queries */
  const { data: specialConfigData, isLoading } = useQuery('config-special-prize', getSpecialPrizeConfigService);
  const { data: provinces, isLoading: provinceLoading } = useQuery(['getProvinces'], getProvincesListService);

  const { mutate: saveMutate, isLoading: saveLoading } = useMutation(
    'saveSpecialPrizeConfig',
    saveSpecialPrizeConfigService,
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries('config-special-prize');
      },
      onError: () => {
        message.error(t('message.updateError'));
      }
    }
  );

  const handleSave = async () => {
    const valid = await method.trigger();

    if (valid) {
      const data = method.getValues();
      saveMutate(data);
    }
  };

  useEffect(() => {
    if (!specialConfigData) return;
    method.reset(specialConfigData.phases.length > 0 ? {
      phases: specialConfigData.phases.map((item) => ({
        phase: item.phase,
        from: new Date(item.from).toISOString(),
        to: new Date(item.to).toISOString(),
        quantity: item.quantity,
        quantityBackup: item.quantityBackup,
        provinceIds: item.provinces?.map((province) => (
          {
            items: province.items.map((it) => it.id),
            quantity: province.quantity,
          })),
        provinceBackupIds: item.provinceBackups?.map((province) => (
          {
            items: province.items.map((it) => it.id),
            quantity: province.quantity,
          })),
      }))
    } : {
      phases: [
        {
          phase: 1,
          from: '',
          to: '',
          quantity: 0,
          quantityBackup: 0,
          provinceIds: [{
            items: [],
            quantity: 0,
          }],
          provinceBackupIds: [{
            items: [],
            quantity: 0,
          }]
        }
      ]
    });
  }, [method, specialConfigData]);

  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.configSpecialPrize')}
        rightHeader={(
          <Button
            type="primary"
            loading={saveLoading}
            disabled={!editable}
            onClick={handleSave}
          >
            <SaveOutlined />
            {t('system.save')}
          </Button>
        )}
      />
      <Spin size="large" spinning={isLoading}>
        <div className="t-mainlayout_wrapper">
          <FormProvider {...method}>
            {fields.map((field, index) => (
              <Card
                key={`prizeConfig-phases-${field.phase || new Date().getTime()}`}
                title={t('prizeConfig.phase')}
                extra={fields.length > 1 && editable && (
                  <div onClick={() => remove(index)}>
                    <MinusCircleOutlined style={{ fontSize: '20px', color: 'red', cursor: 'pointer' }} />
                  </div>
                )}
                style={{ marginBottom: '20px' }}
              >
                <PhasesFormArray
                  editable={editable}
                  index={index}
                  method={method}
                  provinces={provinces ? provinces.map((item) => (
                    { label: item.name, value: item.id })) : []}
                  provinceLoading={provinceLoading}
                />
              </Card>
            ))}
          </FormProvider>
          {editable && fields.length < 3 && (
            <Space style={{ width: '100%' }} direction="vertical" align="center">
              <Button
                type="primary"
                onClick={() => append({
                  from: '', to: '', quantity: 0, quantityBackup: 0, provinceIds: [{ items: [], quantity: 0 }]
                })}
              >
                <PlusCircleOutlined />
                {t('prizeConfig.addPhase')}
              </Button>
            </Space>
          )}
        </div>
      </Spin>
    </>
  );
};

export default ConfigSpecialPrize;
