/* eslint-disable @typescript-eslint/no-unused-vars */
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Card, Col, DatePicker, Row,
  Space,
  Typography
} from 'antd';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { Controller, UseFormReturn, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line import/no-cycle
import { TopupConfigForm } from '.';

import { DropdownElement } from 'common/components/DropdownType';
import Input from 'common/components/Input';
import { TopupConfigFormData } from 'common/services/configPrizes/types';
import { IDistrict } from 'common/services/locations/types';
import { regionTypes } from 'common/utils/constant';

type Props = {
  // provinceLoading?: boolean;
  // districtLoading?: boolean;
  // provinces?: OptionType[];
  // districts?: IDistrict[];
  editable?: boolean;
  method: UseFormReturn<TopupConfigForm>;
};

type DistrictElementProps = {
  loading?: boolean;
  index: number;
  districts: IDistrict[];
  method: UseFormReturn<TopupConfigForm>;
};

// const DistrictElement: React.FC<DistrictElementProps> = ({
//   index, districts, method, loading
// }) => {
//   const { t } = useTranslation();
//   const provinceIds = method.watch(`quotas.${index}.provinceIds`);
//   const districtOptions = useMemo(() => (
//     districts.filter((district) => provinceIds?.includes(district.provinceId))
//       .map((district) => ({
//         label: district.name,
//         value: district.id,
//       }))
//   ), [districts, provinceIds]);
//   return (
//     <Controller
//       name={`quotas.${index}.districtIds`}
//       control={method.control}
//       render={({
//         field: { value, onChange },
//         fieldState: { error },
//       }) => (
//         <DropdownElement
//           loading={loading}
//           options={districtOptions}
//           size="large"
//           placeholder={`${t('system.select')} ${t('location.district')}`}
//           locale="vi"
//           value={value}
//           multiple={{
//             allowClear: true,
//           }}
//           onChange={(e) => {
//             onChange(e);
//           }}
//           error={error?.message}
//         />
//       )}
//     />
//   );
// };

const QuotaFormArray: React.FC<Props> = ({ editable, method }) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({ control: method.control, name: 'quotas' });
  return (
    <div>
      {fields.map((field, index) => (
        <Card
          key={`topup-config-quotas-${index.toString()}`}
          title={`${t('topupConfig.quotas')} ${index + 1}`}
          extra={fields.length > 1 && editable && (
            <div onClick={() => remove(index)}>
              <MinusCircleOutlined style={{ fontSize: '20px', color: 'red', cursor: 'pointer' }} />
            </div>
          )}
          style={{ marginBottom: '20px' }}
        >
          {/* <Row gutter={16}>
            <Col span={12}>
              <div className="p-editPageTemplate_input">
                <Typography.Text strong>
                  {t('location.region')}
                  {' '}
                </Typography.Text>
                <Controller
                  name={`quotas.${index}.regions`}
                  control={method.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <DropdownElement
                      options={regionTypes}
                      size="large"
                      placeholder={`${t('system.select')} ${t('location.region')}`}
                      locale="vi"
                      value={value}
                      multiple={{
                        allowClear: true,
                      }}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      error={error?.message}
                    />
                  )}
                />
              </div>
            </Col>
            <Col span={12}>
              <div className="p-editPageTemplate_input">
                <Typography.Text strong>
                  {t('location.province')}
                  {' '}
                </Typography.Text>
                <Controller
                  name={`quotas.${index}.provinceIds`}
                  control={method.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <DropdownElement
                      options={provinces}
                      loading={provinceLoading}
                      size="large"
                      placeholder={`${t('system.select')} ${t('location.province')}`}
                      locale="vi"
                      value={value}
                      multiple={{
                        allowClear: true,
                      }}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      error={error?.message}
                    />
                  )}
                />
              </div>
            </Col>
            <Col span={12}>
              <div className="p-editPageTemplate_input u-mt-16">
                <Typography.Text strong>
                  {t('location.district')}
                  {' '}
                </Typography.Text>
                <DistrictElement
                  index={index}
                  districts={districts}
                  method={method}
                  loading={districtLoading}
                />
              </div>
            </Col>
          </Row> */}
          <Row gutter={16}>
            <Col span={8}>
              <div className="p-editPageTemplate_input">
                <Typography.Text strong>
                  {t('topupConfig.quota')}
                  {' '}
                </Typography.Text>
                <Typography.Text strong type="danger">
                  *
                </Typography.Text>
                <Controller
                  name={`quotas.${index}.quota`}
                  control={method.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <Input
                      value={value}
                      type="number"
                      onChange={onChange}
                      className="u-mt-8"
                      size="large"
                      readOnly={!editable}
                      error={error?.message}
                    />
                  )}
                />
              </div>
            </Col>
            <Col span={8}>
              <div className="p-editPageTemplate_input">
                <Typography.Text strong>
                  {t('topupConfig.quotaDate')}
                  {' '}
                </Typography.Text>
                <div style={{ display: 'flex' }}>
                  <Controller
                    name={`quotas.${index}.quotaDate`}
                    control={method.control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <>
                        <DatePicker
                          size="large"
                          value={value ? dayjs(value) : null}
                          onChange={(quotaDate) => onChange(quotaDate?.format('YYYY-MM-DD') || '')}
                          format="YYYY-MM-DD"
                          style={{ width: '100%' }}
                          allowClear
                          disabled={!editable}
                          className={`u-mt-8${!editable ? ' readonly' : ''}`}
                        />
                        {
                          error && (
                            <span className="a-input_errorMessage">
                              {error.message}
                            </span>
                          )
                        }
                      </>
                    )}
                  />
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div className="p-editPageTemplate_input">
                <Typography.Text strong>
                  {t('topupConfig.probability')}
                  {' '}
                  <Typography.Text strong type="danger">
                    *
                  </Typography.Text>
                </Typography.Text>
                <Controller
                  name={`quotas.${index}.probability`}
                  control={method.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <Input
                      value={value}
                      type="number"
                      className="u-mt-8"
                      onChange={onChange}
                      size="large"
                      error={error?.message}
                      readOnly={!editable}
                    />
                  )}
                />
              </div>
            </Col>
          </Row>
        </Card>
      ))}
      {editable && (
        <Space style={{ width: '100%' }} direction="vertical" align="center">
          <Button
            type="primary"
            onClick={() => append({})}
          >
            <PlusOutlined />
            {t('topupConfig.addQuota')}
          </Button>
        </Space>
      )}
    </div>
  );
};

export default QuotaFormArray;
