import axiosInstance from '../common/instance';

import {
  ExportDataTypes,
  TopUpBuyCardParamTypes,
  TopupBuyCardData
} from './types';

export const getTopupBuyCardsService = async (
  params?: TopUpBuyCardParamTypes
): Promise<APIPaginationResponse<TopupBuyCardData[]>> => {
  const res = await axiosInstance.get('topup-buy-cards', { params });
  return res.data;
};

export const exportTopupBuyCardsService = async (params?: BaseFilterParams)
  : Promise<ExportDataTypes> => {
  const res = await axiosInstance.post('topup-buy-cards/export', null, { params });
  return res.data.data;
};

export const exportTopupBuyCardsAccountService = async (params?: BaseFilterParams)
  : Promise<ExportDataTypes> => {
  const res = await axiosInstance.post('topup-buy-cards/export-include-account', null, { params });
  return res.data.data;
};
