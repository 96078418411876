// type TelcoNameTypes = 'Viettel' | 'MobiFone' | 'Vinaphone' | 'Vietnamobile';
export type TelcoValueTypes = 1 | 2 | 3 | 4;
export type RequestStatusTypes = 1 | 2 | 3 | 4;
export type RequestAmountTypes = 10 | 20 | 30 | 50;
export type SourceValueTypes = 1 | 2 | 3;

export enum NeverTopUpStatusEnum {
  Invalid = 'invalid',
  Error = 'error',
  Manual = 'manual',
  Auto = 'auto'
}

export type NeverTopUpCodeTypes = '107' | null;

export enum BuyCardStatusEnum {
  Never = 'Never',
  Failed = 'Failed',
  Processing = 'Processing',
  Success = 'Success'
}

export type TopUpAccountType = 1 | 2 | 3;

export type AttemptResponseKeyTypes = 'data' | 'errors';
export type AttemptStatusTypes = 1 | 2 | 3 | 4;
export type BuyCardTransactionItemTypes = {
  uid: string;
  successful: boolean | null,
  createdAt: string;
};

export type AttemptItemTypes = {
  id: number,
  uid: string,
  status: AttemptStatusTypes,
  statusCode: string,
  postPaid: boolean,
  amount: RequestAmountTypes,
  telco: TelcoValueTypes,
  createdAt: string,
  finishedAt: string,
  neverTopupType: Exclude<NeverTopUpStatusEnum, NeverTopUpStatusEnum.Error>,
  neverTopupCode: NeverTopUpCodeTypes,
  account: TopUpAccountType
};

export type TopUpRequestTypes = {
  customerPrize: {
    id: number,
    luckyCode: string,
    phone: string,
    telco: TelcoValueTypes,
    status: RequestStatusTypes,
    createdAt: string,
    topupAmount: RequestAmountTypes,
    zaloPhone: string,
    account: TopUpAccountType,
    successAt: string,
    source: SourceValueTypes
  },
  lastAttempts: AttemptItemTypes[]
};

export type TopupAttemptItemTypes = {
  id: number,
  uid: string,
  status: AttemptStatusTypes,
  statusCode: string,
  telco: TelcoValueTypes,
  amount: RequestAmountTypes,
  postPaid: boolean,
  finishedAt: string,
  createdAt: string,
  response?: {
    [x in AttemptResponseKeyTypes]: {
      code: number,
      status: string,
      message: string,
      successAt?: string
    }
  };
  updatedAt: string,
  parentId: string,
  buyCardStatus: BuyCardStatusEnum,
  cardCode?: string,
  phone: string,
  buyCards: BuyCardTransactionItemTypes[];
  customerPrize: {
    createdAt: string;
    luckyCode: string;
  };
  telcoCustomer: TelcoValueTypes,
  zaloPhone: string,
  source: SourceValueTypes,
  customerLucky: {
    createdAt: string
  }
};

export type TopUpAttemptByIdTypes = {
  lastAttempts: TopupAttemptItemTypes[],
  history: TopupAttemptItemTypes[]
};

export type TopUpAttemptTypes = {
  attempts: TopupAttemptItemTypes[]
};

export type TopUpRequestItemTypes = TopUpRequestTypes['customerPrize'] & {
  lastAttemptData: TopUpRequestTypes['lastAttempts']
};

export type ExportTopUpRequestType = {
  data: {
    link: string;
  }
};

export type TopupRequestHotlineParamsTypes = Pick<BaseFilterParams, 'limit' | 'page'> & {
  phone?: string;
  prizeCode?: string;
  phase?: number
};

export type TopupParamsTypes = Pick<BaseFilterParams, 'limit' | 'page' | 'keyword' | 'phase'>;

export type TopupExportParamsTypes = {
  loadOldData?: boolean;
  [x: string]: any
};

export type NeverTopUpTypes = {
  status: NeverTopUpStatusEnum,
  code?: NeverTopUpCodeTypes,
  newAttempt: {
    id: number,
    uid: string,
    status: AttemptStatusTypes,
    statusCode: string,
    telco: number,
    amount: number,
    neverTopupType: Exclude<NeverTopUpStatusEnum, NeverTopUpStatusEnum.Error>
  }
};

export type ExportDataTypes = {
  link: string,
};

export type ExportParamsTypes = {
  from: string,
  to: string,
};
