/* eslint-disable */
import {
  Button,
  Space,
  Table,
  Typography,
  message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, {
  useEffect, useMemo,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
// import { ROUTE_PATHS } from 'common/utils/constant';
import { exportSMSGatewayService, getAllSMSGatewayService } from 'common/services/smsGateway';
import { MTSResponseTypes, SMSGatewaysResponseTypes } from 'common/services/smsGateway/types';
import { telcos } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';
import { FilterDataProps, FilterValueProps, mappingFilterFields, mappingFilterToQuery, mappingParamsFilter, mappingQueryParamsFilter } from 'common/components/PageTable/filter';
import useDidMount from 'common/hooks/useDidMount';
import { BaseType } from 'antd/es/typography/Base';
import roles, { getPermission } from 'configs/roles';

const SMSGateway: React.FC<ActiveRoles> = ({ roleUpdate, roleOther }) => {
  /* Hooks */
  const { t } = useTranslation();

  /* Search Params */
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get('page');

  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);

  /* Selectors */
  const { defaultPageSize, advancedFilter } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));

  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );

  /* React-query */
  const queryKey = ['smsgateway-list', currentPage, currentView, selectedFilterList];
  const {
    isLoading: listLoading,
    data: listData,
  } = useQuery(
    queryKey,
    () => getAllSMSGatewayService({
      page: currentPage, limit: currentView,
      ...returnParamsFilter,
    }),
    { keepPreviousData: true, enabled: !!currentPage }
  );

  const { mutate: exportMutate, isLoading: exportLoading } = useMutation(
    ['exportSMSGatewayMutate'],
    () => exportSMSGatewayService({
      page: currentPage,
      limit: currentView,
      ...returnParamsFilter,
    }),
    {
      onSuccess(res) {
        if (res.link) {
          window.open(
            res.link,
            '_blank',
          );
        }
      },
      onError: () => {
        message.error(t('message.uploadError'));
      }
    }
  );

  const statusMTS = (status: number) => {
    switch (status) {
      case 200:
        return t('smsGateway.mtsSuccess');
      case 1:
        return t('smsGateway.mtsPending');
      default:
        return t('smsGateway.mtsFailed');
    }
  };

  const reasonMTS = (status: number) => {
    if (status === 200 || status === 1) return '';
    return t('smsGateway.reasonFailed');
  };

  const statusColorMTS = (status: number): BaseType => {
    switch (status) {
      case 200:
        return 'success';
      case 1:
        return 'secondary';
      default:
        return 'danger';
    }
  };

  const luckyCodeStatus = (status?: number) => {
    switch (status) {
      case 1:
        return t('smsGateway.notStart');
      case 2:
        return t('smsGateway.ended');
      case 3:
        return t('smsGateway.invalid');
      case 4:
        return t('smsGateway.codeUsed');
      case 5:
        return t('smsGateway.win');
      case 6:
        return t('smsGateway.lose');
      default:
        return '';
    }
  };

  /* Data */
  const columns: ColumnsType<SMSGatewaysResponseTypes> = useMemo(() => ([
    // --- STT
    {
      title: 'ID',
      key: 'id',
      width: 100,
      align: 'center',
      render: (_name: string, data: SMSGatewaysResponseTypes) => (
        <Typography.Text>
          {data.smsGateways.id}
        </Typography.Text>
      ),
    },
    {
      title: t('smsGateway.commandCode'),
      dataIndex: 'commandCode',
      width: 100,
      key: 'commandCode',
      render: (_name: string, data: SMSGatewaysResponseTypes) => (
        <Typography.Text>
          {data.smsGateways.commandCode}
        </Typography.Text>
      ),
    },
    {
      title: t('smsGateway.messageUser'),
      dataIndex: 'messageUser',
      width: 150,
      key: 'messageUser',
      render: (_name: string, data: SMSGatewaysResponseTypes) => (
        <Typography.Text>
          {data.smsGateways.message}
        </Typography.Text>
      ),
    },
    {
      title: t('smsGateway.phoneUser'),
      dataIndex: 'phoneUser',
      width: 100,
      key: 'phoneUser',
      render: (_name: string, data: SMSGatewaysResponseTypes) => (
        <Typography.Text>
          {data.smsGateways.phone}
        </Typography.Text>
      ),
    },
    {
      title: t('smsGateway.telcoUser'),
      dataIndex: 'telcoUser',
      width: 100,
      key: 'telcoUser',
      render: (_name: string, data: SMSGatewaysResponseTypes) => (
        <Typography.Text>
          {telcos[data.smsGateways.telco as keyof typeof telcos]}
        </Typography.Text>
      ),
    },
    {
      title: t('smsGateway.luckyCode'),
      dataIndex: 'luckyCode',
      width: 100,
      key: 'luckyCode',
      render: (_name: string, data: SMSGatewaysResponseTypes) => (
        <Typography.Text>
          {data.smsGateways.luckyCode || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('smsGateway.luckyCodeStatus'),
      dataIndex: 'luckyCodeStatus',
      width: 100,
      key: 'luckyCodeStatus',
      render: (_name: string, data: SMSGatewaysResponseTypes) => (
        <Typography.Text>
          {luckyCodeStatus(data.smsGateways?.status)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 140,
      // sorter: {
      //   compare: (a: SMSGatewaysResponseTypes, b: SMSGatewaysResponseTypes) => {
      //     const aDate = new Date(a.smsGateways.createdAt || '');
      //     const bDate = new Date(b.smsGateways.createdAt || '');
      //     return aDate.getTime() - bDate.getTime();
      //   },
      // },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: SMSGatewaysResponseTypes) => (
        <Typography.Text>
          {formatDateTime(data.smsGateways.createdAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('smsGateway.mts'),
      dataIndex: 'mts',
      align: 'center',
      key: 'mts',
      render: (_name: string, data: SMSGatewaysResponseTypes) => (
        <Table<MTSResponseTypes>
          dataSource={data.mts}
          className="sms-gateway-mts-table"
          columns={[
            {
              // title: t('smsGateway.mtsMessage'),
              dataIndex: 'mtsMessage',
              key: 'mtsMessage',
              width: 600,
              render: (_name: string, data: MTSResponseTypes) => (
                <div>
                  <Typography.Text>
                    {t('smsGateway.mtsMessage')}
                    {': '}
                  </Typography.Text>
                  <Typography.Text style={{ fontWeight: 'bold' }}>
                    {data.message}
                  </Typography.Text>
                </div>
              ),
            },
            {
              // title: t('smsGateway.mtsStatus'),
              dataIndex: 'mtsStatus',
              key: 'mtsStatus',
              width: 200,
              render: (_name: string, data: MTSResponseTypes) => (
                <div>
                  <Typography.Text>
                    {t('smsGateway.mtsStatus')}
                    {': '}
                  </Typography.Text>
                  <Typography.Text type={statusColorMTS(data.status)} style={{ fontWeight: 'bold' }}>
                    {statusMTS(data.status)}
                  </Typography.Text>
                </div>
              ),
            },
            {
              // title: t('smsGateway.mtsStatus'),
              dataIndex: 'mtsReason',
              key: 'mtsReason',
              width: 300,
              render: (_name: string, data: MTSResponseTypes) => {
                if (data.status === 200 || data.status === 1) return null;
                return (
                  <div>
                    <Typography.Text>
                      {t('smsGateway.mtsReason')}
                      {': '}
                      {reasonMTS(data.status)}
                    </Typography.Text>
                  </div>
                )
              }
            },
            {
              // title: t('smsGateway.mtsCreatedAt'),
              dataIndex: 'mtsCreatedAt',
              key: 'mtsCreatedAt',
              width: 200,
              render: (_name: string, data: MTSResponseTypes) => (
                <Typography.Text>
                  {t('smsGateway.mtsCreatedAt')}
                  {': '}
                  {formatDateTime(data.createdAt)}
                </Typography.Text>
              ),
            },
          ]}
          pagination={false}
        />
      ),
    },
  ]), [t, roleUpdate]);

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('smsGateway', advancedFilter),
    [advancedFilter]
  );

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
  };

  // Add filter
  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  /* Effects */
  useEffect(() => {
    setCurrentPage(1);
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      page: '1'
    }, { replace: true });
  }, [setSearchParams, selectedFilterList]);

  useDidMount(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);
  });

  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.smsGateway')}
        rightHeader={(
          <Space>
            <Button
              type="primary"
              loading={exportLoading}
              disabled={!getPermission(roleOther, roles.SMSGATEWAY_EXPORT)
                || exportLoading}
              onClick={() => exportMutate()}
            >
              {t('system.export')}
            </Button>
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={listLoading}
          noCheckbox
          tableProps={{
            initShowColumns: ['id', 'luckyCode', 'luckyCodeStatus', 'messageUser', 'phoneUser', 'telcoUser', 'mts', 'createdAt'],
            columns,
            pageData: listData?.data,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listData?.meta.total || 1,
            noBaseCol: true,
            noDeleteLanguage: true,
            filterFields,
          }}
          filtersDataTable={{
            selectedFilterList,
            handleFilter,
            handleDeleteFilter
          }}
        />
      </div>
    </>
  );
};

export default SMSGateway;
