import axiosInstance from '../common/instance';

import { StoreData, StoreParams } from './types';

export const getAllStoreService = async (
  params?: BaseFilterParams
): Promise<APIPaginationResponse<StoreData[]>> => {
  const res = await axiosInstance.get('stores', { params });
  return res.data;
};

export const createStoreService = async (params: StoreParams): Promise<void> => {
  await axiosInstance.post('stores', params);
};

export const updateStoreService = async (id: number, params: StoreParams): Promise<void> => {
  await axiosInstance.put(`stores/${id}`, params);
};

export const getStoreByIdService = async (id: number): Promise<StoreData> => {
  const res = await axiosInstance.get(`stores/${id}`);
  return res.data.data;
};

export const deleteMultipleStoreByIdService = async (
  ids: number[]
) => axiosInstance.delete('stores', { data: { ids } });
