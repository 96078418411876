/* eslint-disable @typescript-eslint/no-unused-vars */
import { SaveOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Card, Col, message, Row, Typography
} from 'antd';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Editor from 'common/components/Editor';
import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import ManagementInfo from 'common/components/ManagementInfo';
import { createStoreService, getStoreByIdService, updateStoreService } from 'common/services/store';
import { StoreParams } from 'common/services/store/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { createUpdateStoreSchema } from 'common/utils/schemas';

const StoreDetail: React.FC = () => {
  /* Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const idParams = Number(searchParams.get('id'));
  const method = useForm<StoreParams>({
    defaultValues: {},
    resolver: yupResolver(createUpdateStoreSchema),
  });

  /* Queries */
  const { data, isLoading } = useQuery(
    ['getStoreById', { idParams }],
    () => {
      if (idParams) {
        return getStoreByIdService(idParams);
      }
      return undefined;
    },
  );

  const { mutate: createMutate, isLoading: createLoading } = useMutation(
    ['createStore'],
    createStoreService,
    {
      onSuccess: () => {
        message.success(t('message.createSuccess'));
        navigate(`${ROUTE_PATHS.STORE_MANAGEMENT}`);
      },
      onError: () => {
        message.error('Đã có lỗi xảy ra! Vui lòng thử lại sau');
      }
    }
  );

  const { mutate: updateMutate, isLoading: updateLoading } = useMutation(
    ['updateStore', { idParams }],
    async (params: StoreParams) => updateStoreService(idParams, params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
      },
      onError: () => {
        message.error('Đã có lỗi xảy ra! Vui lòng thử lại sau');
      }

    }
  );

  /* Functions */
  const onSubmit = async () => {
    const formData = method.getValues();
    if (idParams) {
      updateMutate(formData);
    } else {
      createMutate(formData);
    }
  };

  /* Effects */
  useEffect(() => {
    if (data) {
      method.reset({
        name: data.storeData.name,
        address: data.storeData.address
      });
    }
  }, [method, data]);

  /* Render */
  return (
    <FormProvider<StoreParams> {...method}>
      <HeaderPage
        fixed
        title={idParams ? 'Chỉnh sửa' : 'Tạo mới'}
        rightHeader={(
          <Button
            type="primary"
            loading={isLoading || createLoading || updateLoading}
            onClick={method.handleSubmit(onSubmit)}
          >
            <SaveOutlined />
            {t('system.save')}
          </Button>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <Row gutter={16}>
          <Col xxl={18} xl={16} lg={16}>
            <Card>
              <div className="p-editPageTemplate_input">
                <div style={{ marginBottom: 8 }}>
                  <Typography.Text strong>
                    {t('store.name')}
                    {' '}
                  </Typography.Text>
                  <Typography.Text strong type="danger">
                    *
                  </Typography.Text>
                </div>
                <Controller
                  name="name"
                  defaultValue=""
                  render={({
                    field: { value, onChange },
                    fieldState: { error }
                  }) => (
                    <Input
                      type="text"
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                      size="large"
                    />
                  )}
                />
              </div>
              <div className="p-editPageTemplate_input u-mt-16">
                <div style={{ marginBottom: 8 }}>
                  <Typography.Text strong>
                    {t('store.address')}
                    {' '}
                  </Typography.Text>
                  <Typography.Text strong type="danger">
                    *
                  </Typography.Text>
                </div>
                <Controller
                  name="address"
                  defaultValue=""
                  render={({
                    field: { value, onChange },
                    fieldState: { error }
                  }) => (
                    <Input
                      type="text"
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                      size="large"
                    />
                  )}
                />
              </div>
            </Card>
          </Col>
          <Col xxl={6} xl={8} lg={8}>
            <ManagementInfo
              createdDate={data?.storeData.createdAt || ''}
              createdBy=""
              lastUpdated={data?.storeData.updatedAt || ''}
              lastUpdatedBy=""
            />
          </Col>
        </Row>
      </div>
    </FormProvider>
  );
};

export default StoreDetail;
