/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable max-len */
/* eslint-disable no-unsafe-optional-chaining */
import { InfoCircleOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Card, Col, Modal, Pagination, Row, Select, Space, Spin, Tabs, Tooltip, Typography, message
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import Input from '../Input';
import PageTable from '../PageTable';
import { StatusCustomerPrizeLabel, StatusTopUpLabel } from '../StatusLabel';

import { useAppSelector } from 'app/store';
import { getCustomerAllPrizeService } from 'common/services/customerPrizes';
import { CustomerPrizeType, GetAllPrizeRequestParamTypes } from 'common/services/customerPrizes/types';
import { getInfoTopUpRequestForHotline, postNeverTopUpPrize } from 'common/services/topupRequest';
import {
  AttemptItemTypes, AttemptStatusTypes, NeverTopUpStatusEnum, NeverTopUpTypes, RequestAmountTypes, TelcoValueTypes, TopUpAccountType
} from 'common/services/topupRequest/types';
import { ROUTE_PATHS, telcos, topUpExecuteOptions } from 'common/utils/constant';
import { formatDateTime, formatNumberWithSeparators } from 'common/utils/functions';
import { findDataByPhoneSchema } from 'common/utils/schemas';

export type CallCenterData = {
  phone: string;
  timestamp: number;
  callId: string
};
type TabKeysTypes = 'customerPrize' | 'topupRequest';
type StatusTypes = 'statusPrizeExchange' | 'statusTopUpRequest';
type RenderValTypes<T> = {
  value: T;
  statusType?: StatusTypes
  hidden?: boolean
};

type AttemptItemRenderValTypes = {
  transactionId: RenderValTypes<string>;
  id: RenderValTypes<number>;
  status: RenderValTypes<AttemptStatusTypes>;
  telco: RenderValTypes<string>;
  amount: RenderValTypes<string>;
  createdAtEkoin?: RenderValTypes<string>;
  finishedAt?: RenderValTypes<string>;
  phone: RenderValTypes<string>;
  zaloPhone?: RenderValTypes<string>;
  isNotExecuted: RenderValTypes<{
    [key in NeverTopUpReasonTypes]: boolean;
  }>;
  neverTopupCode: RenderValTypes<string | null | undefined>;
  neverTopupType: RenderValTypes<NeverTopUpStatusEnum>;
  account?: RenderValTypes<TopUpAccountType>;
};

type TableTopUpColumnTypes = {
  id: number;
  customerPrize: {
    id: RenderValTypes<number>;
    phone: RenderValTypes<string>;
    telco: RenderValTypes<string>;
    status: RenderValTypes<number>;
    createdAt: RenderValTypes<string>;
  };
  attemptData: (AttemptItemRenderValTypes | null)[]
};

type NeverTopUpReasonTypes = 1;

type ExecutedTopUpItem = {
  id: number;
  type: NeverTopUpReasonTypes | undefined
};

interface Props {
  socketData?: CallCenterData
  handleClose(): void
}

const defaultExecuteObject: RenderValTypes<{
  [key in NeverTopUpReasonTypes]: boolean;
}> = {
  value: {
    1: false,
  },
  hidden: true
};

const SocketDataModal: React.FC<Props> = ({ socketData, handleClose }) => {
  // const navigate = useNavigate();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { defaultPageSize } = useAppSelector((state) => state.system);

  const [activeTab, setActiveTab] = useState<TabKeysTypes>('topupRequest');
  const [total, setTotal] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [topUpExecuteList, setTopUpExecuteList] = useState<ExecutedTopUpItem[]>([]);
  const [firstTopUpList, setFirstTopUpList] = useState<TableTopUpColumnTypes[]>([]);
  const [secondTopUpList, setSecondTopUpList] = useState<TableTopUpColumnTypes[]>([]);
  const [executedTransId, setExecutedTransId] = useState<string[]>([]);

  const method = useForm<{ phone: string }>({
    mode: 'onSubmit',
    resolver: yupResolver(findDataByPhoneSchema),
    defaultValues: {
      phone: ''
    }
  });

  const formatAttemptData = (data: AttemptItemTypes & {
    phone: string;
    zaloPhone: string;
    isNotExecuted: typeof defaultExecuteObject
  }) => ({
    transactionId: { value: data.uid },
    id: { value: data.id, hidden: true },
    status: {
      value: data.status,
      statusType: 'statusTopUpRequest' as StatusTypes
    },
    phone: {
      value: data.phone
    },
    zaloPhone: {
      value: data.zaloPhone
    },
    telco: {
      value: telcos[data.telco]
    },
    amount: {
      value: formatNumberWithSeparators(data.amount * 1000)
    },
    createdAt: {
      value: formatDateTime(data.createdAt) || ''
    },
    finishedAt: {
      value: formatDateTime(data.finishedAt) || ''
    },
    neverTopupType: {
      value: data.neverTopupType,
      hidden: true
    },
    neverTopupCode: {
      value: data.neverTopupCode,
      hidden: true
    },
    isNotExecuted: data.isNotExecuted,
    account: {
      value: data.account,
      hidden: true
    }
  });

  const detectExecuted = (data: any, isInit?: boolean): typeof defaultExecuteObject => {
    if (!data) return defaultExecuteObject;
    if (isInit) {
      const { neverTopupCode, neverTopupType } = data as AttemptItemTypes;
      return {
        value: {
          1: neverTopupCode === '107' || neverTopupType !== NeverTopUpStatusEnum.Auto,
        },
        hidden: true
      };
    }
    const { code, status } = data as NeverTopUpTypes;
    return {
      value: {
        1: code === '107' && status === NeverTopUpStatusEnum.Error,
      },
      hidden: true
    };
  };

  const {
    mutate: callCenterPrizeExchangeMutate,
    data: customerPrizesData,
    isLoading: customerPrizesLoading
  } = useMutation(
    ['prize-exchange-call-center'],
    (data: {
      phone: string,
      params: GetAllPrizeRequestParamTypes
    }) => getCustomerAllPrizeService({ phone: data.phone, ...data.params }),
    {
      onSuccess: (res) => {
        if (res.meta) setTotal(res.meta.totalPages);
      }
    }
  );

  const {
    mutate: callCenterTopUpRequestMutate,
    data: topUpRequestData,
    isLoading: topUpRequestLoading
  } = useMutation(
    ['topUp-request-call-center', activeTab],
    (
      data: {
        phone: string,
        params: BaseFilterParams
      }
    ) => getInfoTopUpRequestForHotline({ phone: data.phone, ...data.params }),
    {
      onSuccess: (res) => {
        if (res.meta) setTotal(res.meta.totalPages);
        if (res.data) {
          const formatData = res.data.map((item, idx) => ({
            id: idx,
            customerPrize: {
              id: { value: item.customerPrize.id },
              phone: { value: item.customerPrize.phone },
              telco: { value: telcos[item.customerPrize.telco] },
              status: {
                value: item.customerPrize.status,
                statusType: 'statusTopUpRequest' as StatusTypes
              },
              createdAt: { value: formatDateTime(item.customerPrize.createdAt) || '' }
            },
            attemptData: item.lastAttempts.map(() => null)

          }));
          setSecondTopUpList(formatData);
        }
      },
    }
  );

  const {
    mutate: neverTopUpMutate,
  } = useMutation(
    ['never-topUp-call-center'],
    (
      { id }: { id: number }
    ) => postNeverTopUpPrize(id),
    {
      onSuccess: (res, params) => {
        // const dummy = {
        //   status: 'error' as NeverTopUpStatusEnum,
        //   code: '107' as NeverTopUpCodeTypes,
        //   newAttempt: {
        //     id: 999,
        //     uid: '01hyhv95zr7xw3ch4b1nz4d53z',
        //     status: 3 as AttemptStatusTypes,
        //     statusCode: '200',
        //     telco: 3,
        //     amount: 10,
        //     createdAt: '',
        //     finishedAt: ''
        //   }
        // };
        const { id: paramId } = params;
        const cloneFirstTopUpList = [...firstTopUpList];
        const topUpIdx = firstTopUpList.findIndex((i) => {
          const attemptItem = i.attemptData.find((j) => j?.id.value === paramId);
          return !!attemptItem;
        });
        const attemptIdx = firstTopUpList[topUpIdx]?.attemptData.findIndex((i) => i?.id.value === paramId);
        const { status: resStatus, code } = res;
        if (res.newAttempt) {
          const {
            amount, id, status, telco, uid
          } = res.newAttempt;
          const cloneSecondTopUpList = [...secondTopUpList];
          cloneSecondTopUpList[topUpIdx]?.attemptData?.splice(attemptIdx, 1, {
            transactionId: { value: uid },
            id: { value: id, hidden: true },
            status: {
              value: status,
              statusType: 'statusTopUpRequest' as StatusTypes
            },
            phone: {
              value: cloneSecondTopUpList[topUpIdx]?.attemptData[attemptIdx]?.phone.value || ''
            },
            telco: {
              value: telcos[telco as keyof typeof telcos]
            },
            amount: {
              value: formatNumberWithSeparators(amount * 1000)
            },
            neverTopupType: {
              value: resStatus,
              hidden: true
            },
            neverTopupCode: {
              value: code,
              hidden: true
            },
            isNotExecuted: defaultExecuteObject
          });
          setSecondTopUpList(cloneSecondTopUpList);
        }
        if (cloneFirstTopUpList[topUpIdx]?.attemptData[attemptIdx]) {
          cloneFirstTopUpList[topUpIdx]?.attemptData.splice(attemptIdx, 1, {
            ...cloneFirstTopUpList[topUpIdx]?.attemptData[attemptIdx]!,
            isNotExecuted: detectExecuted(res)
          });
          setFirstTopUpList(cloneFirstTopUpList);
        }

        const transId = firstTopUpList[topUpIdx]?.attemptData[attemptIdx]?.transactionId?.value;
        if (transId) setExecutedTransId([...executedTransId, transId]);
        setTopUpExecuteList(topUpExecuteList.map((item) => ({
          ...item,
          type: undefined
        })));
      }
    }
  );

  const renderVal = (data: RenderValTypes<any>) => {
    switch (data.statusType) {
      case 'statusPrizeExchange':
        return <StatusCustomerPrizeLabel status={data.value} />;
      case 'statusTopUpRequest':
        return <StatusTopUpLabel status={data.value} />;
      default:
        return (
          <Typography.Text>
            {data.value}
          </Typography.Text>
        );
    }
  };

  const detectMessage = useCallback((attempt: AttemptItemRenderValTypes | null) => {
    if (!attempt) return '';
    const typeExecute = topUpExecuteList.find((item) => item.id === attempt?.id.value)?.type;
    switch (typeExecute) {
      case 1:
        if (attempt.neverTopupCode.value === '107' && attempt.neverTopupType.value === NeverTopUpStatusEnum.Error) {
          return t('callCenter.hasBeenRecharged');
        }
        if (attempt.neverTopupCode.value === '107') return t('callCenter.hasBeenRecharged');
        return '';
      default:
        return '';
    }
  }, [topUpExecuteList, t]);

  const formatCustomerPrize: { [x: string]: RenderValTypes<any> }[] = useMemo(() => (customerPrizesData ? customerPrizesData?.data.map((item) => ({
    id: { value: item.customerPrize.id },
    name: { value: item.customer.fullName },
    phone: { value: item.customer.phone },
    zaloPhone: { value: item.customer.zaloPhone },
    address: { value: item.customerPrize.address },
    province: { value: item.customerPrize.province.name },
    district: { value: item.customerPrize.district.name },
    status: { value: item.customerPrize.status, statusType: 'statusPrizeExchange' },
    prizeLabel: {
      value: item.customerPrize.prize === '1st' ? t('customerLuckyCode.first')
        : t('customerLuckyCode.special')
    },
    prizeType: {
      value: item.customerPrize.prize,
      hidden: true
    },
    contactedAt: { value: formatDateTime(item.customerPrize.contactAt || '') },
    createdAt: { value: formatDateTime(item.customerPrize.createdAt) || '' }
  })) : []), [customerPrizesData]);

  const formatTopUpRequest = useMemo(() => (topUpRequestData ? topUpRequestData.data.map((item, idx) => ({
    id: idx,
    customerPrize: {
      id: { value: item.customerPrize.id },
      phone: { value: item.customerPrize.phone },
      telco: { value: telcos[item.customerPrize.telco as keyof typeof telcos] },
      status: {
        value: item.customerPrize.status,
        statusType: 'statusTopUpRequest' as StatusTypes
      },
      createdAt: { value: formatDateTime(item.customerPrize.createdAt) || '' }
    },
    attemptData: item.lastAttempts.map((attempt) => formatAttemptData({
      ...attempt,
      phone: item.customerPrize.phone,
      zaloPhone: item.customerPrize.zaloPhone,
      createdAt: item.customerPrize.createdAt,
      status: attempt.status as AttemptStatusTypes,
      amount: attempt.amount as RequestAmountTypes,
      telco: attempt.telco as TelcoValueTypes,
      isNotExecuted: detectExecuted(attempt, true),
      account: item.customerPrize.account
    }))

  })) : []), [topUpRequestData]);

  const onSwitchTab = useCallback(({ phone, page }: { phone?: string, page: number }) => {
    if (phone) {
      if (activeTab === 'customerPrize') {
        callCenterPrizeExchangeMutate({ phone, params: { limit: 5, page } });
      } else {
        callCenterTopUpRequestMutate({ phone, params: { limit: currentView, page } });
      }
    }
  }, [activeTab, currentView, callCenterTopUpRequestMutate, callCenterPrizeExchangeMutate]);

  const onChangeSelect = useCallback(({ id, type }: ExecutedTopUpItem) => {
    const clone = [...topUpExecuteList];
    const findIdx = clone.findIndex((item) => item.id === id);
    clone[findIdx].type = type;
    setTopUpExecuteList(clone);
  }, [topUpExecuteList]);

  const onSearchPhone = (data: { phone: string }) => {
    onSwitchTab({ phone: data.phone || socketData?.phone, page: 1 });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const onDetectTopUpActions = useCallback(({ type, id }: ExecutedTopUpItem) => {
    switch (type) {
      case 1:
        return { fetch: async () => neverTopUpMutate({ id }) };
      default:
        return undefined;
    }
  }, [neverTopUpMutate]);

  const onSubmit = useCallback(async (id?: number) => {
    try {
      setIsLoading(true);
      if (!id) {
        if (topUpExecuteList.length > 0) {
          const fetchingFunctions = topUpExecuteList.filter((item) => item.type !== undefined).map((item) => onDetectTopUpActions(item));
          if (fetchingFunctions.length > 0) {
            await Promise.all(fetchingFunctions.map((item) => item?.fetch())).then(() => {
              message.success(t('message.neverTopUpSuccess'));
            }).catch((err) => {
              message.error('message.neverTopUpError');
              console.log(err);
            }).finally(() => {
              setIsLoading(false);
            });
          }
        }
      } else {
        const findItem = topUpExecuteList.find((item) => item.id === id);
        if (findItem) {
          onDetectTopUpActions(findItem)?.fetch().then(() => {
            message.success(t('message.neverTopUpSuccess'));
          });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }, [topUpExecuteList, onDetectTopUpActions, t]);

  const renderBorderColor = ({ account, status }: { account?: number, status: AttemptStatusTypes }) => {
    if (account !== 1 && status === 3) return '#fec304';
    if (account !== 1 && status === 4) return '#ff0000';
    return '#f0f0f0';
  };

  const detectNavSlug = (prize: CustomerPrizeType) => {
    switch (prize) {
      case '1st':
        return ROUTE_PATHS.CUSTOMER_FIRST_PRIZE_DETAIL;
      case 'special':
        return ROUTE_PATHS.CUSTOMER_SPECIAL_PRIZE_DETAIL;
      default:
        return '';
    }
  };

  const columns: ColumnsType<TableTopUpColumnTypes> = useMemo(
    () => [
      // --- STT
      {
        title: 'NO',
        key: 'no',
        width: 75,
        align: 'center',
        render: (_name: string, data: TableTopUpColumnTypes) => (
          <Typography.Text>
            {data.id + 1}
          </Typography.Text>
        ),
      },
      {
        title: t('callCenter.firstTime'),
        key: 'topup',
        align: 'center',
        width: 500,
        render: (name: string, data: TableTopUpColumnTypes) => (
          <Space direction="vertical" className="t-mainlayout_transactions">
            {
              data.attemptData?.length > 0 ? data.attemptData.map((attempt, idx) => {
                const typeExecute = topUpExecuteList.find((item) => item.id === attempt?.id.value)?.type;
                const errorMessage = detectMessage(attempt);
                const isNotExecuted = typeExecute ? attempt?.isNotExecuted.value[typeExecute] : false;
                const isDisabled = attempt ? executedTransId.includes(attempt.transactionId?.value) : false;
                return (
                  attempt ? (
                    <Card
                      className="t-mainlayout_card"
                      key={`item-${idx.toString()}-init-data`}
                      style={{ borderColor: renderBorderColor({ account: attempt.account?.value, status: attempt.status.value }) }}
                    >
                      {attempt.account?.value !== 1 && attempt.neverTopupType.value === NeverTopUpStatusEnum.Manual && (
                        <Tooltip title={t('callCenter.hasBeenExecuted')}>
                          <InfoCircleOutlined className="t-mainlayout_card_flag" style={{ fontSize: '14px', color: '#ff0000' }} />
                        </Tooltip>
                      )}
                      <Row gutter={16}>
                        {
                          Object.keys(attempt).map((key, keyIdx) => {
                            const dataByKey = attempt[key as keyof typeof attempt];
                            return (
                              dataByKey && (
                                dataByKey.hidden ? null : (
                                  <Col
                                    key={`item-${keyIdx.toString()}-${name}-${key}`}
                                    span={12}
                                    className="u-mt-6"
                                    style={{ textAlign: 'left' }}
                                  >
                                    <Space>
                                      <Typography.Text strong>
                                        {t(`callCenter.${key}`)}
                                        :
                                      </Typography.Text>
                                      {renderVal(dataByKey)}
                                    </Space>
                                  </Col>
                                )
                              )
                            );
                          })
                        }
                      </Row>
                      <Row gutter={16} className="u-mt-8">
                        <Col span={12} style={{ textAlign: 'left' }}>
                          <Select
                            style={{ width: '100%' }}
                            placeholder="---"
                            allowClear
                            // onClear={() => onChangeSelect({ id: data.topupData.id.value, type: undefined })}
                            value={topUpExecuteList.find((item) => item.id === attempt.id.value)?.type}
                            onChange={(val) => onChangeSelect({ id: attempt.id.value, type: val })}
                          >
                            {
                              topUpExecuteOptions.map((item, index) => (
                                <Select.Option value={item.value} key={`option-${index.toString()}`}>
                                  {t(item.label)}
                                </Select.Option>
                              ))
                            }
                          </Select>
                        </Col>
                        <Col span={12} style={{ textAlign: 'left' }}>
                          <Button type="primary" disabled={isNotExecuted || isDisabled} onClick={() => onSubmit(attempt.id.value)}>Thực thi</Button>
                        </Col>
                        {errorMessage && (
                          <Col span={24} className="u-mt-8" style={{ textAlign: 'left' }}>
                            <Typography.Text style={{ color: '#ff0000' }}>
                              {errorMessage}
                            </Typography.Text>
                          </Col>
                        )}
                      </Row>
                    </Card>
                  ) : <Typography.Text>Chưa có dữ liệu</Typography.Text>
                );
              }) : null
            }
          </Space>
        )
      },
      {
        title: t('callCenter.secondTime'),
        key: 'attempts',
        align: 'center',
        width: 500,
        render: (name: string, data: TableTopUpColumnTypes) => (
          <Space>
            {
              secondTopUpList[data.id]?.attemptData.length > 0
                ? secondTopUpList[data.id].attemptData.map((attempt, idx) => (
                  attempt ? (
                    <Card className="t-mainlayout_card" key={`item-${idx.toString()}-new-data`}>
                      <Row gutter={16}>
                        {
                          Object.keys(attempt).map((key, keyIdx) => {
                            const dataByKey = attempt[key as keyof typeof attempt];
                            return (
                              dataByKey && (
                                dataByKey.hidden ? null : (
                                  <Col
                                    key={`item-${keyIdx.toString()}-${key}`}
                                    span={12}
                                    className="u-mt-6"
                                    style={{ textAlign: 'left' }}
                                  >
                                    <Space>
                                      <Typography.Text strong>
                                        {t(`callCenter.${key}`)}
                                        :
                                      </Typography.Text>
                                      {renderVal(dataByKey)}
                                    </Space>
                                  </Col>
                                )
                              )
                            );
                          })
                        }
                      </Row>
                    </Card>
                  ) : <Typography.Text>Chưa có dữ liệu</Typography.Text>
                )) : null
            }
          </Space>
        ),
      },
    ],
    [t, secondTopUpList, topUpExecuteList, executedTransId, detectMessage, onChangeSelect, onSubmit]
  );

  useEffect(() => {
    onSwitchTab({ phone: socketData?.phone, page: 1 });
  }, [onSwitchTab, socketData]);

  useEffect(() => {
    if (activeTab === 'topupRequest') {
      setTopUpExecuteList(formatTopUpRequest?.reduce((acc, cur) => {
        // eslint-disable-next-line no-param-reassign
        acc = acc.concat(cur.attemptData.map((item) => ({
          id: item.id.value,
          type: undefined
        })));
        return acc;
      }, [] as ExecutedTopUpItem[]) || []);
      setFirstTopUpList(formatTopUpRequest);
    }
  }, [activeTab, formatTopUpRequest]);
  return (
    <Modal
      width="70vw"
      visible={!!socketData}
      footer={null}
      closable
      onCancel={handleClose}
    >
      <Card
        title={(
          <Space style={{ width: '100%', justifyContent: 'space-between' }}>
            <Typography.Text>{t('sidebar.general')}</Typography.Text>
            <FormProvider {...method}>
              <Space style={{ alignItems: 'flex-start', padding: '1rem 0' }}>
                <Controller
                  name="phone"
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      error={error?.message}
                      size="large"
                      placeholder={t('system.phone')}
                    />
                  )}
                />
                <Button type="primary" size="large" onClick={method.handleSubmit(onSearchPhone)}>Tìm kiếm</Button>
              </Space>
            </FormProvider>
          </Space>
        )}
        style={{ marginTop: '1.5rem' }}
      >
        <Row>
          <Col span={12}>
            <Typography.Text strong>
              {t('callCenter.callID')}
              :
            </Typography.Text>
            <Typography.Text className="u-ml-4">
              {socketData?.callId}
            </Typography.Text>
          </Col>
          <Col span={12}>
            <Typography.Text strong>
              {t('system.phone')}
              :
            </Typography.Text>
            <Typography.Text className="u-ml-4">
              {socketData?.phone}
            </Typography.Text>
          </Col>
          <Col span={12}>
            <Typography.Text strong>
              {t('callCenter.calledAt')}
              :
            </Typography.Text>
            <Typography.Text className="u-ml-4">
              {socketData?.timestamp ? formatDateTime(new Date(socketData?.timestamp * 1000).toISOString()) : ''}
            </Typography.Text>
          </Col>
        </Row>
      </Card>
      <Tabs
        centered
        defaultActiveKey="topupRequest"
        onChange={(key) => {
          setCurrentPage(1);
          setActiveTab(key as TabKeysTypes);
        }}
      >
        <Tabs.TabPane tab="TopUp Request Info" key="topupRequest">
          <Spin spinning={isLoading || topUpRequestLoading}>
            {formatTopUpRequest.length > 0 ? (
              <PageTable
                isLoading={false}
                noCheckbox
                roles={{
                  roleCreate: false,
                  roleUpdate: false,
                  roleDelete: false,
                }}
                tableProps={{
                  initShowColumns: [
                    'no',
                    'topup',
                    'attempts'
                  ],
                  columns,
                  currentPage,
                  pageSize: currentView,
                  total,
                  handleSetCurrentPage: (page) => {
                    setCurrentPage(page);
                    onSwitchTab({ phone: method.getValues('phone') || socketData?.phone, page });
                  },
                  handleSetCurrentView,
                  pageData: firstTopUpList,
                  noBaseCol: true,
                  noDeleteLanguage: true,
                }}
                leftCustomForm={(
                  <Button type="primary" onClick={() => onSubmit()}>
                    {t('system.allExecute')}
                  </Button>
                )}
              />
            ) : <div className="t-mainlayout_table_text">{t('callCenter.nulllist')}</div>}
          </Spin>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Customer Prize Info" key="customerPrize">
          <Spin spinning={customerPrizesLoading}>
            {formatCustomerPrize.length > 0 ? (
              <>
                <div className="t-mainlayout_table">
                  {formatCustomerPrize.length > 0
                    && formatCustomerPrize.map((item, idx) => (
                      <Card
                        className="t-mainlayout_card"
                        title={(
                          <Button
                            type="primary"
                            onClick={() => {
                              handleClose();
                              navigate(
                                `${detectNavSlug(item.prizeType.value as CustomerPrizeType)}?id=${item.id.value}`,
                              );
                            }}
                          >
                            {t('callCenter.updatePrizeExchange')}
                          </Button>
                        )}
                      >
                        <Typography.Title level={4} className="t-mainlayout_card_title">Thông tin từ DR</Typography.Title>
                        <Row gutter={16}>
                          {
                            Object.keys(item).map((key, keyIdx) => {
                              const dataByKey = item[key as keyof typeof item];
                              return (dataByKey && dataByKey.hidden ? null : (
                                <Col
                                  key={`item-${keyIdx.toString()}-col-${idx.toString()}-prizeExchange`}
                                  span={12}
                                  className="u-mt-6"
                                >
                                  <Space>
                                    <Typography.Text strong>
                                      {t(`callCenter.${key}`)}
                                      :
                                    </Typography.Text>
                                    {renderVal(item[key as keyof typeof item] as RenderValTypes<any>)}
                                  </Space>
                                </Col>
                              ));
                            })
                          }
                        </Row>
                      </Card>
                    ))}
                </div>
                <div className="t-mainlayout_tab_pagination">
                  <Pagination
                    current={currentPage}
                    onChange={(page) => {
                      setCurrentPage(page);
                      onSwitchTab({ phone: method.getValues('phone') || socketData?.phone, page });
                    }}
                    total={total}
                    pageSize={1}
                  />
                </div>
              </>
            ) : <div className="t-mainlayout_table_text">{t('callCenter.nulllist')}</div>}
          </Spin>
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
};

export default SocketDataModal;
