import {
  CustomerAllPrizesData,
  GetAllPrizeRequestParamTypes,
  ParamsUpdateStatus,
  RequestDelCustomerPrizeTopUp
} from './types';

import axiosInstance from 'common/services/common/instance';

export const getCustomerAllPrizeService = async (
  params?: GetAllPrizeRequestParamTypes
): Promise<APIPaginationResponse<CustomerAllPrizesData[]>> => {
  const res = await axiosInstance.get('customer-prizes/special-and-first-prizes', { params });
  return res.data;
};

export const deleteCustomerTopUpPrizeService = async (data: RequestDelCustomerPrizeTopUp) => {
  await axiosInstance.delete('customer-prizes/remove-prize-topups', { data });
};

export const updateStatusCustomerTopUpPrizeService = async ({ id, data }: ParamsUpdateStatus) => {
  await axiosInstance.put(`customer-prizes/${id}/update-status`, data);
};

export const sendSMSFirstCustomerPrizeByIdService = async (id: string) => {
  await axiosInstance.post(`customer-prizes/send-sms-first-prizes/${id}`);
};

export const sendSMSSpecialCustomerPrizeByIdService = async (id: string) => {
  await axiosInstance.post(`customer-prizes/send-sms-special-prizes/${id}`);
};

export const sendSMSFirstCustomersPrizeService = async (ids: string[]) => {
  await axiosInstance.post('customer-prizes/bulk-send-sms-first-prizes', { ids });
};

export const sendSMSSpecialCustomersPrizeService = async (ids: string[]) => {
  await axiosInstance.post('customer-prizes/bulk-send-sms-special-prizes', { ids });
};
