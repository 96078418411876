/* eslint-disable max-len */
import { MinusCircleOutlined, PlusCircleOutlined, SaveOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Row,
  Space,
  Typography, message
} from 'antd';
import dayjs from 'dayjs';
import React, {
  useEffect
} from 'react';
import {
  Controller, FormProvider, useFieldArray, useForm
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { editEventTimeConfig, getEventTimeConfig } from 'common/services/systems';
import { EventTimeConfig } from 'common/services/systems/types';

const { RangePicker } = DatePicker;

type EventTimeConfigForm = {
  items: {
    eventDuration?: string[];
    date: string;
  }[]
};

const EventTimeSM: React.FC<{ canEdit: boolean }> = ({ canEdit }) => {
  /* Hooks */
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  /* States */

  /* React-hooks-form */
  const method = useForm<EventTimeConfigForm>({
    mode: 'onSubmit',
    defaultValues: {
      items: [{
        eventDuration: [new Date().toISOString(), new Date().toISOString()],
        date: new Date().toISOString()
      }]
    },
  });

  const { fields, append, remove } = useFieldArray({ control: method.control, name: 'items' });

  /* Queries */
  const {
    data: eventTimeConfigData,
  } = useQuery(
    ['getEventTimeConfig'],
    () => getEventTimeConfig(),
  );

  const { mutate: editEventTimeConfigMutate, isLoading: isEditLoading } = useMutation(
    ['eventTimeConfigMutate', 'edit'],
    async (params: EventTimeConfig[]) => editEventTimeConfig(params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries('getEventTimeConfig');
      },
      onError: () => {
        message.error(t('message.updateError'));
      }

    }
  );

  /* Functions */
  const handleSubmit = (data: EventTimeConfigForm) => {
    if (data.items.length > 0) {
      editEventTimeConfigMutate(data.items.map((item) => ({
        date: item.date,
        from: item.eventDuration?.[0],
        to: item.eventDuration?.[1],
      })));
    } else {
      editEventTimeConfigMutate([{
        date: new Date().toISOString(),
        from: new Date().toISOString(),
        to: new Date().toISOString(),
      }]);
    }
  };

  /* Effects */
  useEffect(() => {
    if (!eventTimeConfigData) return;
    method.reset(eventTimeConfigData.length > 0 ? {
      items: eventTimeConfigData.map((item) => ({
        eventDuration: [item.from, item.to],
        date: item.date,
      }))
    } : {
      items: [
        {
          eventDuration: [new Date().toISOString(), new Date().toISOString()],
          date: new Date().toISOString(),
        }
      ]
    });
  }, [eventTimeConfigData, method]);

  return (
    <div className="p-system_eventTimeConfig">
      <FormProvider<EventTimeConfigForm> {...method}>
        <form noValidate>
          <div className="p-system_eventTimeConfig_submit u-mb-8">
            <Button
              type="primary"
              disabled={!canEdit}
              loading={isEditLoading}
              onClick={method.handleSubmit(handleSubmit)}
            >
              <SaveOutlined />
              {t('system.save')}
            </Button>
          </div>
          {fields.map((field, index) => (
            <Card
              className="u-mt-8"
              type="inner"
              key={field.id}
              extra={fields.length > 1 && canEdit && (
                <div onClick={() => remove(index)}>
                  <MinusCircleOutlined style={{ fontSize: '20px', color: 'red', cursor: 'pointer' }} />
                </div>
              )}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Controller
                    name={`items.${index}.eventDuration`}
                    control={method.control}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <Typography.Text strong>
                          {t('systemManagement.eventDurationEventTimeConfig')}
                        </Typography.Text>
                        <RangePicker
                          className="u-mt-8"
                          allowClear
                          size="large"
                          showTime
                          value={value ? [dayjs(value[0]), dayjs(value[1])] : undefined}
                          format="YYYY-MM-DD HH:mm:ss"
                          onChange={(values) => values && onChange(values.map(
                            (item) => dayjs(item).toISOString()
                          ))}
                          style={{ width: '100%', display: 'flex' }}
                        />
                      </>
                    )}
                  />
                </Col>
                <Col span={12}>
                  <Controller
                    name={`items.${index}.date`}
                    control={method.control}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <Typography.Text strong>
                          {t('systemManagement.date')}
                        </Typography.Text>
                        <DatePicker
                          size="large"
                          value={value ? dayjs(value) : null}
                          showTime
                          onChange={(date) => onChange(date?.toISOString() || '')}
                          format="YYYY-MM-DD HH:mm:ss"
                          style={{ width: '100%', display: 'flex' }}
                          allowClear
                          className="u-mt-8"
                        />
                      </>
                    )}
                  />
                </Col>
              </Row>
            </Card>
          ))}
          {canEdit && (
            <Space style={{ width: '100%' }} direction="vertical" align="center" className="u-mt-8">
              <Button
                type="primary"
                onClick={() => append({
                  eventDuration: [new Date().toISOString(), new Date().toISOString()], date: new Date().toISOString()
                })}
              >
                <PlusCircleOutlined />
                {t('systemManagement.addConfig')}
              </Button>
            </Space>
          )}
          <div className="p-system_eventTimeConfig_submit u-mt-16">
            <Button
              type="primary"
              disabled={!canEdit}
              loading={isEditLoading}
              onClick={method.handleSubmit(handleSubmit)}
            >
              <SaveOutlined />
              {t('system.save')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default EventTimeSM;
