import {
  Card, Col, Row,
  Spin, Typography
} from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import HeaderPage from 'common/components/HeaderPage';
import ManagementInfo from 'common/components/ManagementInfo';
import { getCustomerLuckyCodeDetailService } from 'common/services/customer';

const CustomerLuckyCodesDetail: React.FC<ActiveRoles> = ({
  roleIndex
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const codeParams = searchParams.get('code');

  /* Variables */
  const telcoData: { [key: number]: string } = {
    1: 'Viettel', 2: 'Vinaphone', 3: 'Mobifone', 4: 'Vietnamobile',
  };
  const sourceData: { [key: number]: string } = {
    1: 'Microsite', 2: 'ZaloOA', 3: 'SMS'
  };
  const prizeData: { [key: string]: string } = {
    'top-up': t('customerLuckyCode.topup'),
    '1st': t('customerLuckyCode.first'),
    special: t('customerLuckyCode.special'),
  };

  /* Queries */
  const queryKey = ['getCustomerLuckyCodeDetail', codeParams];
  const { data: customerDataDetail, isLoading } = useQuery(
    queryKey,
    () => {
      if (codeParams) {
        return getCustomerLuckyCodeDetailService(codeParams);
      }
      return null;
    },
    {
      enabled: !!codeParams && roleIndex,
    }
  );

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('customerLuckyCode.detailTitle')}
      />
      <Spin size="large" spinning={isLoading}>
        <div className="t-mainlayout_wrapper">
          <Row gutter={16}>
            <Col xxl={18} xl={16} lg={16}>
              <Card type="inner">
                <Row gutter={16}>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('customerLuckyCode.fullName')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {customerDataDetail?.fullName}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('customerLuckyCode.luckyCode')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {customerDataDetail?.luckyCode}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('customerLuckyCode.phone')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {customerDataDetail?.phone}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('customerLuckyCode.prize')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {prizeData[customerDataDetail?.prize || ''] || ''}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('customerLuckyCode.source')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {sourceData[customerDataDetail?.source || -1] || ''}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('customerLuckyCode.telco')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {telcoData[customerDataDetail?.telco || -1] || ''}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('customerLuckyCode.zaloPhone')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {customerDataDetail?.zaloPhone || ''}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('location.province')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {customerDataDetail?.province?.name || ''}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('location.district')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {customerDataDetail?.district?.name || ''}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('customerLuckyCode.store')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {customerDataDetail?.store?.name || ''}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('customerLuckyCode.note')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {customerDataDetail?.note || ''}
                    </Typography.Text>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xxl={6} xl={8} lg={8}>
              <ManagementInfo
                createdDate={customerDataDetail ? dayjs(customerDataDetail?.createdAt).fromNow() : ''}
                createdBy={customerDataDetail?.fullName || ''}
                lastUpdated=""
                lastUpdatedBy=""
              />
            </Col>
          </Row>
        </div>
      </Spin>
    </>
  );
};

export default CustomerLuckyCodesDetail;
