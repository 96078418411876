import {
  Card, Col,
  Row,
  Spin, Typography
} from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import HeaderPage from 'common/components/HeaderPage';
import { getCustomerLuckyDrawByIdService } from 'common/services/customerLuckyDraw';

const CustomerLuckyDrawDetail: React.FC<ActiveRoles> = ({
  roleIndex
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const idParams = Number(searchParams.get('id'));

  /* Variables */

  /* Queries */
  const queryKey = ['getCustomerLuckyDrawById', idParams];
  const { data: customerDataDetail, isLoading } = useQuery(
    queryKey,
    () => {
      if (idParams) {
        return getCustomerLuckyDrawByIdService(idParams);
      }
      return null;
    },
    {
      enabled: !!idParams && roleIndex,
    }
  );

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.customerLuckyDraw')}
      />
      <div className="t-mainlayout_wrapper">
        <Spin size="large" spinning={isLoading}>
          <Row gutter={16}>
            <Col xxl={24}>
              <Card type="inner">
                <Row gutter={16}>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('customerPrize.fullName')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {customerDataDetail?.customer?.fullName || ''}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('customerPrize.phone')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {customerDataDetail?.customer?.phone || ''}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('customerPrize.zaloPhone')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {customerDataDetail?.customer?.zaloPhone || ''}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('customerPrize.luckyCode')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {customerDataDetail?.customerDraftPrize?.luckyCode || ''}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('customerLuckyDraw.prize')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {customerDataDetail?.customerDraftPrize?.prize || ''}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('location.province')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {customerDataDetail?.customerDraftPrize?.province?.name || ''}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('location.district')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {customerDataDetail?.customerDraftPrize?.district?.name || ''}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('system.createdAt')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {customerDataDetail?.createdAt || ''}
                    </Typography.Text>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Spin>
      </div>
    </>
  );
};

export default CustomerLuckyDrawDetail;
