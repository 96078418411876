/* eslint-disable */
import {
  Space,
  Typography
} from 'antd';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import ReportReward from '../ReportReward';

import { getTopupSuccessReportService } from 'common/services/dashboard';
import { TopupSuccessReportTypes } from 'common/services/dashboard/types';
import { convertDDMMYYToYYYYMMDD } from 'common/utils/functions';

const dataFake: TopupSuccessReportTypes = {
  topupSuccess: [
    {
      date: "2024-07-15",
      total: 292
    },
    {
      date: "2024-07-16",
      total: 142
    },
    {
      date: "2024-07-17",
      total: 229
    },
    {
      date: "2024-07-18",
      total: 174
    },
    {
      date: "2024-07-19",
      total: 279
    },
    {
      date: "2024-07-20",
      total: 360
    },
    {
      date: "2024-07-21",
      total: 271
    },
    {
      date: "2024-07-22",
      total: 301
    },
    {
      date: "2024-07-23",
      total: 264
    },
    {
      date: "2024-07-24",
      total: 266
    },
    {
      date: "2024-07-25",
      total: 335
    },
    {
      date: "2024-07-26",
      total: 288
    },
    {
      date: "2024-07-27",
      total: 318
    },
    {
      date: "2024-07-28",
      total: 279
    },
    {
      date: "2024-07-29",
      total: 257
    },
    {
      date: "2024-07-30",
      total: 336
    },
    {
      date: "2024-07-31",
      total: 297
    },
    {
      date: "2024-08-01",
      total: 270
    },
    {
      date: "2024-08-02",
      total: 345
    },
    {
      date: "2024-08-03",
      total: 386
    },
    {
      date: "2024-08-04",
      total: 422
    },
    {
      date: "2024-08-05",
      total: 430
    },
    {
      date: "2024-08-06",
      total: 375
    },
    {
      date: "2024-08-07",
      total: 403
    },
    {
      date: "2024-08-08",
      total: 365
    },
    {
      date: "2024-08-09",
      total: 408
    },
    {
      date: "2024-08-10",
      total: 483
    },
    {
      date: "2024-08-11",
      total: 464
    },
    {
      date: "2024-08-12",
      total: 485
    },
    {
      date: "2024-08-13",
      total: 107
    }
  ],
  quotas: [
    {
      quotaDate: "2024-07-15",
      quota: 885
    },
    {
      quotaDate: "2024-07-16",
      quota: 885
    },
    {
      quotaDate: "2024-07-17",
      quota: 886
    },
    {
      quotaDate: "2024-07-18",
      quota: 886
    },
    {
      quotaDate: "2024-07-19",
      quota: 886
    },
    {
      quotaDate: "2024-07-20",
      quota: 886
    },
    {
      quotaDate: "2024-07-21",
      quota: 886
    },
    {
      quotaDate: "2024-07-22",
      quota: 1328
    },
    {
      quotaDate: "2024-07-23",
      quota: 1328
    },
    {
      quotaDate: "2024-07-24",
      quota: 1328
    },
    {
      quotaDate: "2024-07-25",
      quota: 1329
    },
    {
      quotaDate: "2024-07-26",
      quota: 1329
    },
    {
      quotaDate: "2024-07-27",
      quota: 1329
    },
    {
      quotaDate: "2024-07-28",
      quota: 1329
    },
    {
      quotaDate: "2024-07-29",
      quota: 1343
    },
    {
      quotaDate: "2024-07-30",
      quota: 1306
    },
    {
      quotaDate: "2024-07-31",
      quota: 1306
    },
    {
      quotaDate: "2024-08-01",
      quota: 1306
    },
    {
      quotaDate: "2024-08-02",
      quota: 1306
    },
    {
      quotaDate: "2024-08-03",
      quota: 1306
    },
    {
      quotaDate: "2024-08-04",
      quota: 1306
    },
    {
      quotaDate: "2024-08-05",
      quota: 799
    },
    {
      quotaDate: "2024-08-06",
      quota: 799
    },
    {
      quotaDate: "2024-08-07",
      quota: 799
    },
    {
      quotaDate: "2024-08-08",
      quota: 798
    },
    {
      quotaDate: "2024-08-09",
      quota: 798
    },
    {
      quotaDate: "2024-08-10",
      quota: 798
    },
    {
      quotaDate: "2024-08-11",
      quota: 798
    },
    {
      quotaDate: "2024-08-12",
      quota: 1000
    },
    {
      quotaDate: "2024-08-13",
      quota: 1000
    },
    {
      quotaDate: "2024-08-14",
      quota: 1000
    },
    {
      quotaDate: "2024-08-15",
      quota: 1000
    },
    {
      quotaDate: "2024-08-16",
      quota: 1000
    },
    {
      quotaDate: "2024-08-17",
      quota: 1000
    },
    {
      quotaDate: "2024-08-18",
      quota: 1000
    },
    {
      quotaDate: "2024-08-19",
      quota: 928
    },
    {
      quotaDate: "2024-08-20",
      quota: 928
    },
    {
      quotaDate: "2024-08-21",
      quota: 928
    },
    {
      quotaDate: "2024-08-22",
      quota: 929
    },
    {
      quotaDate: "2024-08-23",
      quota: 929
    },
    {
      quotaDate: "2024-08-24",
      quota: 929
    },
    {
      quotaDate: "2024-08-25",
      quota: 929
    },
    {
      quotaDate: "2024-08-26",
      quota: 928
    },
    {
      quotaDate: "2024-08-27",
      quota: 928
    },
    {
      quotaDate: "2024-08-28",
      quota: 928
    },
    {
      quotaDate: "2024-08-29",
      quota: 929
    },
    {
      quotaDate: "2024-08-30",
      quota: 929
    },
    {
      quotaDate: "2024-08-31",
      quota: 929
    },
    {
      quotaDate: "2024-09-01",
      quota: 929
    },
    {
      quotaDate: "2024-09-02",
      quota: 928
    },
    {
      quotaDate: "2024-09-03",
      quota: 928
    },
    {
      quotaDate: "2024-09-04",
      quota: 928
    },
    {
      quotaDate: "2024-09-05",
      quota: 929
    },
    {
      quotaDate: "2024-09-06",
      quota: 929
    },
    {
      quotaDate: "2024-09-07",
      quota: 929
    },
    {
      quotaDate: "2024-09-08",
      quota: 929
    },
    {
      quotaDate: "2024-09-09",
      quota: 928
    },
    {
      quotaDate: "2024-09-10",
      quota: 928
    },
    {
      quotaDate: "2024-09-11",
      quota: 928
    },
    {
      quotaDate: "2024-09-12",
      quota: 929
    },
    {
      quotaDate: "2024-09-13",
      quota: 929
    },
    {
      quotaDate: "2024-09-14",
      quota: 929
    },
    {
      quotaDate: "2024-09-15",
      quota: 929
    },
    {
      quotaDate: "2024-09-16",
      quota: 928
    }
  ]
};

const dateFormat = 'MM-YYYY';

const ReportTopUpSuccess: React.FC = () => {
  const { t } = useTranslation();
  // const [dateRange, setDateRange] = useState(defaultDateValue);

  const { data, isLoading } = useQuery(
    ['topup-success-report'],
    () => getTopupSuccessReportService({}),
  );

  function getWeekStartAndEndDates(year: number, month: number, weekNumber: number) {
    const startOfMonth = dayjs(new Date(year, month - 1, 1)); // Start of the month
    const startOfFirstWeek = startOfMonth.startOf('week'); // Start of the first week

    // Calculate the start date of the given week
    const startDate = startOfFirstWeek.add(weekNumber - 1, 'week');

    // Calculate the end date of the given week
    const endDate = startDate.endOf('week');

    return {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    };
  }

  const weeksPewOfMonth = (date: string, month: number) => {
    const year = dayjs(date, dateFormat).year();

    const weeksOfMonth: { week: number, startDate: string, endDate: string }[] = [];
    const daysOfMonth = [];
    const daysInMonth = dayjs(date, dateFormat).daysInMonth();
    // console.log('daysInMonth: ', daysInMonth);

    for (let i = 1; i <= daysInMonth; i += 1) {
      daysOfMonth.push(i);
    }
    for (let i = 1; i <= Math.ceil(daysInMonth / 7); i += 1) {
      const { startDate, endDate } = getWeekStartAndEndDates(year, month, i);
      weeksOfMonth.push({
        week: i,
        startDate: dayjs(startDate).format('DD/MM/YYYY'),
        endDate: dayjs(endDate).format('DD/MM/YYYY')
      });
    }
    // const labels = weeksOfMonth.map((item) => `${t('system.week')} ${item.week} (${item.startDate} - ${item.endDate})`);
    return weeksOfMonth;
  };

  const topupSuccessData = useMemo(() => {
    if (!data) return undefined;
    if (data.quotas.length === 0 && data.topupSuccess.length === 0) return undefined;

    // group months by data
    const groupByMonthsTopUp = data.topupSuccess.reduce((acc: any, cur) => {
      const month = cur.date.split('-')[1];
      if (!acc[month]) {
        acc[month] = [cur];
      }
      const findIndex = acc[month].findIndex((item: any) => item.date === cur.date);
      if (findIndex === -1) {
        acc[month].push(cur);
        acc[month].sort((a: any, b: any) => {
          return dayjs(a.date).unix() - dayjs(b.date).unix();
        });
      }
      return acc;
    }, {});

    const groupByMonthsQuota = data.quotas.reduce((acc: any, cur) => {
      const month = cur.quotaDate.split('-')[1];
      if (!acc[month]) {
        acc[month] = [cur];
      }
      const findIndex = acc[month].findIndex((item: any) => item.date === cur.quotaDate);
      if (findIndex === -1) {
        acc[month].push(cur);
        acc[month].sort((a: any, b: any) => {
          return dayjs(a.date).unix() - dayjs(b.date).unix();
        });
      }
      return acc;
    }, {});

    const groupByMonths = { ...groupByMonthsTopUp, ...groupByMonthsQuota };

    // get labels such as week 1 of month 1, week 2 of month 1, ...
    const weeksPewOfMonths = Object.keys(groupByMonths).sort().map((month) => {
      return weeksPewOfMonth(dayjs(groupByMonths[month][0].date).format(dateFormat), Number(month));;
    }).flat();

    // remove start date and end date of week duplicate in weeksPewOfMonths
    const weeksPewOfMonthsDuplicate: { week: number, startDate: string, endDate: string }[] = weeksPewOfMonths.reduce((acc: any, cur) => {
      const findIndex = acc.findIndex((item: { week: number, startDate: string, endDate: string }) => item.startDate === cur.startDate && item.endDate === cur.endDate);
      if (findIndex === -1) {
        acc.push(cur);
      }
      return acc;
    }, []);

    const topupSuccessDataWeek = weeksPewOfMonthsDuplicate.map((item) => {
      const total = data.topupSuccess.filter((topup) => {
        const dateTimestamp = dayjs(topup.date);
        const startDateTimestamp = dayjs(convertDDMMYYToYYYYMMDD(item.startDate));
        const endDateTimestamp = dayjs(convertDDMMYYToYYYYMMDD(item.endDate));
        return dateTimestamp >= startDateTimestamp && dateTimestamp <= endDateTimestamp;
      }).reduce((acc, cur) => acc + cur.total, 0);
      return total;
    });

    const quotasDataWeek = weeksPewOfMonthsDuplicate.map((item) => {
      const total = data.quotas.filter((quota) => {
        const dateTimestamp = dayjs(quota.quotaDate);
        const startDateTimestamp = dayjs(convertDDMMYYToYYYYMMDD(item.startDate));
        const endDateTimestamp = dayjs(convertDDMMYYToYYYYMMDD(item.endDate));
        return dateTimestamp >= startDateTimestamp && dateTimestamp <= endDateTimestamp;
      }).reduce((acc, cur) => acc + cur.quota, 0);
      return total;
    });

    // find same index all of topupSuccessDataWeek and quotasDataWeek is 0
    const indexZero = topupSuccessDataWeek.map((item, index) => {
      if (item === 0 && quotasDataWeek[index] === 0) {
        return index;
      }
      return -1;
    }).filter((item) => item !== -1);

    // remove index zero in topupSuccessDataWeek and quotasDataWeek
    const topupSuccessDataWeekFilter = topupSuccessDataWeek.filter((_, index) => !indexZero.includes(index));
    const quotasDataWeekFilter = quotasDataWeek.filter((_, index) => !indexZero.includes(index));

    // remove label zero in labels
    const labels = weeksPewOfMonthsDuplicate.filter((_, index) => !indexZero.includes(index)).map((item, index) => `${t('system.week')} ${index + 1} (${item.startDate} - ${item.endDate})`);

    return {
      labels,
      datasets: [
        {
          label: t('dashboard.topupSuccessCount'),
          data: topupSuccessDataWeekFilter,
          backgroundColor: '#2c6eab'
        },
        {
          label: t('dashboard.topupByPlan'),
          data: quotasDataWeekFilter,
          backgroundColor: '#FFA500'
        }
      ]
    };
  }, [data, t]);

  return (
    <div className="o-reportTopUpSuccess">
      <div className="o-reportTopUpSuccess_title">
        <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography.Title level={4} className="fs-16x24 color-brand-color2 o-reportTopUpSuccess_title">{t('dashboard.reportTopUpSuccessTitle')}</Typography.Title>
          {/* <MonthPicker
            allowClear
            placeholder="Select month"
            onChange={(date) => setDateRange(date?.format(dateFormat) || defaultDateValue)}
            style={{ width: 200 }}
            format={dateFormat}
            defaultValue={dayjs(dayjs(new Date()).format(dateFormat), dateFormat)}
          /> */}
        </Space>
      </div>
      <div className="o-reportTopUpSuccess_content u-mt-8">
        <ReportReward
          title=""
          height="100%"
          options={{
            indexAxis: 'y',
            elements: {
              bar: {
                borderWidth: 2,
              }
            },
            responsive: true,
            plugins: {
              legend: {
                position: 'bottom',
              },
            }
          }}
          data={topupSuccessData}
          idLegendTooltip="topup-success-report"
          isLoading={isLoading}
          type="bar"
          percentage
        />
      </div>
    </div>
  );
};

export default ReportTopUpSuccess;
