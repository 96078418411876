/* eslint-disable @typescript-eslint/no-unused-vars */
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button, Modal, Space, Typography, message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps, FilterValueProps, mappingFilterFields,
  mappingFilterToQuery, mappingParamsFilter, mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import useDidMount from 'common/hooks/useDidMount';
import { deleteMultipleStoreByIdService, getAllStoreService } from 'common/services/store';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';

type StoreTableTypes = {
  id: number
  name: string
  address: string
  createdAt: string
  updatedAt: string
};

const Store: React.FC<ActiveRoles> = ({
  roleCreate, roleDelete, roleUpdate
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);

  const pageParam = searchParams.get('page');

  /* Selectors */
  const {
    defaultPageSize,
    advancedFilter
  } = useAppSelector((state) => state.system);

  /* State */
  const [pageData, setPageData] = useState<StoreTableTypes[]>([]);
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));

  /* Memos */
  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('store', advancedFilter),
    [advancedFilter]
  );

  /* Queries */
  const queryKey = ['storeList', keyword, currentPage, currentView, selectedFilterList];
  const { isLoading } = useQuery(
    queryKey,
    () => getAllStoreService({
      keyword, page: currentPage, limit: currentView, ...returnParamsFilter
    }),
    {
      enabled: !!currentPage,
      onSuccess: (data) => {
        setPageData(data.data.map((item) => ({
          id: item.storeData.id,
          name: item.storeData.name,
          address: item.storeData.address,
          createdAt: item.storeData.createdAt,
          updatedAt: item.storeData.updatedAt
        })));
      }
    }
  );

  const { mutate: deleteMutate, isLoading: deleteLoading } = useMutation(
    ['deleteStore'],
    async (ids: number[]) => deleteMultipleStoreByIdService(ids),
    {
      onSuccess: () => {
        message.success(t('message.deleteSuccess'));
        queryClient.invalidateQueries(queryKey);
      },
      onError: () => {
        message.error(t('message.deleteError'));
      }

    }
  );

  /* Variables */
  const columns: ColumnsType<StoreTableTypes> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      render: (_name: string, data: StoreTableTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    {
      title: t('system.name'),
      dataIndex: 'name',
      width: 250,
      key: 'name',
      render: (_: string, data: StoreTableTypes) => (
        <Link to={`${ROUTE_PATHS.STORE_DETAIL}?id=${data.id}`}>
          <Typography.Text
            style={{ color: '#4a4a4a', cursor: 'pointer' }}
          >
            {data.name}
          </Typography.Text>
        </Link>
      ),
    },
    {
      title: t('store.address'),
      dataIndex: 'address',
      key: 'address',
      render: (_: string, data: StoreTableTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.address}
        </Typography.Text>
      ),
    },
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      width: 150,
      key: 'updatedAt',
      render: (_name: string, data: StoreTableTypes) => (
        <Typography.Text>
          {formatDateTime(data.updatedAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      width: 150,
      key: 'createdAt',
      render: (_name: string, data: StoreTableTypes) => (
        <Typography.Text>
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, data: StoreTableTypes) => (
        <Space>
          <Button
            disabled={!roleDelete}
            icon={<DeleteOutlined />}
            onClick={() => {
              Modal.confirm({
                className: 't-pagetable_deleteRecordModal',
                autoFocusButton: 'cancel',
                title: t('message.confirmDeleteRecord'),
                okText: t('system.ok'),
                cancelText: t('system.cancel'),
                cancelButtonProps: {
                  type: 'primary',
                },
                okButtonProps: {
                  type: 'default',
                },
                onOk: () => {
                  deleteMutate([data.id]);
                },
              });
            }}
          />
          <Link to={`${ROUTE_PATHS.STORE_DETAIL}?id=${data.id}`} className="tfc-btnIcon_link">
            <EditOutlined />
          </Link>
          {/* <Button
            className="tfc-btnIcon_link"
            disabled={!roleUpdate}
            icon={<EditOutlined />}
            // type="link"
            href={`${ROUTE_PATHS.STORE_DETAIL}?id=${data.id}`}
          /> */}
        </Space>
      ),
    },
  ];

  /* Functions */

  // Change page view
  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() }, { replace: true });
  };

  // Change page size
  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  // Add filter
  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  // Delete filter
  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
  };

  /* Effects */
  useEffect(() => {
    setCurrentPage(1);
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      page: '1'
    }, { replace: true });
  }, [keyword, setSearchParams, selectedFilterList]);

  useDidMount(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);
  });

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.store')} // title
        rightHeader={(
          <Button
            type="primary"
            disabled={!roleCreate}
            onClick={() => {
              navigate(ROUTE_PATHS.STORE_DETAIL);
            }}
          >
            <PlusOutlined />
            {t('system.create')}
          </Button>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          handleDelete={(data) => deleteMutate(data.map((item) => item.id))}
          isLoading={isLoading}
          handleSearch={setKeyword}
          roles={{ roleCreate, roleDelete, roleUpdate }}
          tableProps={{
            initShowColumns: ['id', 'name', 'address', 'createdAt', 'action'],
            columns,
            pageData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: pageData.length,
            noBaseCol: true,
            noDeleteLanguage: true,
            filterFields,
          }}
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter,
          }}
        />
      </div>
    </>
  );
};

export default Store;
