import { EyeOutlined, RedoOutlined, ShoppingOutlined } from '@ant-design/icons';
import {
  Button,
  Space, Tooltip, Typography, message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { AxiosError } from 'axios';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import ModalAttemptDetail from './modalAttemptDetail';

import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import { StatusBuyCardLabel, StatusTopUpAttemptLabel } from 'common/components/StatusLabel';
import {
  buyCardTopUpRequest,
  getTopupAttemptListById,
  retryTopUpRequest
} from 'common/services/topupRequest';
import { BuyCardStatusEnum, TopupAttemptItemTypes } from 'common/services/topupRequest/types';
import { telcos } from 'common/utils/constant';
import { detectError, formatDateTime, formatNumberWithSeparators } from 'common/utils/functions';
import roles, { getPermission } from 'configs/roles';

const TopUpAttemptManagement: React.FC<ActiveRoles> = ({ roleIndex, roleOther }) => {
  /* Hooks */
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  /* Search Params */
  const [searchParams] = useSearchParams();
  const idParam = searchParams.get('id') || '';

  /* States */
  const [openId, setOpenId] = useState<string>('');

  /* React-query */
  const { isLoading: listLoading, data: listData } = useQuery(
    ['topup-attempt-list'],
    () => getTopupAttemptListById({
      id: idParam,
    }),
    { enabled: !!idParam }
  );

  const { mutate: retryMutate, isLoading: retryLoading } = useMutation(
    ['topup-request-retry'],
    (id: number) => retryTopUpRequest(id),
    {
      onSuccess() {
        message.success(t('message.retrySuccess'));
        queryClient.invalidateQueries(['topup-attempt-list']);
      },
      onError(err) {
        if (err instanceof AxiosError) {
          message.error(detectError(Number(err.response?.status)));
        }
      }
    }
  );

  const { mutate: buyCardMutate, isLoading: buyCardLoading } = useMutation(
    ['topup-request-buyCard'],
    (id: number) => buyCardTopUpRequest(id),
    {
      onSuccess() {
        message.success(t('message.buyCardSuccess'));
      },
      onError(err) {
        if (err instanceof AxiosError) {
          message.error(detectError(Number(err.response?.status)));
        }
      }
    }
  );

  const historyData: TopupAttemptItemTypes[] = useMemo(() => listData?.history.reduce((
    acc: TopupAttemptItemTypes[],
    cur
  ) => {
    const lastAttemptData = listData.lastAttempts.find((i) => i.uid === openId);
    if (cur.parentId === lastAttemptData?.parentId
      && cur.id !== lastAttemptData.id) {
      // eslint-disable-next-line no-param-reassign
      acc = [...acc, cur];
    }

    return acc;
  }, listData?.lastAttempts?.find((i) => i.uid === openId)
    ? [listData?.lastAttempts?.find((i) => i.uid === openId)!] : []) || [], [listData, openId]);

  /* Datas */
  const columns: ColumnsType<TopupAttemptItemTypes> = useMemo(
    () => [
      // --- STT
      {
        title: 'ID',
        key: 'id',
        width: 75,
        align: 'center',
        render: (_name: string, data: TopupAttemptItemTypes) => (
          <Typography.Text>{data.id}</Typography.Text>
        ),
      },
      {
        title: 'Transaction ID',
        key: 'transactionId',
        align: 'center',
        render: (_name: string, data: TopupAttemptItemTypes) => (
          <Typography.Text>{data.uid}</Typography.Text>
        ),
      },
      {
        title: t('system.status'),
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        sorter: {
          compare: (a: TopupAttemptItemTypes, b: TopupAttemptItemTypes) => a.status - b.status,
        },
        sortDirections: ['descend', 'ascend'],
        render: (_name: string, data: TopupAttemptItemTypes) => (
          <StatusTopUpAttemptLabel status={data.status} />
        ),
      },
      {
        title: t('topupAttempt.buyCardStatus'),
        dataIndex: 'buyCardStatus',
        key: 'buyCardStatus',
        align: 'center',
        render: (_name: string, data: TopupAttemptItemTypes) => (
          <StatusBuyCardLabel status={data.buyCardStatus} />
        ),
      },
      {
        title: t('topupAttempt.cardCode'),
        dataIndex: 'cardCode',
        key: 'cardCode',
        align: 'center',
        render: (_name: string, data: TopupAttemptItemTypes) => (
          <Typography.Text>{data.cardCode || '-'}</Typography.Text>
        ),
      },
      {
        title: t('topupAttempt.responseCode'),
        dataIndex: 'responseCode',
        key: 'responseCode',
        align: 'center',
        render: (_name: string, data: TopupAttemptItemTypes) => (
          <Typography.Text>
            {data.statusCode || '-'}
          </Typography.Text>
        ),
      },
      {
        title: t('callCenter.telco'),
        dataIndex: 'telco',
        key: 'telco',
        align: 'center',
        render: (_name: string, data: TopupAttemptItemTypes) => (
          <Typography.Text>
            {telcos[data.telco as keyof typeof telcos]}
          </Typography.Text>
        ),
      },
      {
        title: t('topupRequest.amount'),
        dataIndex: 'amount',
        key: 'amount',
        align: 'center',
        sorter: {
          compare: (
            a: TopupAttemptItemTypes,
            b: TopupAttemptItemTypes
          ) => (a?.amount || 0)
            - (b?.amount || 0),
        },
        sortDirections: ['descend', 'ascend'],
        render: (_name: string, data: TopupAttemptItemTypes) => (
          <Typography.Text>
            {formatNumberWithSeparators(data.amount * 1000) || '-'}
          </Typography.Text>
        ),
      },
      // --- Cập nhật
      {
        title: t('system.createdAt'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: {
          compare: (a: TopupAttemptItemTypes, b: TopupAttemptItemTypes) => {
            const aDate = new Date(a.createdAt || 0);
            const bDate = new Date(b.createdAt || 0);
            return aDate.getTime() - bDate.getTime();
          },
        },
        sortDirections: ['descend', 'ascend'],
        render: (_name: string, data: TopupAttemptItemTypes) => (
          <Typography.Text>{formatDateTime(data.createdAt)}</Typography.Text>
        ),
      },
      {
        title: t('system.updatedAt'),
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        sorter: {
          compare: (a: TopupAttemptItemTypes, b: TopupAttemptItemTypes) => {
            const aDate = new Date(a.updatedAt || 0);
            const bDate = new Date(b.updatedAt || 0);
            return aDate.getTime() - bDate.getTime();
          },
        },
        sortDirections: ['descend', 'ascend'],
        render: (_name: string, data: TopupAttemptItemTypes) => (
          <Typography.Text>{formatDateTime(data.updatedAt)}</Typography.Text>
        ),
      },
      // --- Xem
      {
        title: t('system.action'),
        key: 'action',
        width: 100,
        align: 'center',
        render: (_name: string, data: TopupAttemptItemTypes) => {
          const { id, status, buyCardStatus } = data;
          const conditionBuyCard = status === 4 && (buyCardStatus === BuyCardStatusEnum.Failed
            || buyCardStatus === BuyCardStatusEnum.Never);
          return (
            <Space>
              <Button
                disabled={!(roleIndex)}
                icon={<EyeOutlined />}
                onClick={() => setOpenId(data.uid)}
              />
              <Tooltip title={t('system.retryTopUp')}>
                <Button
                  loading={retryLoading}
                  disabled={!(getPermission(roleOther, roles.TOPUP_ATTEMPT_RETRY)
                    && conditionBuyCard)}
                  icon={<RedoOutlined />}
                  onClick={() => retryMutate(id)}
                />
              </Tooltip>
              <Tooltip title={t('system.buyCardTopUp')}>
                <Button
                  loading={buyCardLoading}
                  disabled={!(getPermission(roleOther, roles.TOPUP_ATTEMPT_BUY_CARD)
                    && conditionBuyCard)}
                  icon={<ShoppingOutlined />}
                  onClick={() => buyCardMutate(id)}
                />
              </Tooltip>
            </Space>
          );
        }
      },
    ],
    [buyCardLoading, buyCardMutate, retryLoading, retryMutate, roleIndex, roleOther, t]
  );

  const tableData: TopupAttemptItemTypes[] = useMemo(
    () => listData?.lastAttempts.map((item) => ({
      ...item
    })) || [],
    [listData]
  );

  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.topupAttempt')}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={listLoading}
          noCheckbox
          tableProps={{
            initShowColumns: [
              'id',
              'transactionId',
              'status',
              'buyCardStatus',
              'cardCode',
              'responseCode',
              'telco',
              'amount',
              'createdAt',
              'updatedAt',
              'action',
            ],
            columns,
            pageData: tableData,
            noBaseCol: true,
            noDeleteLanguage: true,
          }}
        />
      </div>
      <ModalAttemptDetail
        isOpen={!!openId}
        info={historyData}
        handleClose={() => setOpenId('')}
      />
    </>
  );
};

export default TopUpAttemptManagement;
