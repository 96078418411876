import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Row,
  Space,
  Tag,
  Typography
} from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useMemo } from 'react';
import {
  Controller, UseFormReturn, useFieldArray, useWatch
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DropdownElement } from 'common/components/DropdownType';
import Input from 'common/components/Input';
import { PrizeFormData } from 'common/services/configPrizes/types';
import { phaseTypes } from 'common/utils/constant';

type Props = {
  index: number;
  editable?: boolean;
  provinceLoading: boolean;
  provinces: OptionType[];
  method: UseFormReturn<PrizeFormData>;
};

const PhasesFormArray: React.FC<Props> = ({
  index, editable, provinces, provinceLoading, method
}) => {
  const { t } = useTranslation();

  const { fields, append, remove } = useFieldArray({ control: method.control, name: `phases.${index}.provinceIds` });
  const { fields: fieldsBackup, append: appendBackup, remove: removeBackup } = useFieldArray({ control: method.control, name: `phases.${index}.provinceBackupIds` });
  const selectedPhase = useWatch({ name: 'phases', control: method.control }).filter((phase) => !!phase.phase).map((phase) => phase.phase);

  const getOptionsPhase = useCallback((idx: number) => {
    const value = method.getValues(`phases.${idx}.phase`);
    return !selectedPhase.includes(value)
      ? phaseTypes.filter((
        phase
      ) => !selectedPhase.includes(phase.value as number))
      : phaseTypes;
  }, [method, selectedPhase]);
  const quantity = useWatch({ name: `phases.${index}.quantity`, control: method.control });
  const quantityBackup = useWatch({ name: `phases.${index}.quantityBackup`, control: method.control });
  const total = useWatch({ name: `phases.${index}.provinceIds`, control: method.control });
  const totalBackup = useWatch({ name: `phases.${index}.provinceBackupIds`, control: method.control });
  const selectedProvince = useWatch({ name: `phases.${index}.provinceIds`, control: method.control })?.filter((province) => province.items.length > 0).map((province) => province.items).flat();
  const selectedProvinceBackup = useWatch({ name: `phases.${index}.provinceBackupIds`, control: method.control })?.filter((province) => province.items.length > 0).map((province) => province.items).flat();

  const remainQuantity = useMemo(() => {
    const totalInput = (total || []).reduce((
      acc,
      field
    ) => acc + (field.items.length * Number(field.quantity)), 0);
    return quantity - totalInput;
  }, [quantity, total]);

  const remainQuantityBackup = useMemo(() => {
    const totalInput = (totalBackup || []).reduce((
      acc,
      field
    ) => acc + (field.items.length * Number(field.quantity)), 0);
    return quantityBackup - totalInput;
  }, [quantityBackup, totalBackup]);

  const getOptionsProvince = useCallback((idx: number) => {
    const value = method.getValues(`phases.${index}.provinceIds.${idx}.items`);
    return provinces.filter((province) => !selectedProvince?.includes(Number(province.value))
      || value?.includes(Number(province.value)));
  }, [index, method, provinces, selectedProvince]);

  const getOptionsProvinceBackup = useCallback((idx: number) => {
    const value = method.getValues(`phases.${index}.provinceBackupIds.${idx}.items`);
    return provinces.filter((province) => !selectedProvinceBackup?.includes(Number(province.value))
      || value?.includes(Number(province.value)));
  }, [index, method, provinces, selectedProvinceBackup]);

  return (
    <Row gutter={16}>
      <Col span={24}>
        <div className="p-editPageTemplate_input">
          <Typography.Text strong>
            {t('prizeConfig.phase')}
            {' '}
            <Typography.Text strong type="danger">
              *
            </Typography.Text>
          </Typography.Text>
          <Controller
            name={`phases.${index}.phase`}
            control={method.control}
            render={({
              field: { value, onChange },
              fieldState: { error },
            }) => (
              <DropdownElement
                options={getOptionsPhase(index)}
                size="large"
                placeholder={`${t('system.select')} ${t('prizeConfig.phase')}`}
                locale="vi"
                value={value}
                onChange={(e) => {
                  onChange(e);
                }}
                error={error?.message}
                readOnly={!editable}
              />
            )}
          />
        </div>
      </Col>
      <Col span={12}>
        <div className="p-editPageTemplate_input u-mt-16">
          <Typography.Text strong>
            {t('prizeConfig.from')}
            {' '}
            <Typography.Text strong type="danger">
              *
            </Typography.Text>
          </Typography.Text>
          <Controller
            name={`phases.${index}.from`}
            control={method.control}
            render={({
              field: { value, onChange },
              fieldState: { error },
            }) => (
              <>
                <DatePicker
                  size="large"
                  value={value ? dayjs(value) : null}
                  showTime
                  onChange={(quotaDate) => onChange(quotaDate?.toISOString() || '')}
                  format="YYYY-MM-DD HH:mm:ss"
                  style={{ width: '100%' }}
                  allowClear
                  disabled={!editable}
                  className={`u-mt-8${!editable ? ' readonly' : ''}`}
                />
                {
                  error && (
                    <span className="a-input_errorMessage">
                      {error.message}
                    </span>
                  )
                }
              </>
            )}
          />
        </div>
      </Col>
      <Col span={12}>
        <div className="p-editPageTemplate_input u-mt-16">
          <Typography.Text strong>
            {t('prizeConfig.to')}
            {' '}
            <Typography.Text strong type="danger">
              *
            </Typography.Text>
          </Typography.Text>
          <Controller
            name={`phases.${index}.to`}
            control={method.control}
            render={({
              field: { value, onChange },
              fieldState: { error },
            }) => (
              <>
                <DatePicker
                  size="large"
                  showTime
                  value={value ? dayjs(value) : null}
                  onChange={(quotaDate) => onChange(quotaDate?.toISOString() || '')}
                  format="YYYY-MM-DD HH:mm:ss"
                  style={{ width: '100%' }}
                  allowClear
                  disabled={!editable}
                  className={`u-mt-8${!editable ? ' readonly' : ''}`}
                />
                {
                  error && (
                    <span className="a-input_errorMessage">
                      {error.message}
                    </span>
                  )
                }
              </>
            )}
          />
        </div>
      </Col>
      <Col span={12}>
        <div className="p-editPageTemplate_input u-mt-16">
          <Typography.Text strong>
            {t('prizeConfig.quantity')}
            {' '}
            <Typography.Text strong type="danger">
              *
            </Typography.Text>
          </Typography.Text>
          <Controller
            name={`phases.${index}.quantity`}
            control={method.control}
            render={({
              field: { value, onChange },
              fieldState: { error },
            }) => (
              <Input
                value={value}
                type="number"
                className="u-mt-8"
                onChange={(fieldValue) => onChange(Number(fieldValue.target.value))}
                size="large"
                error={error?.message}
                readOnly={!editable}
              />
            )}
          />
        </div>
      </Col>
      <Col span={12}>
        <div className="p-editPageTemplate_input u-mt-16">
          <Typography.Text strong>
            {t('prizeConfig.quantityBackup')}
            {' '}
            <Typography.Text strong type="danger">
              *
            </Typography.Text>
          </Typography.Text>
          <Controller
            name={`phases.${index}.quantityBackup`}
            control={method.control}
            render={({
              field: { value, onChange },
              fieldState: { error },
            }) => (
              <Input
                value={value}
                type="number"
                className="u-mt-8"
                onChange={(fieldValue) => onChange(Number(fieldValue.target.value))}
                size="large"
                error={error?.message}
                readOnly={!editable}
              />
            )}
          />
        </div>
      </Col>
      <Col span={24}>
        <Row gutter={16}>
          <Col span={12}>
            {fields.map((field, idx) => (
              <Card
                title={t('prizeConfig.quantity')}
                key={`prizeConfig-phase-${field.id || new Date().getTime()}-provincesId`}
                extra={fields.length > 1 && editable && (
                  <div onClick={() => remove(idx)}>
                    <MinusCircleOutlined style={{ fontSize: '20px', color: 'red', cursor: 'pointer' }} />
                  </div>
                )}
                style={{ marginTop: '24px' }}
              >
                <Row gutter={16}>
                  <Col span={24}>
                    <div className="p-editPageTemplate_input">
                      <Typography.Text strong>
                        {t('location.province')}
                        {' '}
                        <Typography.Text strong type="danger">
                          *
                        </Typography.Text>
                      </Typography.Text>
                      <Controller
                        name={`phases.${index}.provinceIds.${idx}.items`}
                        control={method.control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <DropdownElement
                            options={getOptionsProvince(idx)}
                            loading={provinceLoading}
                            size="large"
                            placeholder={`${t('system.select')} ${t('location.province')}`}
                            locale="vi"
                            value={value}
                            multiple={{
                              allowClear: true,
                            }}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            error={error?.message}
                            readOnly={!editable}
                          />
                        )}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="p-editPageTemplate_input u-mt-16">
                      <Typography.Text strong>
                        {t('prizeConfig.quantity')}
                        {' '}
                        <Typography.Text strong type="danger">
                          *
                        </Typography.Text>
                      </Typography.Text>
                      <Controller
                        name={`phases.${index}.provinceIds.${idx}.quantity`}
                        control={method.control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Input
                            value={value}
                            type="number"
                            className="u-mt-8"
                            onChange={(fieldValue) => onChange(Number(fieldValue.target.value))}
                            size="large"
                            error={error?.message}
                            readOnly={!editable}
                          />
                        )}
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
            ))}
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Tag color="red" className="u-mt-8" style={{ width: 'fit-content' }}>
                <Typography.Text type="danger" style={{ fontSize: '14px' }}>
                  * Còn lại
                  {' '}
                  {remainQuantity < 0 ? 0 : remainQuantity}
                  {' '}
                  giải sẽ được phân bổ cho các tỉnh còn lại
                </Typography.Text>
              </Tag>
            </div>
            {editable && (
              <Space className="u-mt-16" style={{ width: '100%' }} direction="vertical" align="center">
                <Button
                  type="primary"
                  onClick={() => append({
                    items: [], quantity: 0
                  })}
                >
                  <PlusCircleOutlined />
                  {t('prizeConfig.addProvince')}
                </Button>
              </Space>
            )}
          </Col>
          <Col span={12}>
            {fieldsBackup.map((field, idx) => (
              <Card
                title={t('prizeConfig.quantityBackup')}
                key={`prizeConfig-phase-${field.id || new Date().getTime()}-provincesId`}
                extra={fieldsBackup.length > 1 && editable && (
                  <div onClick={() => removeBackup(idx)}>
                    <MinusCircleOutlined style={{ fontSize: '20px', color: 'red', cursor: 'pointer' }} />
                  </div>
                )}
                style={{ marginTop: '24px' }}
              >
                <Row gutter={16}>
                  <Col span={24}>
                    <div className="p-editPageTemplate_input">
                      <Typography.Text strong>
                        {t('location.province')}
                        {' '}
                        <Typography.Text strong type="danger">
                          *
                        </Typography.Text>
                      </Typography.Text>
                      <Controller
                        name={`phases.${index}.provinceBackupIds.${idx}.items`}
                        control={method.control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <DropdownElement
                            options={getOptionsProvinceBackup(idx)}
                            loading={provinceLoading}
                            size="large"
                            placeholder={`${t('system.select')} ${t('location.province')}`}
                            locale="vi"
                            value={value}
                            multiple={{
                              allowClear: true,
                            }}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            error={error?.message}
                            readOnly={!editable}
                          />
                        )}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="p-editPageTemplate_input u-mt-16">
                      <Typography.Text strong>
                        {t('prizeConfig.quantity')}
                        {' '}
                        <Typography.Text strong type="danger">
                          *
                        </Typography.Text>
                      </Typography.Text>
                      <Controller
                        name={`phases.${index}.provinceBackupIds.${idx}.quantity`}
                        control={method.control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Input
                            value={value}
                            type="number"
                            className="u-mt-8"
                            onChange={(fieldValue) => onChange(Number(fieldValue.target.value))}
                            size="large"
                            error={error?.message}
                            readOnly={!editable}
                          />
                        )}
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
            ))}
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Tag color="red" className="u-mt-8" style={{ width: 'fit-content' }}>
                <Typography.Text type="danger" style={{ fontSize: '14px' }}>
                  * Còn lại
                  {' '}
                  {remainQuantityBackup < 0 ? 0 : remainQuantityBackup}
                  {' '}
                  giải dự phòng sẽ được phân bổ cho các tỉnh còn lại
                </Typography.Text>
              </Tag>
            </div>
            {editable && (
              <Space className="u-mt-16" style={{ width: '100%' }} direction="vertical" align="center">
                <Button
                  type="primary"
                  onClick={() => appendBackup({
                    items: [], quantity: 0,
                  })}
                >
                  <PlusCircleOutlined />
                  {t('prizeConfig.addProvince')}
                </Button>
              </Space>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PhasesFormArray;
