import {
  Space, Typography
} from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import mapModifiers, { formatNumberWithSeparators } from 'common/utils/functions';

export type ConvertRewardProps = {
  title: string;
  sended: {
    quantity: number;
    rate: number;
  };
  pending?: {
    quantity: number;
    rate: number;
  };
  total: number;
  remain: {
    quantity: number;
    rate: number
  };
  type: 'first' | 'special' | 'topup';
};

export const ConvertReward: React.FC<ConvertRewardProps> = ({
  title,
  sended,
  pending,
  total,
  remain,
  type
}) => {
  const { t } = useTranslation();
  return (
    <div className={mapModifiers('o-convertReward', type === 'topup' ? 'hiddenConfirmed' : '')}>
      <div className="o-convertReward_titleWrapper">
        <Typography.Title level={4} className={mapModifiers('o-convertReward_title', type)}>{title}</Typography.Title>
        <Typography.Text className={mapModifiers('o-convertReward_title', type)}>{`${t('dashboard.totalPrizes')}: ${formatNumberWithSeparators(total)}`}</Typography.Text>
      </div>
      <div className="o-convertReward_content">
        {pending && (
          <div className="o-convertReward_content_item">
            <Space className="o-convertReward_content_item_wrapper" direction="vertical" style={{ width: '100%', height: '100%' }}>
              <Typography.Text className="o-convertReward_content_item-heading" type="secondary">
                {
                  t('dashboard.pending')
                }
              </Typography.Text>
              <Typography.Text className="o-convertReward_content_item-value">{`${formatNumberWithSeparators(pending.quantity)}`}</Typography.Text>
              <Typography.Text className="o-convertReward_content_item-value">{`${pending?.rate}%`}</Typography.Text>
            </Space>
          </div>
        )}
        <div className="o-convertReward_content_item">
          <Space className="o-convertReward_content_item_wrapper" direction="vertical" style={{ width: '100%', height: '100%' }}>
            <Typography.Text className="o-convertReward_content_item-heading" type="secondary">
              {
                type === 'topup' ? t('dashboard.topupSuccess') : t('dashboard.awards')
              }
            </Typography.Text>
            <Typography.Text className="o-convertReward_content_item-value">{`${formatNumberWithSeparators(sended.quantity)}`}</Typography.Text>
            <Typography.Text className="o-convertReward_content_item-value">{`${sended?.rate}%`}</Typography.Text>
          </Space>
        </div>
        <div className="o-convertReward_content_item">
          <Space className="o-convertReward_content_item_wrapper" direction="vertical" style={{ width: '100%', height: '100%' }}>
            <Typography.Text className="o-convertReward_content_item-heading" type="secondary">
              {
                type === 'topup' ? t('dashboard.topupRemain') : t('dashboard.remaining')
              }
            </Typography.Text>
            <Typography.Text className="o-convertReward_content_item-value">{`${formatNumberWithSeparators(remain.quantity)}`}</Typography.Text>
            <Typography.Text className="o-convertReward_content_item-value">{`${remain.rate}%`}</Typography.Text>
          </Space>
        </div>
      </div>
    </div>
  );
};
