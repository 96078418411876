/* eslint-disable */
import {
  Col,
  Row,
  Space,
  Tooltip,
  Typography
} from 'antd';
import React, { createContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import HeadingPrizeSection from './HeadingPrizeSection';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { useAppSelector } from 'app/store';
import ReportLimit from 'common/components/ReportLimited';
import ReportReward from 'common/components/ReportReward';
import ReportTopUpSuccess from 'common/components/ReportTopUpSuccess';
import ReportTopUpUser from 'common/components/ReportTopUpUser';
import { getCustomerParticipatedService } from 'common/services/dashboard';
import roles, { getPermission } from 'configs/roles';
import { useQuery } from 'react-query';
import ReportTopUpSummaries from "common/components/ReportTopUpSummaries";
import ReportSMSBrandName from "common/components/ReportSMSBrandName";

const BACKGROUND_COLOR = [
  'rgba(67, 114, 194, 1)',
  'rgba(236, 124, 48, 1)',
  'rgba(163, 163, 163, 1)',
];

export const PhaseContext = createContext({
  phaseExecute: -1,
  setPhaseExecute: (val: number) => { },
});

const Home: React.FC = () => {
  const rolesUser = useAppSelector((state) => state.auth.roles);
  const { t } = useTranslation();
  const [phaseExecute, setPhaseExecute] = useState<number>(-1);

  const roleHeadingPrizeSection = rolesUser.includes('*')
    || rolesUser.includes(roles.DASHBOARD_FIRST_PRIZE_COUNT)
    || rolesUser.includes(roles.DASHBOARD_SPECIAL_PRIZE_COUNT)
    || rolesUser.includes(roles.DASHBOARD_TOPUP_PRIZE_COUNT);

  const roleCustomerParticipated = getPermission(rolesUser, roles.DASHBOARD_CUSTOMER_PARTICIPATED);

  const roleTopupUserSection = getPermission(rolesUser, roles.DASHBOARD_TOPUP_USER_REPORT);
  const roleTopupLimitSection = getPermission(rolesUser, roles.DASHBOARD_TOPUP_LIMIT_REPORT);
  const roleTopupSuccessSection = getPermission(rolesUser, roles.DASHBOARD_TOPUP_SUCCESS_REPORT);
  const roleTopupSummariesSection = getPermission(rolesUser, roles.DASHBOARD_TOPUP_SUMMARY);
  const roleTopupSMSBrandNameSection = getPermission(rolesUser, roles.DASHBOARD_SMS_BRAND_NAME);

  const roleTopup = roleTopupUserSection || roleTopupLimitSection || roleTopupSuccessSection || roleTopupSummariesSection;

  const { data: customerParticipated } = useQuery(
    ['getCustomerParticipatedPrizeExchange', phaseExecute],
    () => getCustomerParticipatedService({
      phase: phaseExecute >= 0 ? Number(phaseExecute) : undefined
    }),
    {
      enabled: roleCustomerParticipated && phaseExecute !== -1,
    }
  );

  const dataFirst = useMemo(() => {
    if (!customerParticipated) return [];
    return {
      labels: [
        t('dashboard.customerParticipatedMicro'),
        t('dashboard.customerParticipatedZaloOA'),
        t('dashboard.customerParticipatedSMS'),
      ],
      datasets: [
        {
          data: [
            customerParticipated.microsite.quantity,
            customerParticipated.zaloOA.quantity,
            customerParticipated.sms.quantity,
          ],
          backgroundColor: BACKGROUND_COLOR,
          borderColor: BACKGROUND_COLOR,
          hoverBackgroundColor: BACKGROUND_COLOR,
          // datalabels: {
          //   labels: {
          //     // index: {
          //     //   align: 'end',
          //     //   anchor: 'end',
          //     //   color: function (ctx: any) {
          //     //     return ctx.dataset.backgroundColor;
          //     //   },
          //     //   font: { size: 18 },
          //     //   formatter: function (value: any, ctx: any) {
          //     //     return ctx.chart.data.labels[ctx.dataIndex];
          //     //   },
          //     //   offset: 8,
          //     //   opacity: function (ctx: any) {
          //     //     return ctx.active ? 1 : 0.5;
          //     //   }
          //     // },
          //     name: {
          //       align: 'top',
          //       font: { size: 16 },
          //       formatter: function (value: any, ctx: any) {
          //         return ctx.chart.data.labels[ctx.dataIndex];
          //       }
          //     },
          //     value: {
          //       align: 'bottom',
          //       backgroundColor: function (ctx: any) {
          //         var value = ctx.dataset.data[ctx.dataIndex];
          //         return value > 50 ? 'white' : null;
          //       },
          //       borderColor: 'white',
          //       borderWidth: 2,
          //       borderRadius: 4,
          //       color: function (ctx: any) {
          //         var value = ctx.dataset.data[ctx.dataIndex];
          //         return value > 50
          //           ? ctx.dataset.backgroundColor
          //           : 'white';
          //       },
          //       formatter: function (value: any, ctx: any) {
          //         var dataset = ctx.dataset;
          //         var value = dataset.data[ctx.dataIndex];
          //         const sumDataIndex = dataset.data?.reduce((acc: any, val: any) => Number(acc) + Number(val), 0);

          //         return `${value},\n Tỉ lệ: ${(value / sumDataIndex * 100).toFixed(2)}%`;
          //         // return Math.round(value * 1000) / 1000;
          //       },
          //       padding: 4
          //     },

          //   }
          // }
        },
      ],
    };
  }, [customerParticipated, t]);

  const micrositeData = useMemo(() => {
    if (!customerParticipated) return [];
    return {
      labels: [
        t('dashboard.northern'),
        t('dashboard.central'),
        t('dashboard.southern'),
      ],
      datasets: [
        {
          data: [
            customerParticipated.microsite.regions.northernVietnam,
            customerParticipated.microsite.regions.centralVietnam,
            customerParticipated.microsite.regions.southernVietnam,
          ],
          backgroundColor: BACKGROUND_COLOR,
          borderColor: BACKGROUND_COLOR,
          hoverBackgroundColor: BACKGROUND_COLOR,
          // borderWidth: 1,
        },
      ],
    };
  }, [customerParticipated, t]);

  const zaloData = useMemo(() => {
    if (!customerParticipated) return [];
    return {
      labels: [
        t('dashboard.northern'),
        t('dashboard.central'),
        t('dashboard.southern'),
      ],
      datasets: [
        {
          data: [
            customerParticipated.zaloOA.regions.northernVietnam,
            customerParticipated.zaloOA.regions.centralVietnam,
            customerParticipated.zaloOA.regions.southernVietnam,
          ],
          backgroundColor: BACKGROUND_COLOR,
          borderColor: BACKGROUND_COLOR,
          hoverBackgroundColor: BACKGROUND_COLOR,
        },
      ],
    };
  }, [customerParticipated, t]);

  const options = useMemo(
    () => ({
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom' as const,
          labels: {
            // This more specific font property overrides the global property
            font: {
              size: 14,
            },
            boxWidth: 12,
            boxHeight: 12,
          },
        }
      },
    }),
    []
  );

  return (
    <PhaseContext.Provider value={{ phaseExecute, setPhaseExecute }}>
      <div className="p-home">
        <Space direction="vertical" size={40} style={{ width: '100%' }}>
          {roleHeadingPrizeSection && (
            <section className="p-home_section">
              <HeadingPrizeSection />
            </section>
          )}
          {roleCustomerParticipated && (
            <section className="p-home_section">
              <Space direction="vertical" size={24} className="u-mt-20" style={{ width: '100%' }}>
                <Row gutter={24}>
                  <Col span={24}>
                    <ReportReward
                      heading={(
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography.Title level={3} className="t-generalSection_title">
                            {t('dashboard.titleCustomerParticipated')}
                          </Typography.Title>
                          <Tooltip
                            placement="right"
                            title={() => (
                              <Typography.Text
                                style={{ display: 'block', color: '#fff' }}
                                className="fs-16x24 p-home_reportAward_note_item"
                              >
                                {t('dashboard.titleCustomerParticipatedTooltip')}
                              </Typography.Text>
                            )}
                          >
                            <QuestionCircleOutlined style={{ fontSize: 20 }}
                              className="p-home_reportAward_note" />
                          </Tooltip>
                        </div>
                      )}
                      title={t('dashboard.titleThreeChannel')}
                      options={options}
                      data={dataFirst}
                      idLegendTooltip="tooltip-pieChart-first"
                      height={200}
                      width={200}
                      percentage
                    />
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24}>
                    <Space direction="vertical" size={12} className="o-reportReward">
                      <Typography.Title level={3} className="t-generalSection_title">
                        {t('dashboard.titleCustomerArea')}
                      </Typography.Title>
                      <Row gutter={24}>
                        <Col span={12}>
                          <ReportReward
                            title={t('dashboard.titleMicrositeReport')}
                            options={options}
                            data={micrositeData}
                            idLegendTooltip="tooltip-pieChart-microsite"
                            height={200}
                            width={200}
                            percentage
                            hasDatalabels
                          />
                        </Col>
                        <Col span={12}>
                          <ReportReward
                            title={t('dashboard.titleZaloOAReport')}
                            options={options}
                            data={zaloData}
                            idLegendTooltip="tooltip-pieChart-zaloOA"
                            height={200}
                            width={200}
                            percentage
                            hasDatalabels
                          />
                        </Col>
                      </Row>
                    </Space>
                  </Col>
                </Row>
              </Space>
            </section>
          )}
        </Space>
        <section className="p-home_section">
          {roleTopup && (
            <Typography.Title level={3} className="t-generalSection_title">
              {t('dashboard.topupWinner')}
            </Typography.Title>
          )}
          {roleTopupLimitSection && (
            <div className="u-mt-20">
              <ReportLimit />
            </div>
          )}
          {roleTopupUserSection && (
            <div className="u-mt-20">
              <ReportTopUpUser />
            </div>
          )}
          {roleTopupSummariesSection && (
            <div className="u-mt-20">
              <ReportTopUpSummaries />
            </div>
          )}
          {roleTopupSMSBrandNameSection && (
            <div className="u-mt-20">
              <ReportSMSBrandName />
            </div>
          )}
          {roleTopupSuccessSection && (
            <div className="u-mt-20">
              <ReportTopUpSuccess />
            </div>
          )}
        </section>
      </div>
    </PhaseContext.Provider >
  );
};

export default Home;
