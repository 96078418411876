import React from 'react';

import TopUpRequestManagementByPhoneContainer from './topupRequestByPhoneContainer';
import TopUpRequestManagementContainer from './topupRequestContainer';

import roles, { getPermission } from 'configs/roles';

const TopUpRequestManagement: React.FC<ActiveRoles> = (props) => {
  const { roleOther, roleIndex } = props;
  const isFetchByPhone = !roleIndex
    && getPermission(roleOther, roles.TOPUP_ATTEMPT_BY_PHONE);
  return isFetchByPhone ? <TopUpRequestManagementByPhoneContainer {...props} />
    : <TopUpRequestManagementContainer {...props} />;
};

export default TopUpRequestManagement;
