import axiosInstance from '../common/instance';

import {
  ConfigHeaderParams, ConfigHeaderTypes,
  EventTimeConfig,
  InitialSystemData,
  SaveErrorConfigParams,
  SeoGeneralParamsTypes,
  SeoGeneralTypes,
  SystemGeneralData,
  SystemLocalesData,
  UpdateSystemGeneralParams,
  UpdateSystemLocalesParams
} from './types';

export const getConfigService = async (code: string): Promise<ConfigHeaderTypes> => {
  const res = await axiosInstance.get(`config-${code}`);
  return res.data.data;
};

export const saveConfigService = async (code: string, data: ConfigHeaderParams): Promise<void> => {
  await axiosInstance.post(`config-${code}`, data);
};

export const getSeoGeneralService = async (): Promise<SeoGeneralTypes> => {
  const res = await axiosInstance.get('seo-general');
  return res.data.data;
};

export const postSeoGeneralService = async (params: SeoGeneralParamsTypes) => {
  const res = await axiosInstance.post('seo-general', params);
  return res.data;
};

export const getSystemInitialService = async (): Promise<InitialSystemData> => {
  const res = await axiosInstance.get('initial');
  return res.data.data;
};

export const getSystemLocalesService = async (): Promise<SystemLocalesData> => {
  const res = await axiosInstance.get('system-locales');
  return res.data.data;
};

export const updateSystemLocalesService = async (params: UpdateSystemLocalesParams):
  Promise<void> => {
  await axiosInstance.post('system-locales', params);
};

export const getSystemGeneralService = async ():
  Promise<SystemGeneralData> => {
  const res = await axiosInstance.get('system-general');
  return res.data.data;
};

export const updateSystemGeneralService = async (params: UpdateSystemGeneralParams):
  Promise<void> => {
  console.log('🚀 ~ params:', params);
  const bodyForm = new FormData();
  const gaids = params.gaIds?.split(',');
  gaids?.forEach((item) => {
    bodyForm.append('gaIds[]', item.trim());
  });
  const gtmids = params.gtmIds?.split(',');
  gtmids?.forEach((item) => {
    bodyForm.append('gtmIds[]', item.trim());
  });
  if (params.gMapId) {
    bodyForm.append('gMapId', params.gMapId);
  }
  if (params.fileFavicon) {
    bodyForm.append('fileFavicon', params.fileFavicon);
  }
  if (params.eventFrom) {
    bodyForm.append('eventFrom', params.eventFrom);
  }
  if (params.eventTo) {
    bodyForm.append('eventTo', params.eventTo);
  }
  if (params.luckyDrawFirstDuration) {
    bodyForm.append('luckyDrawFirstDuration', params.luckyDrawFirstDuration.toString());
  }
  if (params.luckyDrawFirstBackupDuration) {
    bodyForm.append('luckyDrawFirstBackupDuration', params.luckyDrawFirstBackupDuration.toString());
  }
  if (params.luckyDrawSpecialDuration) {
    bodyForm.append('luckyDrawSpecialDuration', params.luckyDrawSpecialDuration.toString());
  }
  if (params.luckyDrawSpecialBackupDuration) {
    bodyForm.append('luckyDrawSpecialBackupDuration', params.luckyDrawSpecialBackupDuration.toString());
  }
  await axiosInstance.post('system-general', bodyForm);
};

export const getErrorConfigService = async (id: string):
  Promise<ConfigHeaderTypes> => {
  const res = await axiosInstance.get(`errors-pages/${id}`);
  return res.data.data;
};

export const saveErrorConfigService = async (id: string, data: SaveErrorConfigParams):
  Promise<void> => {
  await axiosInstance.post(`errors-pages/${id}`, data);
};

export const getEventTimeConfig = async ():
  Promise<EventTimeConfig[]> => {
  const res = await axiosInstance.get('event-time-config');
  return res.data.data;
};
export const editEventTimeConfig = async (params: EventTimeConfig[]) => {
  const res = await axiosInstance.post('event-time-config', { data: params });
  return res.data;
};

export const getBlacklistFirstPrizeConfig = async ():
  Promise<string[]> => {
  const res = await axiosInstance.get('backlist-first-prize-config');
  return res.data.data;
};
export const editBlacklistFirstPrizeConfig = async (params: string[]) => {
  const res = await axiosInstance.post('backlist-first-prize-config', { data: params });
  return res.data;
};

export const getBlacklistSpecialPrizeConfig = async ():
  Promise<string[]> => {
  const res = await axiosInstance.get('backlist-special-prize-config');
  return res.data.data;
};
export const editBlacklistSpecialPrizeConfig = async (params: string[]) => {
  const res = await axiosInstance.post('backlist-special-prize-config', { data: params });
  return res.data;
};
