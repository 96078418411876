export const languageList = [
  {
    label: 'Tiếng Việt',
    value: 'vi',
  },
  {
    label: 'English',
    value: 'en',
  },
];

export const samplePage = [...Array(10)].map((_, idx) => ({
  label: `Trang mẫu ${idx + 1}`,
  value: `${idx + 1}`,
}));

export const statusDummy = [
  {
    label: 'Bản nháp',
    value: 1,
  },
  {
    label: 'Gửi duyệt',
    value: 7,
  },
  {
    label: 'Đã duyệt',
    value: 13,
  },
];

export const contactStatusDummy = [
  {
    label: 'Mới',
    value: 1,
  },
  {
    label: 'Chờ duyệt',
    value: 7,
  },
  {
    label: 'Đã duyệt',
    value: 13,
  },
];

export const topupRequestStatusDummy = [
  {
    label: 'Đang trong tiến trình',
    value: 1,
  },
  {
    label: 'Thành công',
    value: 2,
  },
  {
    label: 'Thất bại',
    value: 3,
  },
];

export const telcoDummy = [
  {
    label: 'Viettel',
    value: 1,
  },
  {
    label: 'Vinaphone',
    value: 2,
  },
  {
    label: 'Mobifone',
    value: 3,
  },
  {
    label: 'Vietnamobile',
    value: 4,
  },
];

export const provinceLeaderStatusDummy = [
  {
    label: 'Active',
    value: 1,
  },
  {
    label: 'Inactive',
    value: 7,
  },
];
