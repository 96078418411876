import axiosInstance from '../common/instance';

import { SMSGatewaysResponseTypes } from './types';

export const getAllSMSGatewayService = async (
  params?: BaseFilterParams
): Promise<APIPaginationResponse<SMSGatewaysResponseTypes[]>> => {
  const res = await axiosInstance.get('sms-gateways', { params });
  return res.data;
};

export const exportSMSGatewayService = async (
  params?: BaseFilterParams
): Promise<{ link: string }> => {
  const res = await axiosInstance.post('sms-gateways/export', null, { params });
  return res.data.data;
};

export const placeholder = '';
