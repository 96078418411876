import { EyeOutlined } from '@ant-design/icons';
import {
  Button, Select, Space, Tooltip, Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, {
  useEffect, useMemo, useState
} from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import PageTable from 'common/components/PageTable';
import { StatusTopUpLabel } from 'common/components/StatusLabel';
import { getInfoTopUpRequestForHotline } from 'common/services/topupRequest';
import { TopUpRequestItemTypes, TopupRequestHotlineParamsTypes } from 'common/services/topupRequest/types';
import {
  ROUTE_PATHS, customerLuckyCodePhases, sources, telcos
} from 'common/utils/constant';
import { formatDateTime, formatPhoneNumber } from 'common/utils/functions';
import roles, { getPermission } from 'configs/roles';

type FormFilterTypes = {
  phone: string;
  prizeCode: string
};

const TopUpRequestManagementByPhoneContainer: React.FC<ActiveRoles> = ({
  roleOther,
  roleCreate,
  roleUpdate
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();

  /* Search Params */
  const [searchParams, setSearchParams] = useSearchParams();
  // const params = useMemo(() => {
  //   const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
  //   if (searchParams.has('page')) delete paramsObj.page;
  //   return paramsObj;
  // }, [searchParams]);

  const pageParam = searchParams.get('page');
  const phoneParam = searchParams.get('phone');
  const prizeCodeParam = searchParams.get('prizeCode');

  /* Selectors */
  const { defaultPageSize } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(Number(pageParam) || 1);
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [selectedPhase, setSelectedPhase] = useState<number | undefined>(undefined);

  /* React-query */
  const queryKeys = ['topup-request-by-phone-list', selectedPhase];
  const {
    mutate: getTopUpHotlineMutate,
    isLoading: listLoading,
    data: listData,
  } = useMutation(
    queryKeys,
    (params: TopupRequestHotlineParamsTypes) => getInfoTopUpRequestForHotline({
      ...params,
      ...selectedPhase && { phase: selectedPhase },
    }),
  );

  /* Datas */
  const columns: ColumnsType<TopUpRequestItemTypes> = useMemo(() => ([
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (_name: string, data: TopUpRequestItemTypes) => (
        <Typography.Text className="max-content">
          {data.id}
        </Typography.Text>
      ),
    },
    {
      title: t('topupRequest.telco'),
      dataIndex: 'telco',
      key: 'telco',
      render: (_name: string, data: TopUpRequestItemTypes) => (
        <Typography.Text>
          {telcos[data.telco as keyof typeof telcos]}
        </Typography.Text>
      ),
    },
    {
      title: t('system.phone'),
      dataIndex: 'phone',
      key: 'phone',
      render: (_name: string, data: TopUpRequestItemTypes) => (
        <Typography.Text>
          {formatPhoneNumber(data.phone, true)}
        </Typography.Text>
      ),
    },
    {
      title: t('customerPrize.source'),
      dataIndex: 'source',
      width: 150,
      key: 'source',
      align: 'center',
      render: (_name: string, data: TopUpRequestItemTypes) => (
        <Typography.Text>
          {sources[data.source as keyof typeof sources]}
        </Typography.Text>
      ),
    },
    {
      title: t('customerLuckyCode.luckyCode'),
      dataIndex: 'luckyCode',
      width: 200,
      key: 'luckyCode',
      render: (_name: string, data: TopUpRequestItemTypes) => (
        <Typography.Text>
          {data.luckyCode}
        </Typography.Text>
      ),
    },
    {
      title: t('callCenter.topupStatus'),
      dataIndex: 'topupStatus',
      key: 'topupStatus',
      width: 300,
      // sorter: {
      //   compare: (
      //     a: TopUpRequestItemTypes,
      //     b: TopUpRequestItemTypes
      //   ) => a.status - b.status,
      // },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: TopUpRequestItemTypes) => (
        <Space style={{ justifyContent: 'space-between', width: '100%' }}>
          {
            data.status && (
              <StatusTopUpLabel
                status={data.status}
              />
            )
          }
        </Space>
      ),
    },
    // {
    //   title: t('callCenter.postPaid'),
    //   dataIndex: 'postPaid',
    //   key: 'postPaid',
    //   width: 100,
    //   render: (_name: string, data: TopUpRequestItemTypes) => (
    //     <Typography.Text>{data.postPaid ? 'Thuê bao trả sau' :
    // 'Thuê bao trả trước'}</Typography.Text>
    //   ),
    // },
    // {
    //   title: t('system.attemptStatusCode'),
    //   dataIndex: 'attemptStatusCode',
    //   align: 'center',
    //   key: 'attemptStatusCode',
    //   render: (_name: string, { lastAttemptData }: TopUpRequestItemTypes) => (
    //     <Typography.Text>
    //       {lastAttemptData?.response?.data?.code ||
    // lastAttemptData?.response?.errors?.code || '-'}
    //     </Typography.Text>
    //   ),
    // },

    // --- Thoi gian tạo
    {
      title: t('topupRequest.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      // sorter: {
      //   compare: (a: TopUpRequestItemTypes, b: TopUpRequestItemTypes) => {
      //     const aDate = new Date(a.createdAt || 0);
      //     const bDate = new Date(b.createdAt || 0);
      //     return Number(aDate) - Number(bDate);
      //   },
      // },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: TopUpRequestItemTypes) => (
        <Typography.Text>
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    // // --- Thành công lúc
    {
      title: t('topupRequest.successAt'),
      dataIndex: 'successAt',
      key: 'successAt',
      // sorter: {
      //   compare: (a: TopUpRequestItemTypes, b: TopUpRequestItemTypes) => {
      //     const aDate = new Date(a.successAt || 0);
      //     const bDate = new Date(b.successAt || 0);
      //     return Number(aDate) - Number(bDate);
      //   },
      // },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: TopUpRequestItemTypes) => (
        <Typography.Text>
          {formatDateTime(data.successAt)}
        </Typography.Text>
      ),
    },
    // --- Xem
    {
      title: t('system.action'),
      key: 'action',
      align: 'center',
      width: 100,
      render: (_name: string, _data: TopUpRequestItemTypes) => {
        const {
          id
        } = _data;
        return (
          <Space>
            <Tooltip title={t('system.seeAttempt')}>
              <Button
                disabled={!getPermission(roleOther, roles.TOPUP_ATTEMPT_INDEX)}
                icon={<EyeOutlined />}
                onClick={() => {
                  navigate(`${ROUTE_PATHS.TOPUP_ATTEMPT_MANAGEMENT}?id=${id}`);
                }}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ]), [t, navigate, roleOther]);

  const tableData: TopUpRequestItemTypes[] = useMemo(() => (
    listData?.data?.map((item) => ({
      ...item.customerPrize,
      lastAttemptData: item.lastAttempts
    })) || []), [listData]);

  const method = useForm<FormFilterTypes>({
    mode: 'onSubmit',
    defaultValues: {
      phone: '',
      prizeCode: ''
    }
  });

  const handleSubmit = (data: FormFilterTypes & { page?: number, limit?: number }) => {
    const {
      phone, prizeCode, page, limit
    } = data;
    const params = {
      ...(phone && { phone }),
      ...(prizeCode && { prizeCode }),
    };
    getTopUpHotlineMutate({ ...params, page: page || currentPage, limit: limit || currentView });
    setSearchParams({ ...params, page: page?.toString() || currentPage.toString() });
  };

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ ...searchParams, page: page.toString() });
    handleSubmit({
      phone: method.getValues('phone'), prizeCode: method.getValues('prizeCode'), page, limit: currentView
    });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
    handleSubmit({
      phone: method.getValues('phone'), prizeCode: method.getValues('prizeCode'), page: currentPage, limit: view
    });
  };

  useEffect(() => {
    setSearchParams({
      ...pageParam && { page: pageParam },
      ...phoneParam && { phone: phoneParam },
      ...prizeCodeParam && { prizeCode: prizeCodeParam },
    });
    if (phoneParam) method.setValue('phone', phoneParam);
    if (prizeCodeParam) method.setValue('prizeCode', prizeCodeParam);
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.topupRequest')}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={listLoading}
          noCheckbox
          tableProps={{
            initShowColumns: ['id', 'telco', 'phone', 'source', 'topupStatus', 'createdAt', 'successAt', 'action'],
            columns,
            pageData: tableData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listData?.meta?.total || 1,
            noBaseCol: true,
            noDeleteLanguage: true,
          }}
          roles={{
            roleCreate,
            roleUpdate,
            roleDelete: true,
          }}
          leftCustomForm={(
            <FormProvider {...method}>
              <Space style={{ minHeight: '52px', alignItems: 'center' }}>
                <Select
                  style={{ width: '100%' }}
                  placeholder="Lọc theo phase"
                  allowClear
                  value={selectedPhase}
                  onChange={(val) => setSelectedPhase(val)}
                >
                  {
                    customerLuckyCodePhases.map((item, index) => (
                      <Select.Option value={item.value} key={`option-${index.toString()}`}>
                        {t(item.label)}
                      </Select.Option>
                    ))
                  }
                </Select>
                <Controller
                  name="phone"
                  control={method.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <Input
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                      size="middle"
                      placeholder="Nhập số điện thoại"
                    />
                  )}
                />
                <Controller
                  name="prizeCode"
                  control={method.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <Input
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                      size="middle"
                      placeholder="nhập mã giải thưởng"
                    />
                  )}
                />
                <Button
                  type="primary"
                  onClick={method.handleSubmit(handleSubmit)}
                >
                  {t('system.filter')}
                </Button>
              </Space>
            </FormProvider>
          )}
        />
      </div>
    </>
  );
};

export default TopUpRequestManagementByPhoneContainer;
