import {
  AppstoreOutlined,
  // BarsOutlined,
  // BorderLeftOutlined,
  DashboardOutlined,
  FileOutlined,
  GoldOutlined,
  PieChartOutlined,
  SettingOutlined,
  ToolOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
  GatewayOutlined
} from '@ant-design/icons';

import roles from './roles';

import { MenuItem } from 'common/components/Sidebar';
import { ROUTE_PATHS } from 'common/utils/constant';

const menuSidebar: MenuItem[] = [
  {
    key: 'dashboard',
    title: 'sidebar.dashboard',
    path: '/',
    icon: <DashboardOutlined />,
  },
  // {
  //   key: 'page_management',
  //   title: 'sidebar.page',
  //   icon: <CodeSandboxOutlined />,
  //   items: [
  //     {
  //       key: 'page_list',
  //       title: 'sidebar.pageList',
  //       path: ROUTE_PATHS.PAGE_MANAGEMENT,
  //       role: roles.PAGE_INDEX,
  //       childrens: [ROUTE_PATHS.PAGE_DETAIL]
  //     },
  //     {
  //       key: 'page_template',
  //       title: 'sidebar.pageTemplate',
  //       path: ROUTE_PATHS.PAGE_TEMPLATE_MANAGEMENT,
  //       role: roles.TEMPLATE_INDEX
  //     },
  //   ],
  // },
  // {
  //   key: 'news',
  //   title: 'sidebar.news',
  //   icon: <ContainerOutlined />,
  //   items: [
  //     {
  //       key: 'news_management',
  //       title: 'sidebar.newsList',
  //       path: ROUTE_PATHS.NEWS_MANAGEMENT,
  //       role: roles.NEWS_INDEX,
  //       childrens: [ROUTE_PATHS.NEWS_DETAIL]
  //     },
  //     {
  //       key: 'news_category',
  //       title: 'sidebar.newsCategory',
  //       path: ROUTE_PATHS.NEWS_CATEGORY_MANAGEMENT,
  //       role: roles.NEWS_CATE_INDEX,
  //       childrens: [ROUTE_PATHS.NEWS_CATEGORY_DETAIL]
  //     },
  //   ],
  // },
  {
    key: 'file',
    title: 'sidebar.file',
    icon: <FileOutlined />,
    path: ROUTE_PATHS.FILE_MANAGEMENT,
    role: roles.FOLDER_VIEWALL
  },
  // {
  //   key: 'menu',
  //   title: 'sidebar.menu',
  //   icon: <MenuOutlined />,
  //   path: ROUTE_PATHS.MENU_MANAGEMENT,
  //   role: roles.MENU_INDEX,
  //   childrens: [ROUTE_PATHS.MENU_DETAIL]
  // },
  // {
  //   key: 'banner',
  //   title: 'sidebar.banner',
  //   icon: <LayoutOutlined />,
  //   path: ROUTE_PATHS.BANNER_MANAGEMENT,
  //   role: roles.BANNER_INDEX,
  //   childrens: [ROUTE_PATHS.BANNER_DETAIL]
  // },
  // {
  //   key: 'static_block',
  //   title: 'sidebar.staticBlocks',
  //   icon: <ProjectOutlined />,
  //   path: ROUTE_PATHS.STATIC_BLOCK_MANAGEMENT,
  //   role: roles.STATIC_BLOCK_INDEX,
  //   childrens: [ROUTE_PATHS.STATIC_BLOCK_DETAIL]
  // },
  // {
  //   key: 'faq',
  //   title: 'sidebar.faqs',
  //   icon: <ContainerOutlined />,
  //   items: [
  //     {
  //       key: 'faq_management',
  //       title: 'sidebar.faqsList',
  //       path: ROUTE_PATHS.FAQ_MANAGEMENT,
  //       role: roles.FAQ_INDEX,
  //       childrens: [ROUTE_PATHS.FAQ_DETAIL]
  //     },
  //     {
  //       key: 'faq_category_management',
  //       title: 'sidebar.faqCategory',
  //       path: ROUTE_PATHS.FAQ_CATEGORY_MANAGEMENT,
  //       role: roles.FAQ_CATEGORY_INDEX,
  //       childrens: [ROUTE_PATHS.FAQ_CATEGORY_DETAIL]
  //     },
  //   ],
  // },
  // {
  //   key: 'contact',
  //   title: 'sidebar.contact',
  //   icon: <ContactsOutlined />,
  //   items: [
  //     {
  //       key: 'contact_management',
  //       title: 'sidebar.contactList',
  //       path: ROUTE_PATHS.CONTACT_MANAGEMENT,
  //       role: roles.CONTACT_INDEX
  //     },
  //     {
  //       key: 'contact_problem',
  //       title: 'sidebar.contactProblem',
  //       path: ROUTE_PATHS.CONTACT_PROBLEM_MANAGEMENT,
  //       role: roles.CONTACT_PROBLEM_INDEX,
  //       childrens: [ROUTE_PATHS.CONTACT_PROBLEM_DETAIL]
  //     },
  //   ],
  // },
  // {
  //   key: 'email_template',
  //   title: 'sidebar.emailTemplate',
  //   icon: <MailOutlined />,
  //   path: ROUTE_PATHS.EMAIL_TEMPLATE_MANAGEMENT,
  //   role: roles.EMAIL_TEMPLATE_INDEX,
  //   childrens: [ROUTE_PATHS.EMAIL_TEMPLATE_DETAIL]
  // },
  // {
  //   key: 'moduleForm',
  //   title: 'sidebar.formsModule',
  //   icon: <FormOutlined />,
  //   items: [
  //     {
  //       key: 'forms',
  //       title: 'sidebar.forms',
  //       path: ROUTE_PATHS.FORM_MANAGEMENT,
  //       role: roles.FORM_INDEX,
  //       childrens: [ROUTE_PATHS.FORM_DETAIL],
  //     },
  //     {
  //       key: 'submit_form',
  //       title: 'sidebar.typeFormSubmit',
  //       path: ROUTE_PATHS.SUBMIT_FORM_MANAGEMENT,
  //       role: roles.SUBMIT_FROM,
  //       childrens: [ROUTE_PATHS.SUBMIT_FORM_LIST_BY_FORM]
  //     },
  //   ]
  // },
  {
    key: 'smsgateway',
    title: 'sidebar.smsGateway',
    icon: <GatewayOutlined />,
    path: ROUTE_PATHS.SMSGATEWAY,
    role: roles.SMSGATEWAY_INDEX,
  },
  {
    key: 'redeemRewardMenu',
    title: 'sidebar.redeemRewardMenu',
    icon: <UnorderedListOutlined />,
    items: [
      // {
      //   key: 'prizeExchangeMenu',
      //   title: 'sidebar.prizeExchange',
      //   icon: <CompassOutlined />,
      //   path: ROUTE_PATHS.PRIZE_EXCHANGE_MANAGEMENT,
      //   role: roles.PRIZE_EXCHANGE_INDEX,
      //   specialRole: roles.PRIZE_EXCHANGE_INDEX_BY_PHONE
      // },
      {
        key: 'topupRequest',
        title: 'sidebar.topupRequest',
        path: ROUTE_PATHS.TOPUP_REQUEST_MANAGEMENT,
        role: roles.TOPUP_ATTEMPT_INDEX,
        specialRole: roles.TOPUP_ATTEMPT_BY_PHONE
      },
      {
        key: 'topupTransaction',
        title: 'sidebar.topupTransaction',
        path: ROUTE_PATHS.TOPUP_TRANSACTION_MANAGEMENT,
        role: roles.TOPUP_ATTEMPT_LIST,
      },
      {
        key: 'customerFirstPrize',
        title: 'sidebar.customerFirstPrize',
        path: ROUTE_PATHS.CUSTOMER_FIRST_PRIZE_MANAGEMENT,
        role: roles.CUSTOMER_PRIZE_GET_FIRST_PRIZE,
      },
      {
        key: 'customerSpecialPrize',
        title: 'sidebar.customerSpecialPrize',
        path: ROUTE_PATHS.CUSTOMER_SPECIAL_PRIZE_MANAGEMENT,
        role: roles.CUSTOMER_PRIZE_GET_SPECIAL_PRIZE,
      },
      {
        key: 'topupBuyCards',
        title: 'sidebar.topupBuyCards',
        path: ROUTE_PATHS.TOPUP_BUY_CARDS_MANAGEMENT,
        role: roles.TOPUP_BUY_CARDS_INDEX,
      },
    ]
  },
  // {
  //   key: 'topupQuotasMenu',
  //   title: 'sidebar.topupQuotasMenu',
  //   icon: <BorderLeftOutlined />,
  //   items: [
  //     {
  //       key: 'topupQuotasManagement',
  //       title: 'sidebar.topupQuotasManagement',
  //       icon: <BarsOutlined />,
  //       path: ROUTE_PATHS.QUOTAS_MANAGEMENT,
  //       role: roles.TOPUP_QUOTAS_INDEX,
  //     },
  //     {
  //       key: 'topupQuotasHistoryManagement',
  //       title: 'sidebar.topupQuotasHistoryManagement',
  //       icon: <BarsOutlined />,
  //       path: ROUTE_PATHS.QUOTAS_HISTORY_MANAGEMENT,
  //       role: roles.TOPUP_QUOTAS_HISTORY,
  //     },
  //   ],
  // },
  {
    key: 'config',
    title: 'sidebar.config',
    icon: <PieChartOutlined />,
    items: [
      {
        key: 'topup',
        title: 'sidebar.configTopup',
        path: ROUTE_PATHS.CONFIG_TOPUP,
        role: roles.CONFIG_TOPUP_INDEX
      },
      {
        key: 'first_prize',
        title: 'sidebar.configFirstPrize',
        path: ROUTE_PATHS.CONFIG_FIRST_PRIZE,
        role: roles.CONFIG_FIRST_PRIZE_INDEX
      },
      {
        key: 'special_prize',
        title: 'sidebar.configSpecialPrize',
        path: ROUTE_PATHS.CONFIG_SPECIAL_PRIZE,
        role: roles.CONFIG_SPECIAL_PRIZE_INDEX
      },
    ]
  },
  {
    key: 'customer_lucky_draw',
    title: 'sidebar.customerLuckyDraw',
    icon: <GoldOutlined />,
    path: ROUTE_PATHS.CUSTOMER_LUCKY_DRAW_MANAGEMENT,
    role: roles.CUSTOMER_LUCKY_DRAW_INDEX,
  },
  {
    key: 'store',
    title: 'sidebar.store',
    icon: <AppstoreOutlined />,
    path: ROUTE_PATHS.STORE_MANAGEMENT,
    role: roles.STORE_INDEX,
    childrens: [ROUTE_PATHS.STORE_DETAIL]
  },
  {
    key: 'customer_lucky_code',
    title: 'sidebar.customerLuckyCode',
    icon: <UsergroupAddOutlined />,
    path: ROUTE_PATHS.CUSTOMER_LUCKY_CODE_MANAGEMENT,
    role: roles.CUSTOMER_LUCKY_CODE_INDEX,
  },
  {
    key: 'tools',
    title: 'sidebar.tools',
    icon: <ToolOutlined />,
    items: [
      {
        key: 'fe_translation',
        title: 'sidebar.feTranslations',
        path: ROUTE_PATHS.FE_TRANSLATION_MANAGEMENT,
        role: roles.FE_TRANSLATION_INDEX
      },
      {
        key: 'cms_translation',
        title: 'sidebar.cmsTranslations',
        path: ROUTE_PATHS.CMS_TRANSLATION_MANAGEMENT,
        role: roles.CMS_TRANSLATION_INDEX
      },
      {
        key: 'redirect',
        title: 'sidebar.redirect',
        path: ROUTE_PATHS.REDIRECT_MANAGEMENT,
        role: roles.REDIRECT_INDEX
      },
    ]
  },
  // {
  //   key: 'appearance',
  //   title: 'sidebar.appearance',
  //   icon: <BgColorsOutlined />,
  //   items: [
  //     {
  //       key: 'config-management',
  //       title: 'sidebar.headerFooter',
  //       path: ROUTE_PATHS.SYSTEM_CONFIG_MANAGEMENT,
  //       role: roles.CONFIG_HEADER_INDEX || roles.CONFIG_FOOTER_INDEX,
  //     },
  //     {
  //       key: 'errors_management',
  //       title: 'sidebar.errorsManagement',
  //       path: ROUTE_PATHS.ERRORS_MANAGEMENT,
  //     }
  //   ]
  // },
  {
    key: 'system',
    title: 'sidebar.system',
    icon: <SettingOutlined />,
    items: [
      {
        key: 'system_management',
        title: 'sidebar.general',
        path: ROUTE_PATHS.SYSTEM_MANAGEMENT,
        role: roles.SYSTEM_INDEX
      },
      {
        key: 'user_management',
        title: 'sidebar.users',
        path: ROUTE_PATHS.USERS_MANAGEMENT,
        role: '*',
      },
      {
        key: 'roles',
        title: 'sidebar.roles',
        path: ROUTE_PATHS.ROLES_MANAGEMENT,
        role: '*',
      },
      {
        key: 'activity_logs',
        title: 'sidebar.activityLogs',
        path: ROUTE_PATHS.ACTIVITY_MANAGEMENT,
        role: roles.ACTIVITYLOG_INDEX,
      },
    ],
  },
];

export default menuSidebar;
