/* eslint-disable import/no-cycle */
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Card, Col,
  DatePicker,
  Row,
  Space,
  Typography
} from 'antd';
import dayjs from 'dayjs';
import React, { useCallback } from 'react';
import {
  Controller, UseFormReturn, useFieldArray, useWatch
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TopupConfigForm } from '.';

import { DropdownElement } from 'common/components/DropdownType';
import Input from 'common/components/Input';
import { amountTopupTypes, topupPhaseTypes } from 'common/utils/constant';

type Props = {
  editable?: boolean;
  method: UseFormReturn<TopupConfigForm>;
};

const PhasesFormArray: React.FC<Props> = ({ editable, method }) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({ control: method.control, name: 'phases' });

  const selectedPhase = useWatch({ name: 'phases', control: method.control }).filter((phase) => !!phase.phase).map((phase) => phase.phase);

  const getOptionsPhase = useCallback((index: number) => {
    const value = method.getValues(`phases.${index}.phase`);
    return !selectedPhase.includes(value)
      ? topupPhaseTypes.filter((
        phase
      ) => !selectedPhase.includes(phase.value as number))
      : topupPhaseTypes;
  }, [method, selectedPhase]);

  return (
    <div>
      {fields.map((field, index) => (
        <Card
          key={`topupConfig-phases-${index.toString()}`}
          extra={fields.length > 1 && editable && (
            <div onClick={() => remove(index)}>
              <MinusCircleOutlined style={{ fontSize: '20px', color: 'red', cursor: 'pointer' }} />
            </div>
          )}
          style={{ marginBottom: '20px' }}
        >
          <Row gutter={16}>
            <Col span={8}>
              <div className="p-editPageTemplate_input">
                <Typography.Text strong>
                  {t('topupConfig.phase')}
                  {' '}
                  <Typography.Text strong type="danger">
                    *
                  </Typography.Text>
                </Typography.Text>
                <Controller
                  name={`phases.${index}.phase`}
                  control={method.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <DropdownElement
                      options={getOptionsPhase(index)}
                      size="large"
                      placeholder={`${t('system.select')} ${t('topupConfig.phase')}`}
                      locale="vi"
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      error={error?.message}
                      readOnly={!editable}
                    />
                  )}
                />
              </div>
            </Col>
            <Col span={8}>
              <div className="p-editPageTemplate_input">
                <Typography.Text strong>
                  {t('topupConfig.prizesPerPhone')}
                  {' '}
                  <Typography.Text strong type="danger">
                    *
                  </Typography.Text>
                </Typography.Text>
                <Controller
                  name={`phases.${index}.prizesPerPhone`}
                  control={method.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <Input
                      value={value}
                      type="number"
                      className="u-mt-8"
                      onChange={onChange}
                      size="large"
                      error={error?.message}
                      readOnly={!editable}
                    />
                  )}
                />
              </div>
            </Col>
            <Col span={8}>
              <div className="p-editPageTemplate_input">
                <Typography.Text strong>
                  {t('topupConfig.amount')}
                  {' '}
                  <Typography.Text strong type="danger">
                    *
                  </Typography.Text>
                </Typography.Text>
                <Controller
                  name={`phases.${index}.amount`}
                  control={method.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <DropdownElement
                      options={amountTopupTypes}
                      size="large"
                      placeholder={`${t('system.select')} ${t('topupConfig.amount')}`}
                      locale="vi"
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      error={error?.message}
                      readOnly={!editable}
                    />
                  )}
                />
              </div>
            </Col>
            <Col span={8}>
              <div className="p-editPageTemplate_input u-mt-16">
                <Typography.Text strong>
                  {t('topupConfig.from')}
                  {' '}
                  <Typography.Text strong type="danger">
                    *
                  </Typography.Text>
                </Typography.Text>
                <Controller
                  name={`phases.${index}.from`}
                  control={method.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <>
                      <DatePicker
                        size="large"
                        value={value ? dayjs(value) : null}
                        showTime
                        onChange={(quotaDate) => onChange(quotaDate?.toISOString() || '')}
                        format="YYYY-MM-DD HH:mm:ss"
                        style={{ width: '100%' }}
                        allowClear
                        disabled={!editable}
                        className={`u-mt-8${!editable ? ' readonly' : ''}`}
                      />
                      {
                        error && (
                          <span className="a-input_errorMessage">
                            {error.message}
                          </span>
                        )
                      }
                    </>
                  )}
                />
              </div>
            </Col>
            <Col span={8}>
              <div className="p-editPageTemplate_input u-mt-16">
                <Typography.Text strong>
                  {t('topupConfig.to')}
                  {' '}
                  <Typography.Text strong type="danger">
                    *
                  </Typography.Text>
                </Typography.Text>
                <Controller
                  name={`phases.${index}.to`}
                  control={method.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <>
                      <DatePicker
                        size="large"
                        showTime
                        value={value ? dayjs(value) : null}
                        onChange={(quotaDate) => onChange(quotaDate?.toISOString() || '')}
                        format="YYYY-MM-DD HH:mm:ss"
                        style={{ width: '100%' }}
                        allowClear
                        disabled={!editable}
                        className={`u-mt-8${!editable ? ' readonly' : ''}`}
                      />
                      {
                        error && (
                          <span className="a-input_errorMessage">
                            {error.message}
                          </span>
                        )
                      }
                    </>
                  )}
                />
              </div>
            </Col>
            <Col span={8}>
              <div className="p-editPageTemplate_input u-mt-16">
                <Typography.Text strong>
                  {t('topupConfig.quantity')}
                  {' '}
                  <Typography.Text strong type="danger">
                    *
                  </Typography.Text>
                </Typography.Text>
                <Controller
                  name={`phases.${index}.quantity`}
                  control={method.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <Input
                      value={value}
                      type="number"
                      className="u-mt-8"
                      onChange={(fieldValue) => onChange(Number(fieldValue.target.value))}
                      size="large"
                      error={error?.message}
                      readOnly={!editable}
                    />
                  )}
                />
              </div>
            </Col>
          </Row>
        </Card>
      ))}
      {editable && selectedPhase.length < 6 && (
        <Space style={{ width: '100%' }} direction="vertical" align="center">
          <Button
            type="primary"
            onClick={() => append({})}
          >
            <PlusCircleOutlined />
            {t('topupConfig.addPhase')}
          </Button>
        </Space>
      )}
    </div>
  );
};

export default PhasesFormArray;
