import { PlusOutlined, SaveOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Input,
  InputRef,
  Space,
  Tag,
  Tooltip,
  Typography,
  message,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import { editBlacklistSpecialPrizeConfig, getBlacklistSpecialPrizeConfig } from 'common/services/systems';
import mapModifiers, { formatPhoneNumber } from 'common/utils/functions';

type TagType = { tag: string, id: number };

const BlackListSpecialPrizes: React.FC<{ canEdit: boolean }> = ({ canEdit }) => {
  /* Hooks */
  const { t } = useTranslation();
  const [tags, setTags] = useState<Array<TagType>>([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [editInputId, setEditInputId] = useState(-1);
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');
  const [inValidInputEditValue, setInvalidInputEditValue] = useState<Array<number>>([]);
  const inputRef = useRef<InputRef>(null);
  const editInputRef = useRef<InputRef>(null);

  /* Queries */
  useQuery(
    ['getBlacklistSpecialPrizeConfig'],
    () => getBlacklistSpecialPrizeConfig(),
    {
      onSuccess(data) {
        if (data.length > 0) {
          setTags(data.map((item, index) => ({
            tag: formatPhoneNumber(item, true),
            id: index + 1
          })));
        }
      },
    }
  );

  const { mutate: editBlacklistSpecialConfigMutate, isLoading: isEditLoading } = useMutation(
    ['editBlacklistSpecialConfigMutate'],
    async (params: string[]) => editBlacklistSpecialPrizeConfig(params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
      },
      onError: () => {
        message.error(t('message.updateError'));
      }
    }
  );

  // /* Functions */
  const handleSubmit = () => {
    editBlacklistSpecialConfigMutate(tags.map((item) => item.tag));
  };

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [editInputValue]);

  const getOtherIdFromTags = (id: number): number => {
    const existId = tags.findIndex((item) => item.id === id);
    if (existId === -1) {
      return id;
    }
    return getOtherIdFromTags(id + 1);
  };

  const handleClose = (removedTag: TagType) => {
    setInvalidInputEditValue(inValidInputEditValue.filter((item) => item !== removedTag.id));
    const newTags = tags.filter((tag) => tag.id !== removedTag.id);
    setTags(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
    setEditInputId(getOtherIdFromTags(1));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditInputValue(e.target.value);
    if (!e.target.value.match(/^(?:84|0)[1-9]\d{8}$/)) {
      if (!inValidInputEditValue.includes(editInputId)) {
        setInvalidInputEditValue([...inValidInputEditValue, editInputId]);
      }
    } else {
      setInvalidInputEditValue(inValidInputEditValue.filter((item) => item !== editInputId));
    }
  };

  const handleInputConfirm = () => {
    if (editInputValue === '') {
      setInvalidInputEditValue(inValidInputEditValue.filter((item) => item !== editInputId));
    } else if (!editInputValue.match(/^(?:84|0)[1-9]\d{8}$/)) {
      if (!inValidInputEditValue.includes(editInputId)) {
        setInvalidInputEditValue([...inValidInputEditValue, editInputId]);
      }
    }
    if (editInputValue && tags.findIndex((item) => item.tag === editInputValue) === -1) {
      setTags([...tags, { tag: editInputValue, id: getOtherIdFromTags(1) }]);
    }
    setInputVisible(false);
    setEditInputValue('');
    setEditInputId(-1);
    setEditInputIndex(-1);
  };

  const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditInputValue(e.target.value);
    if (!e.target.value.match(/^(?:84|0)[1-9]\d{8}$/)) {
      if (!inValidInputEditValue.includes(editInputId)) {
        setInvalidInputEditValue([...inValidInputEditValue, editInputId]);
      }
    } else {
      setInvalidInputEditValue(inValidInputEditValue.filter((item) => item !== editInputId));
    }
  };

  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex].tag = editInputValue;
    if (editInputValue === '') {
      setInvalidInputEditValue(inValidInputEditValue.filter((item) => item !== editInputId));
    } else if (!editInputValue.match(/^(?:84|0)[1-9]\d{8}$/)) {
      if (!inValidInputEditValue.includes(editInputId)) {
        setInvalidInputEditValue([...inValidInputEditValue, editInputId]);
      }
    }
    setTags(newTags);
    setEditInputId(-1);
    setEditInputIndex(-1);
    setEditInputValue('');
  };

  return (
    <div className="p-system_blacklist">
      <Card
        type="inner"
        title={(
          <div className="p-system_blacklist_heading">
            <Typography.Title
              level={5}
            >
              {t('systemManagement.blacklistSpecialPrize')}
            </Typography.Title>
            <Button
              type="primary"
              disabled={!canEdit || inValidInputEditValue.length > 0}
              loading={isEditLoading}
              onClick={handleSubmit}
            >
              <SaveOutlined />
              {t('system.save')}
            </Button>
          </div>
        )}
      >
        <Space size={12} style={{ width: '100%', flexWrap: 'wrap' }}>
          {tags.map((tag, index) => {
            if (editInputIndex === index) {
              return (
                <Tooltip
                  title={t('systemManagement.invalidFormatPhone')}
                  color="red"
                  open={inValidInputEditValue.includes(tag.id)}
                >
                  <Input
                    ref={editInputRef}
                    key={tag.tag}
                    type="number"
                    size="small"
                    className={mapModifiers('p-system_blacklist_input', inValidInputEditValue.includes(tag.id) ? 'error' : '')}
                    value={editInputValue}
                    onChange={handleEditInputChange}
                    onBlur={handleEditInputConfirm}
                    onPressEnter={handleEditInputConfirm}
                  />
                </Tooltip>
              );
            }
            return (
              <Tag
                className={mapModifiers('p-system_blacklist_tag', inValidInputEditValue.includes(tag.id) ? 'error' : '')}
                key={tag.tag}
                closable
                onClose={() => handleClose(tag)}
              >

                <Tooltip
                  title={inValidInputEditValue.includes(tag.id) ? t('systemManagement.invalidFormatPhone') : ''}
                  color="red"
                >
                  <span
                    onDoubleClick={(e) => {
                      setEditInputId(tag.id);
                      setEditInputIndex(index);
                      setEditInputValue(tag.tag);
                      e.preventDefault();
                    }}
                  >
                    {tag.tag}
                  </span>
                </Tooltip>
              </Tag>
            );
          })}
          {inputVisible && (
            <Tooltip
              title={t('systemManagement.invalidFormatPhone')}
              color="red"
              open={inValidInputEditValue.includes(editInputId)}
            >
              <Input
                ref={inputRef}
                type="number"
                size="small"
                className={mapModifiers('p-system_blacklist_input', inValidInputEditValue.includes(editInputId) ? 'error' : '')}
                value={editInputValue}
                onChange={handleInputChange}
                onBlur={handleInputConfirm}
                onPressEnter={handleInputConfirm}
              />
            </Tooltip>
          )}
          {!inputVisible && (
            <Tag className="site-tag-plus" onClick={showInput}>
              <PlusOutlined />
              {' '}
              {t('system.addNew')}
            </Tag>
          )}
        </Space>
      </Card>
    </div>
  );
};

export default BlackListSpecialPrizes;
