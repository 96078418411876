// import { UserOutlined } from '@ant-design/icons';
import {
  Space, Typography
} from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { useAppSelector } from 'app/store';
import { getTopupSummariesService } from 'common/services/dashboard';
import { formatNumberWithSeparators } from 'common/utils/functions';
import roles, { getPermission } from 'configs/roles';
import { PhaseContext } from 'features/Home';

const ReportTopUpSummaries: React.FC = () => {
  const { t } = useTranslation();
  const phase = useContext(PhaseContext);
  const rolesUser = useAppSelector((state) => state.auth.roles);

  const { data } = useQuery(
    ['topup-summaries', phase.phaseExecute],
    () => getTopupSummariesService({
      phase: phase.phaseExecute >= 0 ? Number(phase.phaseExecute) : undefined
    }),
    {
      enabled: getPermission(rolesUser, roles.DASHBOARD_TOPUP_SUMMARY)
        && phase.phaseExecute !== -1
    }
  );
  return (
    <div className="o-reportTopUpSummaries">
      <div className="o-reportTopUpSummaries_title">
        <Space>
          <Typography.Title level={4} className="fs-16x24 color-brand-color2 o-reportTopUpSummaries_title">{t('dashboard.reportTopUpSummariesTitle')}</Typography.Title>
        </Space>
      </div>
      <div className="o-reportTopUpSummaries_content u-mt-8">
        <div className="o-reportTopUpSummaries_content_item">
          <Space direction="vertical" style={{ width: '100%' }}>
            <Space direction="horizontal">
              <Typography.Text className="o-reportTopUpSummaries_content_item-heading" type="secondary">
                {t('dashboard.topupBuyCardSuccess')}
              </Typography.Text>
            </Space>
            <Typography.Text className="o-reportTopUpSummaries_content_item-value">{formatNumberWithSeparators(data?.totalBuyCardSuccess || 0)}</Typography.Text>
          </Space>
        </div>
        <div className="o-reportTopUpSummaries_content_item">
          <Space direction="vertical" style={{ width: '100%' }}>
            <Typography.Text className="o-reportTopUpSummaries_content_item-heading" type="secondary">
              {t('dashboard.topupAttemptSuccess')}
            </Typography.Text>
            <Typography.Text className="o-reportTopUpSummaries_content_item-value">{Math.round(parseFloat((data?.totalAttemptSuccess || 0).toFixed(2)))}</Typography.Text>
          </Space>
        </div>
      </div>
    </div>
  );
};

export default ReportTopUpSummaries;
