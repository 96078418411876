import {
  Card,
  Empty, Space, Spin, Typography
} from 'antd';
import React from 'react';

import ChartCustom, { ShapeType } from '../Chart';

export type ReportRewardProps = {
  heading?: React.ReactNode;
  title: string;
  options?: any;
  data?: any;
  idLegendTooltip?: string;
  width?: number;
  height?: number | string;
  isLoading?: boolean;
  type?: ShapeType;
  percentage?: boolean;
  hasDatalabels?: boolean;
};

const ReportReward: React.FC<ReportRewardProps> = ({
  heading,
  title,
  data,
  options,
  idLegendTooltip,
  width,
  height,
  type = 'doughnut',
  isLoading = false,
  percentage,
  hasDatalabels,
}) => (
  <Space direction="vertical" size={12} className="o-reportReward">
    {
      heading && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {heading}
        </div>
      )
    }
    <Card type="inner">
      <Spin size="large" spinning={isLoading}>
        <div>
          {data
            ? (
              <ChartCustom
                type={type || 'doughnut'}
                data={data}
                options={options}
                height={height}
                width={width}
                idLegendTooltip={idLegendTooltip}
                percentage={percentage}
                hasDatalabels={hasDatalabels}
              />
            ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </div>
        {
          title && (
            <Typography.Title level={4} className="o-reportReward_title">{title}</Typography.Title>
          )
        }
      </Spin>
    </Card>
  </Space>
);

export default ReportReward;
