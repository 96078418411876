import { EyeOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Col, Modal, Row, Select, Space, Typography, message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import luckyDrawRoundDummy from 'common/assets/dummyData/customerLuckyDraw';
import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import PageTable from 'common/components/PageTable';
import {
  FilterValueProps,
  mappingFilterFields,
  mappingParamsFilter,
  mappingQueryParamsFilter,
  FilterDataProps,
  mappingFilterToQuery
} from 'common/components/PageTable/filter';
import useDidMount from 'common/hooks/useDidMount';
import { getCustomerLuckyDrawService, postFirstPrizeService } from 'common/services/customerLuckyDraw';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateTime, formatPhoneVietnamese } from 'common/utils/functions';
import { setupLuckyDrawPrizeSchema } from 'common/utils/schemas';
import roles, { getPermission } from 'configs/roles';

type FormTypes = {
  round: number;
  quantity: number
};

export interface CustomerLuckyDrawData {
  id: number
  fullName: string
  phone: string
  zaloPhone: string
  luckyCode: string
  prize: string
  province: string
  district: string
  createdAt: string
}

const CustomerLuckyDraw: React.FC<ActiveRoles> = ({
  roleOther
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigation = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);

  const pageParam = searchParams.get('page');

  /* Selectors */
  const {
    defaultPageSize, advancedFilter
  } = useAppSelector((state) => state.system);

  /* State */
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));

  /* Variables */
  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('customerDraftPrize', advancedFilter),
    [advancedFilter]
  );

  /* Form */
  const method = useForm<FormTypes>({
    mode: 'onChange',
    defaultValues: {
      round: 1,
      quantity: 1
    },
    resolver: yupResolver(setupLuckyDrawPrizeSchema)
  });

  /* React query */
  const { mutate: postFirstPrize, isLoading: isLoadingFirstPrize } = useMutation(
    ['setup-lucky-draw-first-prize'],
    ({ data }: { data: FormTypes }) => postFirstPrizeService(data),
    {
      onSuccess() {
        message.success(t('customerLuckyDraw.setupFirstPrizeSuccess'));
        setIsOpen(false);
      },
      onError(err) {
        console.log(err);
      }
    }
  );

  const queryKey = ['getCustomerFirstPrize', keyword, currentPage, currentView, selectedFilterList];
  const { data: customerLuckyDrawData, isLoading } = useQuery(
    queryKey,
    () => getCustomerLuckyDrawService({
      keyword,
      page: currentPage,
      limit: currentView,
      ...returnParamsFilter,
    }),
    {
      enabled: !!currentPage,
    }
  );

  const onSubmit = async () => {
    const isValid = await method.trigger();
    if (!isValid) return;
    const data = method.getValues();
    postFirstPrize({ data });
  };

  const tableData: CustomerLuckyDrawData[] = useMemo(() => (
    customerLuckyDrawData?.data.map((val) => ({
      id: val.customer?.id,
      fullName: val.customer?.fullName || '',
      phone: val.customer?.phone || '',
      zaloPhone: val.customer?.zaloPhone || '',
      luckyCode: val.customerDraftPrize?.luckyCode || '',
      prize: val.customerDraftPrize?.prize || '',
      province: val.customerDraftPrize?.province?.name || '',
      district: val.customerDraftPrize?.district?.name || '',
      createdAt: val.createdAt || '',
    })) || []), [customerLuckyDrawData]);

  const columns: ColumnsType<CustomerLuckyDrawData> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      render: (_name: string, data: CustomerLuckyDrawData) => (
        <Typography.Text>
          {data.id || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('customerPrize.fullName'),
      key: 'fullName',
      render: (_name: string, data: CustomerLuckyDrawData) => (
        <Typography.Text>
          {data.fullName || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('customerPrize.phone'),
      key: 'phone',
      render: (_name: string, data: CustomerLuckyDrawData) => (
        <Typography.Text>
          {formatPhoneVietnamese((data.phone || '').toString())}
        </Typography.Text>
      ),
    },
    {
      title: t('customerPrize.zaloPhone'),
      key: 'zaloPhone',
      render: (_name: string, data: CustomerLuckyDrawData) => (
        <Typography.Text>
          {data.zaloPhone || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('customerPrize.luckyCode'),
      key: 'luckyCode',
      render: (_name: string, data: CustomerLuckyDrawData) => (
        <Typography.Text>
          {data.luckyCode || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('customerLuckyDraw.prize'),
      key: 'prize',
      render: (_name: string, data: CustomerLuckyDrawData) => (
        <Typography.Text>
          {data.prize || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('location.province'),
      key: 'province',
      render: (_name: string, data: CustomerLuckyDrawData) => (
        <Typography.Text>
          {data.province || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('location.district'),
      key: 'district',
      render: (_name: string, data: CustomerLuckyDrawData) => (
        <Typography.Text>
          {data.district || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_name: string, data: CustomerLuckyDrawData) => (
        <Typography.Text>
          {formatDateTime(data.createdAt || '')}
        </Typography.Text>
      ),
    },
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, data: CustomerLuckyDrawData) => (
        <Space>
          <Button
            icon={<EyeOutlined />}
            onClick={() => navigation(`${ROUTE_PATHS.CUSTOMER_LUCKY_DRAW_DETAIL}?id=${data.id}`)}
          />
        </Space>
      ),
    },
  ];

  // Change page view
  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() }, { replace: true });
  };

  // Change page size
  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
  };

  // Add filter
  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  /* Effects */
  useEffect(() => {
    setCurrentPage(1);
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      page: '1'
    }, { replace: true });
  }, [keyword, setSearchParams, selectedFilterList]);

  useDidMount(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);
  });

  useEffect(() => {
    method.reset();
  }, [isOpen, method]);

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.customerLuckyDraw')}
        rightHeader={(
          <Button
            type="primary"
            disabled={!getPermission(roleOther, roles.CUSTOMER_LUCKY_DRAW_FIRST_PRIZE)}
            onClick={() => setIsOpen(true)}
          >
            {t('customerLuckyDraw.setupFirstPrizeQuantity')}
          </Button>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={isLoading}
          handleSearch={setKeyword}
          tableProps={{
            initShowColumns: ['id', 'fullName', 'phone', 'zaloPhone', 'luckyCode', 'prize', 'province', 'district', 'createdAt', 'action'],
            columns,
            pageData: tableData || [],
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: customerLuckyDrawData?.meta.total ?? 1,
            noBaseCol: true,
            noDeleteLanguage: true,
            filterFields,
          }}
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter,
          }}
          noCheckbox
        />
      </div>
      <Modal
        className="modal-detail"
        title={<Typography.Title level={3}>{t('customerLuckyDraw.setupFirstPrizeQuantity')}</Typography.Title>}
        open={isOpen}
        centered
        confirmLoading={isLoadingFirstPrize}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={onSubmit}
        onCancel={() => setIsOpen(false)}
      >
        <FormProvider {...method}>
          <Row gutter={16}>
            <Col span={24}>
              <Typography.Text strong>
                {t('customerLuckyDraw.round')}
              </Typography.Text>
              {' '}
              <Typography.Text strong type="danger">
                *
              </Typography.Text>
              <Controller
                name="round"
                render={({ field: { value, onChange } }) => (
                  <Select
                    size="large"
                    className="u-mt-8"
                    style={{ width: '100%' }}
                    placeholder="---"
                    value={value}
                    onChange={onChange}
                  >
                    {
                      luckyDrawRoundDummy.map((val, idx) => (
                        <Select.Option value={val.value} key={`option-${idx.toString()}`}>
                          {t(val.label)}
                        </Select.Option>
                      ))
                    }
                  </Select>
                )}
              />
            </Col>
            <Col span={24} className="u-mt-8">
              <Typography.Text strong>
                {t('customerLuckyDraw.quantity')}
              </Typography.Text>
              {' '}
              <Typography.Text strong type="danger">
                *
              </Typography.Text>
              <Controller
                name="quantity"
                render={({ field, fieldState: { error } }) => (
                  <Input
                    className="u-mt-8"
                    type="number"
                    error={error?.message}
                    {...field}
                  />
                )}
              />
            </Col>
          </Row>
        </FormProvider>
      </Modal>
    </>
  );
};

export default CustomerLuckyDraw;
