import axiosInstance from '../common/instance';

import {
  CustomerFirstPrizeData,
  CustomerLuckyCodeData,
  CustomerLuckyCodeParams,
  CustomerLuckyCodesDetailDataTypes,
  CustomerSpecialPrizeData,
  ExportDataTypes,
  DeleteParamsTypes,
  UpdatePrizeInformationById,
  ResponseUploadType
} from './types';

export const getCustomerLuckyCodeService = async (
  params?: CustomerLuckyCodeParams
): Promise<APIPaginationResponse<CustomerLuckyCodeData[]>> => {
  const res = await axiosInstance.get('customer-lucky-codes', { params });
  return res.data;
};

export const getCustomerLuckyCodeDetailService = async (
  code: string
): Promise<CustomerLuckyCodesDetailDataTypes> => {
  const res = await axiosInstance.get(`customer-lucky-codes/${code}`);
  return res.data.data;
};

export const getCustomerFirstPrizeService = async (
  params?: BaseFilterParams
): Promise<APIPaginationResponse<CustomerFirstPrizeData[]>> => {
  const res = await axiosInstance.get('customer-prizes/first-prizes', { params });
  return res.data;
};

export const getCustomerSpecialPrizeService = async (
  params?: BaseFilterParams
): Promise<APIPaginationResponse<CustomerSpecialPrizeData[]>> => {
  const res = await axiosInstance.get('customer-prizes/special-prizes', { params });
  return res.data;
};

export const updateCustomerPrizeService = async (
  params: UpdatePrizeInformationById
): Promise<void> => {
  const { id, ...data } = params;
  await axiosInstance.put(`customer-prizes/${id}`, data);
};

export const getCustomerPrizeDetailService = async (id: string)
  : Promise<CustomerFirstPrizeData> => {
  const res = await axiosInstance.get(`customer-prizes/${id}`);
  return res.data.data;
};

export const exportCustomerFirstPrizesService = async (params?: BaseFilterParams)
  : Promise<ExportDataTypes> => {
  const res = await axiosInstance.get('customer-prizes/export-first-prizes', { params });
  return res.data.data;
};

export const exportCustomerSpecialPrizesService = async (params?: BaseFilterParams)
  : Promise<ExportDataTypes> => {
  const res = await axiosInstance.get('customer-prizes/export-special-prizes', { params });
  return res.data.data;
};

export const deleteCustomerPrizesService = async (params?: DeleteParamsTypes)
  : Promise<void> => {
  await axiosInstance.delete('customer-prizes/remove-prizes', { params });
};

export const uploadCustomerFileService = async (file?: File)
  : Promise<ResponseUploadType> => {
  const bodyForm = new FormData();
  if (file) {
    bodyForm.append('qqfile', file);
  }
  const res = await axiosInstance.post('customer-prizes/upload-file', bodyForm);
  return res.data;
};

export const exportCustomerService = async (params?: BaseFilterParams)
  : Promise<ExportDataTypes> => {
  const res = await axiosInstance.post('customer-lucky-codes/exports', null, { params });
  return res.data.data;
};
