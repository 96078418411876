import {
  ExportDataTypes,
  NeverTopUpTypes,
  TopUpAttemptByIdTypes,
  TopUpRequestTypes, TopupAttemptItemTypes,
  TopupParamsTypes, TopupRequestHotlineParamsTypes
} from './types';

import axiosInstance from 'common/services/common/instance';

export const getTopupRequestList = async (params?: TopupParamsTypes)
  : Promise<APIPaginationResponse<TopUpRequestTypes[]>> => {
  const res = await axiosInstance.get('topup-prizes/requests', { params });
  return res.data;
};

export const getTopupAttemptListById = async ({ id }:
  { id: string })
  : Promise<TopUpAttemptByIdTypes> => {
  const res = await axiosInstance.get(`topup-prizes/requests/${id}/attempts`);
  return res.data.data;
};

export const getInfoTopUpRequestForHotline = async (params?: TopupRequestHotlineParamsTypes):
  Promise<APIPaginationResponse<TopUpRequestTypes[]>> => {
  const res = await axiosInstance.get('topup-prizes/requests/for-hotline', { params });
  return res.data;
};

export const retryTopUpRequest = async (id: number): Promise<void> => {
  await axiosInstance.post(`topup-prizes/requests/${id}/retry-topup`);
};

export const buyCardTopUpRequest = async (id: number): Promise<void> => {
  await axiosInstance.post(`topup-prizes/requests/${id}/buy-card`);
};

export const bulkRetryTopUpRequest = async ({ ids, params }: {
  ids?: string[],
  params?: any
}): Promise<void> => {
  await axiosInstance.post('topup-prizes/requests/retry-topup', ids ? { ids } : null, { params });
};

export const bulkBuyCardTopUpRequest = async ({ ids, params }: {
  ids?: string[],
  params?: any
}): Promise<void> => {
  await axiosInstance.post('topup-prizes/requests/buy-card', ids ? { ids } : null, { params });
};

export const getTopupAttemptList = async (params?: TopupParamsTypes)
  : Promise<APIPaginationResponse<TopupAttemptItemTypes[]>> => {
  const res = await axiosInstance.get('topup-prizes/requests/attempts', { params });
  return res.data;
};

export const postNeverTopUpPrize = async (id: number)
  : Promise<NeverTopUpTypes> => {
  const res = await axiosInstance.post(`topup-prizes/requests/${id}/never-topup`);
  return res.data.data;
};

export const exportTopupRequestService = async (params?: BaseFilterParams)
  : Promise<ExportDataTypes> => {
  const res = await axiosInstance.post('topup-prizes/requests/export', null, { params });
  return res.data.data;
};

export const exportTopupRequestIncludeAccountService = async (params?: BaseFilterParams)
  : Promise<ExportDataTypes> => {
  const res = await axiosInstance.post('topup-prizes/requests/export-include-account', null, { params });
  return res.data.data;
};

export const exportTopupAttemptService = async (params?: BaseFilterParams)
  : Promise<ExportDataTypes> => {
  const res = await axiosInstance.post('topup-prizes/requests/export-attempts', null, { params });
  return res.data.data;
};

export const exportTopupAttemptIncludeAccountService = async (params?: BaseFilterParams)
  : Promise<ExportDataTypes> => {
  const res = await axiosInstance.post('topup-prizes/requests/export-attempt-include-account', null, { params });
  return res.data.data;
};
