import {
  Card,
  Col, Modal, Row, Space, Typography
} from 'antd';
import { useTranslation } from 'react-i18next';

import { StatusTopUpAttemptLabel } from 'common/components/StatusLabel';
import { TopUpRequestItemTypes } from 'common/services/topupRequest/types';
import { telcos } from 'common/utils/constant';
import { formatDateTime, formatNumberWithSeparators } from 'common/utils/functions';

interface PopupAttemptsProps {
  info: TopUpRequestItemTypes['lastAttemptData'];
  isOpen?: boolean;
  handleClose: () => void;
}

const FlexRow: React.FC<{
  title?: string,
  content?: string,
  isStatus?: boolean
}> = ({ title, content, isStatus }) => (
  <div style={{
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  }}
  >
    <Typography.Text
      strong
      style={{
        flexBasis: 'fit-content', whiteSpace: 'nowrap', fontSize: '14px', lineHeight: '22px'
      }}
    >
      {title}
      :
    </Typography.Text>
    {isStatus ? (
      <div className="u-ml-12">
        <StatusTopUpAttemptLabel status={Number(content)} />
      </div>
    ) : (
      <Typography.Text className="u-ml-12" style={{ flexBasis: '80%', fontSize: '14px', lineHeight: '22px' }}>
        {content}
      </Typography.Text>
    )}
  </div>
);

const PopupAttempts: React.FC<PopupAttemptsProps> = ({
  isOpen, info, handleClose
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      className="modal-detail"
      title={<Typography.Title level={3}>{t('topupRequest.attemptStreamDetail')}</Typography.Title>}
      visible={isOpen}
      centered
      cancelButtonProps={{ style: { display: 'none' } }}
      onOk={handleClose}
      onCancel={handleClose}
    >
      {/* <Spin size="large" spinning={isLoading}> */}
      <Row gutter={16}>
        <div className="site-card-border-less-wrapper">
          <Space direction="vertical" size={12} style={{ width: '100%' }}>
            {info.map((item, idx) => (
              <Card
                key={`item-${idx.toString()}`}
              >
                <Row gutter={16}>
                  <Col className="u-mt-8">
                    <FlexRow
                      title={t('system.status')}
                      content={item.status.toString()}
                      isStatus
                    />
                  </Col>
                  <Col className="u-mt-8">
                    <FlexRow
                      title={t('callCenter.postPaid')}
                      content={item.postPaid ? 'Thuê bao trả sau' : 'Thuê bao trả trước'}
                    />
                  </Col>
                  <Col className="u-mt-8">
                    <FlexRow
                      title={t('topupRequest.telco')}
                      content={telcos[item.telco as keyof typeof telcos]}
                    />
                  </Col>

                  <Col className="u-mt-8">
                    <FlexRow
                      title={t('topupRequest.amount')}
                      content={formatNumberWithSeparators(item.amount * 1000)}
                    />
                  </Col>
                  <Col className="u-mt-8">
                    <FlexRow
                      title={t('callCenter.finishedAt')}
                      content={formatDateTime(item.finishedAt)}
                    />
                  </Col>
                </Row>
              </Card>
            ))}
          </Space>
        </div>
      </Row>
      {/* </Spin> */}
    </Modal>
  );
};

export default PopupAttempts;
