/* eslint-disable */
import {
  Col, Row, Space,
  Tooltip,
  Typography
} from 'antd';
import { Select } from 'antd/lib';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { PhaseContext } from '.';

import { useAppSelector } from 'app/store';
import { ConvertReward } from 'common/components/ConvertReward';
import { getFirstPrizeCountService, getGeneralDataService, getSpecialPrizeCountService, getTopupPrizeCountService } from 'common/services/dashboard';
import { customerLuckyCodePhases, timeGoneTooltips } from 'common/utils/constant';
import roles, { getPermission } from 'configs/roles';
import dayjs from "dayjs";
import { QuestionCircleOutlined } from "@ant-design/icons";

const HeadingPrizeSection: React.FC = () => {
  const rolesUser = useAppSelector((state) => state.auth.roles);
  const phase = useContext(PhaseContext);
  const initRef = useRef<boolean>(true);

  const { t } = useTranslation();

  const { data: generalData } = useQuery(
    ['getGeneralPrizeService', phase.phaseExecute],
    () => getGeneralDataService({
      phase: phase.phaseExecute >= 0 ? Number(phase.phaseExecute) : undefined
    }),
    {
      enabled: getPermission(rolesUser, roles.DASHBOARD_GENERAL)
    }
  );

  const { data: firstPrize } = useQuery(
    ['getFirstPrizeService', phase.phaseExecute],
    () => getFirstPrizeCountService({
      phase: phase.phaseExecute >= 0 ? Number(phase.phaseExecute) : undefined
    }),
    {
      enabled: (rolesUser.includes('*') || rolesUser.includes(roles.DASHBOARD_FIRST_PRIZE_COUNT)) && phase.phaseExecute !== -1
    }
  );

  const { data: specialPrize } = useQuery(
    ['getSpecialPrizeService', phase.phaseExecute],
    () => getSpecialPrizeCountService({
      phase: phase.phaseExecute >= 0 ? Number(phase.phaseExecute) : undefined
    }),
    {
      enabled: (rolesUser.includes('*') || rolesUser.includes(roles.DASHBOARD_SPECIAL_PRIZE_COUNT)) && phase.phaseExecute !== -1
    }
  );

  const { data: topupPrize } = useQuery(
    ['getTopupPrizeService', phase.phaseExecute],
    () => getTopupPrizeCountService({
      phase: phase.phaseExecute >= 0 ? Number(phase.phaseExecute) : undefined
    }),
    {
      enabled: (rolesUser.includes('*') || rolesUser.includes(roles.DASHBOARD_TOPUP_PRIZE_COUNT)) && phase.phaseExecute !== -1
    }
  );

  const calAwardRate = (actual: number, total: number) => {
    if (!total) return 0;
    return Number(((actual / total) * 100).toFixed(1));
  };

  const rateByTotal = useMemo(() => ({
    firstPrize: firstPrize ? {
      sended: {
        quantity: firstPrize?.totalExchanged || 0,
        rate: calAwardRate((firstPrize?.totalExchanged || 0), (firstPrize?.total || 0)),
      },
      pending: {
        quantity: firstPrize?.totalPending || 0,
        rate: calAwardRate((firstPrize?.totalPending || 0), (firstPrize?.total || 0)),
      },
      remain: {
        quantity: (firstPrize?.total || 0)
          - ((firstPrize?.totalExchanged || 0) + (firstPrize.totalPending || 0)),
        rate: calAwardRate(
          (firstPrize?.total || 0)
          - ((firstPrize?.totalExchanged || 0) + (firstPrize.totalPending || 0)),
          (firstPrize?.total || 0)
        ),
      },
    } : null,
    specialPrize: specialPrize ? {
      sended: {
        quantity: specialPrize?.totalExchanged || 0,
        rate: calAwardRate((specialPrize?.totalExchanged || 0), (specialPrize?.total || 0))
      },
      pending: {
        quantity: specialPrize?.totalPending || 0,
        rate: calAwardRate((specialPrize?.totalPending || 0), (specialPrize?.total || 0)),
      },
      remain: {
        quantity: (specialPrize?.total || 0)
          - ((specialPrize?.totalExchanged || 0) + (specialPrize?.totalPending || 0)),
        rate: calAwardRate(
          (specialPrize?.total || 0)
          - ((specialPrize?.totalExchanged || 0) + (specialPrize?.totalPending || 0)),
          (specialPrize?.total || 0)
        ),
      },
    } : null,
    topupPrize: topupPrize ? {
      sended: {
        quantity: topupPrize?.totalWon || 0,
        rate: calAwardRate((topupPrize?.totalWon || 0), (topupPrize?.total || 0))
      },
      remain: {
        quantity: (topupPrize?.total || 0) - (topupPrize?.totalWon || 0),
        rate: calAwardRate(
          ((topupPrize?.total || 0) - (topupPrize?.totalWon || 0)),
          (topupPrize?.total || 0)
        ),
      }
    } : null
  }), [firstPrize, specialPrize, topupPrize]);

  const timeGone = useMemo(() => {
    const startDate = generalData?.from;
    const endDate = generalData?.to;
    const diff = dayjs(endDate).diff(dayjs(startDate), 'day');
    const burnDays = dayjs().diff(dayjs(startDate), 'day');
    const processingPer = burnDays / diff * 100;
    return processingPer > 0 ? processingPer.toFixed(1) : 0;
  }, [generalData?.from, generalData?.to]);

  useEffect(() => {
    if (generalData && initRef.current) {
      phase.setPhaseExecute(Number(generalData.phase));
      initRef.current = false;
    }
  }, [generalData]);

  useEffect(() => {
    if (!getPermission(rolesUser, roles.DASHBOARD_GENERAL)) {
      phase.setPhaseExecute(0);
    }
  }, [rolesUser]);

  return (
    <>
      <Space
        className="t-generalSection"
        style={{
          width: '100%', flexDirection: 'column', justifyContent: 'center'
        }}
      >
        <Typography.Title className="t-generalSection_title" level={1}>NABATI GOGUMA – NGON CHUẨN HÀN MỞ NGÀN QUÀ TẶNG</Typography.Title>
        <div dangerouslySetInnerHTML={{ __html: `${t('dashboard.fromDate')} ${dayjs(generalData?.from).format('DD/MM/YYYY')}  ${t('dashboard.toDate')} <strong style="color: red">${dayjs(generalData?.to).format('DD/MM/YYYY')}</strong>` }} className="t-generalSection_title" />
        <Space>
          <Typography.Text className="t-generalSection_timeGone">{`Time gone: ${timeGone}%`}</Typography.Text>
          <Tooltip
            placement="right"
            title={() => timeGoneTooltips.map((item, idx) => (
              <Typography.Text
                style={{ display: 'block' }}
                className="u-mt-16 fs-16x24 color-white"
                key={`tooltip-topup-avg-${idx.toString()}`}
              >
                {`${t(item.label)}: ${t(item.note)}`}
              </Typography.Text>
            ))}
          >
            <QuestionCircleOutlined
              style={{ fontSize: 20 }}
              className="o-reportTopUpAvgTime_note"
            />
          </Tooltip>
        </Space>
      </Space>
      <div className="u-mt-20">
        <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography.Title className="t-generalSection_title" level={3}>{t('dashboard.generalAwards')}</Typography.Title>
          <Select
            style={{ width: '100%', minWidth: 200 }}
            placeholder="Chọn giai đoạn"
            value={phase.phaseExecute}
            onChange={(val: number) => phase.setPhaseExecute(val)}
          >
            {
              customerLuckyCodePhases.map((item, index) => (
                <Select.Option value={item.value} key={`option-${index.toString()}`}>
                  {t(item.label)}
                </Select.Option>
              ))
            }
          </Select>
        </Space>
        <Row gutter={24} className="u-mt-12">
          {
            rateByTotal.specialPrize && (
              <Col lg={8}>
                <ConvertReward
                  title={t('dashboard.titleSpecialReward')}
                  total={specialPrize?.total || 0}
                  pending={rateByTotal.specialPrize.pending}
                  sended={rateByTotal.specialPrize.sended}
                  remain={rateByTotal.specialPrize.remain}
                  type="special"
                />
              </Col>
            )
          }
          {rateByTotal.firstPrize && (
            <Col lg={8}>
              <ConvertReward
                title={t('dashboard.titleFirstReward')}
                total={firstPrize?.total || 0}
                pending={rateByTotal.firstPrize.pending}
                sended={rateByTotal.firstPrize.sended}
                remain={rateByTotal.firstPrize.remain}
                type="first"
              />
            </Col>
          )}
          {
            rateByTotal.topupPrize && (
              <Col lg={8}>
                <ConvertReward
                  title={t('dashboard.titleTopupReward')}
                  sended={rateByTotal.topupPrize?.sended}
                  total={topupPrize?.total || 0}
                  remain={rateByTotal.topupPrize?.remain}
                  type="topup"
                />
              </Col>
            )
          }
        </Row>
      </div>
    </>
  );
};

export default HeadingPrizeSection;
