import { Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps,
  FilterValueProps,
  mappingFilterFields,
  mappingFilterToQuery,
  mappingParamsFilter,
  mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import { getTopupQuotasHistoryService } from 'common/services/topupQuotas';
import { QuotasHistoryItemTypes } from 'common/services/topupQuotas/types';
// import { ROUTE_PATHS } from 'common/utils/constant';
import { telcos } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';

const TopUpQuotaHistoriesManagement: React.FC<ActiveRoles> = () => {
  /* Hooks */
  const { t } = useTranslation();

  /* Search Params */
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);
  const pageParam = searchParams.get('page');

  /* Selectors */
  const { defaultPageSize, advancedFilter } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));

  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );
  /* React-query */
  const {
    isLoading: listLoading,
    data: listData,
  } = useQuery(
    ['topup-quotas-history-list', currentPage, selectedFilterList, currentView],
    () => getTopupQuotasHistoryService({
      page: currentPage,
      limit: currentView,
      ...returnParamsFilter
    }),
    { keepPreviousData: true, enabled: !!currentPage }
  );

  /* Datas */
  const columns: ColumnsType<QuotasHistoryItemTypes> = useMemo(() => ([
    // --- STT
    {
      title: 'ID',
      key: 'id',
      width: 75,
      align: 'center',
      render: (_name: string, data: QuotasHistoryItemTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    {
      title: t('topupSMS.telco'),
      dataIndex: 'telco',
      width: 150,
      key: 'telco',
      render: (_name: string, data: QuotasHistoryItemTypes) => (
        <Typography.Text>
          {telcos[data.telco as keyof typeof telcos]}
        </Typography.Text>
      ),
    },
    {
      title: t('topupQuota.quota'),
      dataIndex: 'quota',
      key: 'quota',
      render: (_name: string, data: QuotasHistoryItemTypes) => (
        <Typography.Text>
          {data.quota}
        </Typography.Text>
      ),
    },
    {
      title: t('topupQuota.name'),
      dataIndex: 'name',
      key: 'name',
      render: (_name: string, data: QuotasHistoryItemTypes) => (
        <Typography.Text>
          {data.name}
        </Typography.Text>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (_name: string, data: QuotasHistoryItemTypes) => (
        <Typography.Text>
          {data.email}
        </Typography.Text>
      ),
    },
    // --- Cập nhật
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: {
        compare: (a: QuotasHistoryItemTypes, b: QuotasHistoryItemTypes) => {
          const aDate = new Date(a.createdAt || 0);
          const bDate = new Date(b.createdAt || 0);
          return aDate.getTime() - bDate.getTime();
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: QuotasHistoryItemTypes) => (
        <Typography.Text>
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
  ]), [t]);

  const tableData: QuotasHistoryItemTypes[] = useMemo(() => (
    listData?.data?.map((item) => ({
      ...item.topupQuotaHistoryData,
      email: item.creator.email,
      name: item.creator.name
    })) || []), [listData]);

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
  };

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('topupQuotaHistory', advancedFilter),
    [advancedFilter]
  );

  useEffect(() => {
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      ...pageParam && { page: pageParam }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterList]);

  useEffect(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.topupQuotasHistoryManagement')}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={listLoading}
          noCheckbox
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter,
          }}
          tableProps={{
            initShowColumns: ['id', 'telco', 'quota', 'name', 'email', 'createdAt'],
            columns,
            pageData: tableData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listData?.meta.total || 1,
            noBaseCol: true,
            noDeleteLanguage: true,
            filterFields
          }}
        />
      </div>
    </>
  );
};

export default TopUpQuotaHistoriesManagement;
