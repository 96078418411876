import {
  Image, message
} from 'antd';
import Sider from 'antd/lib/layout/Sider';
import Layout from 'antd/lib/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { LayoutContext } from './context';
import SocketDataModal, { CallCenterData } from './socketDataModal';

import { useAppSelector } from 'app/store';
import IconLogo from 'common/assets/images/nabati.svg';
import Sidebar, { MenuItem } from 'common/components/Sidebar';
import getTokenWebSocketService from 'common/services/websocket';
import socket from 'common/services/websocket/socket';

type Props = {
  menus: MenuItem[];
};

const Mainlayout: React.FC<Props> = ({ menus }) => {
  const user = useAppSelector((state) => state.auth.profileData);
  const { collapsed, setCollapsed } = useContext(LayoutContext);
  const [socketData, setSocketData] = useState<CallCenterData | undefined>();
  const { t } = useTranslation();

  useEffect(() => {
    if (user) {
      const { host, protocol } = window.location;
      const wsHost = `${protocol === 'https:' ? 'wss' : 'ws'}://${host}/ws`;
      // dev
      // const wsHost = 'wss://nabati-2024-dev-cms.3forcom.net/ws';
      socket(
        wsHost,
        getTokenWebSocketService,
        {
          onMessage: (data: string) => {
            const parsedData = JSON.parse(data);
            if (parsedData.event === 'customerCall') {
              setSocketData(parsedData.data);
            }
            if (parsedData.event === 'documentUpload') {
              if (parsedData.data.success) {
                message.success(t('prizeExchange.uploadDocumentSuccess'));
              } else {
                message.error(t('prizeExchange.uploadDocumentFail'));
              }
            }
            if (parsedData.event === 'readIDCard') {
              if (parsedData.data.success) {
                message.success(t('prizeExchange.readIDCardSuccess'));
              } else {
                message.error(t('prizeExchange.readIDCardFail'));
              }
            }
          },
        }
      );
    }
  }, [t, user]);

  return (
    <Layout>
      <Sider width={collapsed ? 80 : 250}>
        <div className="t-mainlayout_sidebar_header">
          <Image width={collapsed ? 46 : 68} src={IconLogo} preview={false} />
        </div>
        <div className="t-mainlayout_sidebar_menu">
          <Sidebar
            menuItems={menus}
            collapsed={collapsed}
            handleCollapsed={setCollapsed}
          />
        </div>
      </Sider>
      <Layout className="t-mainlayout_content">
        <Outlet />
      </Layout>
      <SocketDataModal
        socketData={socketData}
        handleClose={() => setSocketData(undefined)}
      />
    </Layout>
  );
};

export default Mainlayout;
