// import { UserOutlined } from '@ant-design/icons';
import {
  Space, Typography
} from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { useAppSelector } from 'app/store';
import { getSMSBrandNameService } from 'common/services/dashboard';
import { formatNumberWithSeparators } from 'common/utils/functions';
import roles, { getPermission } from 'configs/roles';
import { PhaseContext } from 'features/Home';

const ReportSMSBrandName: React.FC = () => {
  const { t } = useTranslation();
  const phase = useContext(PhaseContext);
  const rolesUser = useAppSelector((state) => state.auth.roles);

  const { data } = useQuery(
    ['sms-brand-name', phase.phaseExecute],
    () => getSMSBrandNameService({
      phase: phase.phaseExecute >= 0 ? Number(phase.phaseExecute) : undefined
    }),
    {
      enabled: getPermission(rolesUser, roles.DASHBOARD_SMS_BRAND_NAME)
        && phase.phaseExecute !== -1
    }
  );
  return (
    <div className="o-reportSMSBrandName">
      <div className="o-reportSMSBrandName_title">
        <Space>
          <Typography.Title level={4} className="fs-16x24 color-brand-color2 o-reportSMSBrandName_title">{t('dashboard.reportSMSBrandNameTitle')}</Typography.Title>
        </Space>
      </div>
      <div className="o-reportSMSBrandName_content u-mt-8">
        <div className="o-reportSMSBrandName_content_item">
          <Space direction="vertical" style={{ width: '100%' }}>
            <Space direction="horizontal">
              <Typography.Text className="o-reportSMSBrandName_content_item-heading" type="secondary">
                {t('dashboard.topupSMSBrandName')}
              </Typography.Text>
            </Space>
            <Typography.Text className="o-reportSMSBrandName_content_item-value">{formatNumberWithSeparators(data?.totalSMSBrandName || 0)}</Typography.Text>
          </Space>
        </div>
      </div>
    </div>
  );
};

export default ReportSMSBrandName;
