// import { UserOutlined } from '@ant-design/icons';
import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Space, Tooltip, Typography
} from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { useAppSelector } from 'app/store';
import { getTopupUserReportService } from 'common/services/dashboard';
import { userTopUpTooltips } from 'common/utils/constant';
import { formatNumberWithSeparators } from 'common/utils/functions';
import roles, { getPermission } from 'configs/roles';
import { PhaseContext } from 'features/Home';

const ReportTopUpUser: React.FC = () => {
  const { t } = useTranslation();
  const phase = useContext(PhaseContext);
  const rolesUser = useAppSelector((state) => state.auth.roles);

  const { data } = useQuery(
    ['topup-user-count', phase.phaseExecute],
    () => getTopupUserReportService({
      phase: phase.phaseExecute >= 0 ? Number(phase.phaseExecute) : undefined
    }),
    {
      enabled: getPermission(rolesUser, roles.DASHBOARD_TOPUP_USER_REPORT)
        && phase.phaseExecute !== -1
    }
  );
  return (
    <div className="o-reportTopUpUser">
      <div className="o-reportTopUpUser_title">
        <Space>
          <Typography.Title level={4} className="fs-16x24 color-brand-color2 o-reportTopUpUser_title">{t('dashboard.reportTopUpUserTitle')}</Typography.Title>
          <Tooltip
            placement="right"
            title={() => userTopUpTooltips.map((item, idx) => (
              <Typography.Text
                style={{ display: 'block' }}
                className="fs-16x24 color-white"
                key={`tooltip-topup-user-${idx.toString()}`}
              >
                {`${t(item.label)}: ${t(item.note)}`}
              </Typography.Text>
            ))}
          >
            <QuestionCircleOutlined
              style={{ fontSize: 20 }}
              className="o-reportTopUpUser_note"
            />
          </Tooltip>
        </Space>
      </div>
      <div className="o-reportTopUpUser_content u-mt-8">
        <div className="o-reportTopUpUser_content_item">
          <Space direction="vertical" style={{ width: '100%' }}>
            <Space direction="horizontal">
              <Typography.Text className="o-reportTopUpUser_content_item-heading" type="secondary">
                {t('formManagement.userIdsFormField')}
              </Typography.Text>
            </Space>
            <Typography.Text className="o-reportTopUpUser_content_item-value">{formatNumberWithSeparators(data?.totalUserTopup || 0)}</Typography.Text>
          </Space>
        </div>
        <div className="o-reportTopUpUser_content_item">
          <Space direction="vertical" style={{ width: '100%' }}>
            <Typography.Text className="o-reportTopUpUser_content_item-heading" type="secondary">
              {t('dashboard.avgUserAmount')}
            </Typography.Text>
            <Typography.Text className="o-reportTopUpUser_content_item-value">{Math.round(parseFloat((data?.avgUserTopup || 0).toFixed(2)))}</Typography.Text>
          </Space>
        </div>
      </div>
    </div>
  );
};

export default ReportTopUpUser;
