import {
  EyeOutlined, RedoOutlined, ShoppingOutlined, UnorderedListOutlined
} from '@ant-design/icons';
import {
  Button, Col,
  Row, Select, Space, Tooltip, Typography, message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { AxiosError } from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import PopupAttempts from './popupAttempts';
import PopupBuyCardTransactions from './popupBuyCardTransactions';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps,
  FilterValueProps,
  mappingFilterFields,
  mappingFilterToQuery,
  mappingParamsFilter,
  mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import {
  StatusBuyCardLabel,
  StatusTopUpAttemptLabel
} from 'common/components/StatusLabel';
import {
  bulkBuyCardTopUpRequest,
  bulkRetryTopUpRequest,
  buyCardTopUpRequest,
  exportTopupAttemptIncludeAccountService,
  exportTopupAttemptService,
  getTopupAttemptList,
  retryTopUpRequest,
} from 'common/services/topupRequest';
import {
  BuyCardStatusEnum, TopupAttemptItemTypes
} from 'common/services/topupRequest/types';
import { customerLuckyCodePhases, sources, telcos } from 'common/utils/constant';
import {
  detectError, formatDateTime, formatNumberWithSeparators, formatPhoneNumber
} from 'common/utils/functions';
import roles, { getPermission } from 'configs/roles';

const TopUpTransactionManagement: React.FC<ActiveRoles> = ({
  roleIndex,
  roleCreate,
  roleUpdate,
  roleOther
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  /* Search Params */
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);
  const pageParam = searchParams.get('page');

  /* Selectors */
  const { advancedFilter, defaultPageSize } = useAppSelector((state) => state.system);
  const { roles: userRoles } = useAppSelector((state) => state.auth);

  /* States */
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState(defaultPageSize);
  // const [keyword, setKeyword] = useState('');
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));
  const [attemptId, setAttemptId] = useState('');
  const [selectedPhase, setSelectedPhase] = useState<number | undefined>(undefined);
  const [buyCardTransactions, setBuyCardTransactions] = useState<TopupAttemptItemTypes['buyCards']>([]);

  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );
  const queryKey = ['topup-transaction-list', currentPage, currentView, selectedFilterList, selectedPhase];

  /* React-query */
  const {
    isLoading: listLoading,
    data: listData,
  } = useQuery(
    queryKey,
    () => getTopupAttemptList({
      // keyword,
      page: currentPage,
      limit: currentView,
      phase: selectedPhase,
      ...returnParamsFilter,
    }),
    { keepPreviousData: true }
  );

  const { mutate: bulkBuyCardMutate, isLoading: bulkBuyCardLoading } = useMutation(
    ['topup-request-bulk-buyCard'],
    ({ ids, params: buyCardParams }: { ids?: string[], params?: any }) => bulkBuyCardTopUpRequest({
      ...ids && { ids },
      ...buyCardParams && { params: buyCardParams },
    }),
    {
      onSuccess() {
        message.success(t('message.buyCardSuccess'));
      },
      onError(err) {
        if (err instanceof AxiosError) {
          message.error(detectError(Number(err.response?.status)));
        } else if (Array.isArray(err) && err.length > 0) {
          message.error(t(`message.${err[0].message.toLowerCase().replaceAll(' ', '_')}`));
        }
      }
    }
  );

  const { mutate: bulkRetryMutate, isLoading: bulkRetryLoading } = useMutation(
    ['topup-request--bulk-retry'],
    ({ ids, params: retryParams }: { ids?: string[], params?: any }) => bulkRetryTopUpRequest({
      ...ids && { ids },
      ...retryParams && { params: retryParams },
    }),
    {
      onSuccess() {
        message.success(t('message.retrySuccess'));
      },
      onError(err) {
        if (err instanceof AxiosError) {
          message.error(detectError(Number(err.response?.status)));
        } else if (Array.isArray(err) && err.length > 0) {
          message.error(t(`message.${err[0].message.toLowerCase().replaceAll(' ', '_')}`));
        }
      }
    }
  );

  const { mutate: retryMutate, isLoading: retryLoading } = useMutation(
    ['topup-request-retry'],
    (id: number) => retryTopUpRequest(id),
    {
      onSuccess() {
        message.success(t('message.retrySuccess'));
        queryClient.invalidateQueries(['topup-attempt-list']);
      },
      onError(err) {
        if (err instanceof AxiosError) {
          message.error(t(detectError(Number(err.response?.status))));
        }
      }
    }
  );

  const { mutate: buyCardMutate, isLoading: buyCardLoading } = useMutation(
    ['topup-request-buyCard'],
    (id: number) => buyCardTopUpRequest(id),
    {
      onSuccess() {
        message.success(t('message.buyCardSuccess'));
      },
      onError(err) {
        if (err instanceof AxiosError) {
          message.error(t(detectError(Number(err.response?.status))));
        }
      }
    }
  );

  const { mutate: exportTopupAttemptMutate, isLoading: exportAttemptLoading } = useMutation(
    ['exportTopupAttemptMutate'],
    () => exportTopupAttemptService({
      page: currentPage,
      limit: currentView,
      phase: selectedPhase,
      ...returnParamsFilter,
    }),
    {
      onSuccess(res) {
        if (res.link) {
          window.open(
            res.link,
            '_blank',
          );
        }
      },
      onError: () => {
        message.error(t('message.uploadError'));
      }
    }
  );

  const {
    mutate: exportTopupAttemptIncludeAccountMutate,
    isLoading: exportTopupAttemptIncludeAccounLoading
  } = useMutation(
    ['exportTopupAttemptIncludeAccountMutate'],
    () => exportTopupAttemptIncludeAccountService({
      page: currentPage,
      limit: currentView,
      phase: selectedPhase,
      ...returnParamsFilter,
    }),
    {
      onSuccess(res) {
        if (res.link) {
          window.open(
            res.link,
            '_blank',
          );
        }
      },
      onError: () => {
        message.error(t('message.uploadError'));
      }
    }
  );

  /* Datas */
  const columns: ColumnsType<TopupAttemptItemTypes> = useMemo(
    () => [
      {
        title: 'Transaction ID',
        key: 'transactionId',
        align: 'center',
        render: (_name: string, data: TopupAttemptItemTypes) => (
          <Space style={{ justifyContent: 'space-around' }}>
            <Typography.Text style={{ flex: 1 }}>{data.uid || '-'}</Typography.Text>
            {data.buyCards.length > 0 && (
              <Tooltip title={t('topupAttempt.buyCardTransactionIdTooltip')}>
                <Button type="text" onClick={() => setBuyCardTransactions(data.buyCards)}>
                  <UnorderedListOutlined />
                </Button>
              </Tooltip>
            )}
          </Space>
        ),
      },
      // {
      //   title: t('topupAttempt.buyCardTransactionId'),
      //   key: 'buyCardTransactionId',
      //   align: 'center',
      //   render: (_name: string, data: TopupAttemptItemTypes) => (
      //     <Space style={{ justifyContent: 'space-around' }}>
      //       <Typography.Text style={{ flex: 1 }}>{data.
      // buyCards[data.buyCards.length - 1]?.uid || '-'}</Typography.Text>
      //       {data.buyCards.length > 0 && (
      //         <Tooltip title={t('topupAttempt.buyCardTransactionIdTooltip')}>
      //           <Button type="text" onClick={() => setBuyCardTransactions(data.buyCards)}>
      //             <UnorderedListOutlined />
      //           </Button>
      //         </Tooltip>
      //       )}
      //     </Space>
      //   ),
      // },
      {
        title: t('system.status'),
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        // sorter: {
        //   compare: (a: TopupAttemptItemTypes, b: TopupAttemptItemTypes) => a.status - b.status,
        // },
        sortDirections: ['descend', 'ascend'],
        render: (_name: string, data: TopupAttemptItemTypes) => (
          <StatusTopUpAttemptLabel status={data.status} />
        ),
      },
      {
        title: t('topupAttempt.buyCardStatus'),
        dataIndex: 'buyCardStatus',
        key: 'buyCardStatus',
        align: 'center',
        render: (_name: string, data: TopupAttemptItemTypes) => (
          <StatusBuyCardLabel status={data.buyCardStatus} />
        ),
      },
      // {
      //   title: t('topupAttempt.cardCode'),
      //   dataIndex: 'cardCode',
      //   key: 'cardCode',
      //   align: 'center',
      //   render: (_name: string, data: TopupAttemptItemTypes) => (
      //     <Typography.Text>{data.cardCode || '-'}</Typography.Text>
      //   ),
      // },
      {
        title: t('topupAttempt.responseCode'),
        dataIndex: 'responseCode',
        key: 'responseCode',
        align: 'center',
        render: (_name: string, data: TopupAttemptItemTypes) => (
          <Typography.Text>
            {data.statusCode || '-'}
          </Typography.Text>
        ),
      },
      {
        title: t('customerPrize.luckyCode'),
        key: 'luckyCode',
        align: 'center',
        width: 150,
        render: (_name: string, data: TopupAttemptItemTypes) => (
          <Typography.Text>{data.customerPrize?.luckyCode || '-'}</Typography.Text>
        ),
      },
      {
        title: t('system.phone'),
        key: 'phone',
        align: 'center',
        width: 150,
        render: (_name: string, data: TopupAttemptItemTypes) => (
          <Typography.Text>{formatPhoneNumber(data.phone, true)}</Typography.Text>
        ),
      },
      {
        title: t('customerPrize.zaloPhone'),
        key: 'zaloPhone',
        align: 'center',
        width: 150,
        render: (_name: string, data: TopupAttemptItemTypes) => (
          <Typography.Text>{formatPhoneNumber(data.zaloPhone, true)}</Typography.Text>
        ),
      },
      {
        title: t('callCenter.telco'),
        dataIndex: 'telco',
        key: 'telco',
        align: 'center',
        render: (_name: string, data: TopupAttemptItemTypes) => (
          <Typography.Text>
            {telcos[data.telco as keyof typeof telcos]}
          </Typography.Text>
        ),
      },
      {
        title: t('callCenter.telcoCustomer'),
        dataIndex: 'telcoCustomer',
        key: 'telcoCustomer',
        align: 'center',
        render: (_name: string, data: TopupAttemptItemTypes) => (
          <Typography.Text>
            {telcos[data.telcoCustomer as keyof typeof telcos]}
          </Typography.Text>
        ),
      },
      {
        title: t('customerPrize.source'),
        dataIndex: 'source',
        width: 150,
        key: 'source',
        align: 'center',
        render: (_name: string, data: TopupAttemptItemTypes) => (
          <Typography.Text>
            {sources[data.source as keyof typeof sources]}
          </Typography.Text>
        ),
      },
      {
        title: t('topupRequest.amount'),
        dataIndex: 'amount',
        key: 'amount',
        align: 'center',
        // sorter: {
        //   compare: (
        //     a: TopupAttemptItemTypes,
        //     b: TopupAttemptItemTypes
        //   ) => (a?.amount || 0)
        //     - (b?.amount || 0),
        // },
        sortDirections: ['descend', 'ascend'],
        render: (_name: string, data: TopupAttemptItemTypes) => (
          <Typography.Text>
            {formatNumberWithSeparators(data.amount * 1000) || '-'}
          </Typography.Text>
        ),
      },
      // --- Ngày khách hàng tham gia
      {
        title: t('customerPrize.customerJoiningDate'),
        dataIndex: 'customerJoiningDate',
        key: 'customerJoiningDate',
        width: 200,
        // sorter: {
        //   compare: (a: TopupAttemptItemTypes, b: TopupAttemptItemTypes) => {
        //     const aDate = new Date(a.createdAt || 0);
        //     const bDate = new Date(b.createdAt || 0);
        //     return aDate.getTime() - bDate.getTime();
        //   },
        // },
        sortDirections: ['descend', 'ascend'],
        render: (_name: string, data: TopupAttemptItemTypes) => (
          <Typography.Text>{formatDateTime(data.customerLucky?.createdAt || '')}</Typography.Text>
        ),
      },
      // --- Cập nhật
      {
        title: t('system.createdAt'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 200,
        // sorter: {
        //   compare: (a: TopupAttemptItemTypes, b: TopupAttemptItemTypes) => {
        //     const aDate = new Date(a.createdAt || 0);
        //     const bDate = new Date(b.createdAt || 0);
        //     return aDate.getTime() - bDate.getTime();
        //   },
        // },
        sortDirections: ['descend', 'ascend'],
        render: (_name: string, data: TopupAttemptItemTypes) => (
          <Typography.Text>{formatDateTime(data.createdAt)}</Typography.Text>
        ),
      },
      {
        title: t('callCenter.finishedAt'),
        dataIndex: 'finishedAt',
        key: 'finishedAt',
        width: 200,
        // sorter: {
        //   compare: (a: TopupAttemptItemTypes, b: TopupAttemptItemTypes) => {
        //     const aDate = new Date(a.finishedAt || 0);
        //     const bDate = new Date(b.finishedAt || 0);
        //     return aDate.getTime() - bDate.getTime();
        //   },
        // },
        sortDirections: ['descend', 'ascend'],
        render: (_name: string, data: TopupAttemptItemTypes) => (
          <Typography.Text>{formatDateTime(data.finishedAt)}</Typography.Text>
        ),
      },
      // --- Xem
      {
        title: t('system.action'),
        key: 'action',
        width: 100,
        align: 'center',
        fixed: 'right',
        render: (_name: string, data: TopupAttemptItemTypes) => {
          const { id, status, buyCardStatus } = data;
          const conditionBuyCard = status === 4 && (buyCardStatus === BuyCardStatusEnum.Failed
            || buyCardStatus === BuyCardStatusEnum.Never);
          return (
            <Row gutter={16}>
              <Col span={8}>
                <Button
                  disabled={!roleIndex}
                  icon={<EyeOutlined />}
                  onClick={() => setAttemptId(data.uid)}
                />
              </Col>
              <Col span={8}>
                <Tooltip title={t('system.retryTopUp')}>
                  <Button
                    loading={retryLoading}
                    disabled={!(getPermission(roleOther, roles.TOPUP_ATTEMPT_RETRY)
                      && conditionBuyCard)}
                    icon={<RedoOutlined />}
                    onClick={() => retryMutate(id)}
                  />
                </Tooltip>
              </Col>
              <Col span={8}>
                <Tooltip title={t('system.buyCardTopUp')}>
                  <Button
                    loading={buyCardLoading}
                    disabled={!(getPermission(roleOther, roles.TOPUP_ATTEMPT_BUY_CARD)
                      && conditionBuyCard)}
                    icon={<ShoppingOutlined />}
                    onClick={() => buyCardMutate(id)}
                  />
                </Tooltip>
              </Col>
            </Row>
          );
        }
      },
    ],
    [t, retryMutate, buyCardMutate, retryLoading, buyCardLoading, roleIndex, roleOther]
  );

  const tableData: TopupAttemptItemTypes[] = useMemo(() => (
    listData?.data.map((item) => ({
      ...item,
    })) || []), [listData]);

  const attempsDataPopup = useMemo(
    () => listData?.data.find((item) => item.uid === attemptId),
    [listData, attemptId]
  );

  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
  };

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('topupAttempt', advancedFilter),
    [advancedFilter]
  );

  const extraOptions = useMemo(() => {
    const initOptions = [
      {
        isActive: getPermission(userRoles, roles.TOPUP_ATTEMPT_BULK_BUY_CARD),
        data: {
          label: t('system.buyCardTopUp'),
          value: 'buyCardAll'
        }
      },
      {
        isActive: getPermission(userRoles, roles.TOPUP_ATTEMPT_BULK_RETRY),
        data: {
          label: t('system.retryTopUp'),
          value: 'retryAll',
        }
      },
      // {
      //   isActive: getPermission(userRoles, roles.TOPUP_REQUEST_BULK_BUY_CARD),
      //   data: {
      //     label: t('system.buyCardTopUpAllPages'),
      //     value: 'buyCardAllPages',
      //     isExecuted: Object.keys(returnParamsFilter).length > 0
      //   }
      // },
      // {
      //   isActive: getPermission(userRoles, roles.TOPUP_REQUEST_BULK_RETRY),
      //   data: {
      //     label: t('system.retryTopUpAllPages'),
      //     value: 'retryAllPages',
      //     isExecuted: Object.keys(returnParamsFilter).length > 0
      //   }
      // }
    ];
    return initOptions.filter((o) => o.isActive).map((o) => ({ ...o.data }));
  }, [userRoles, t]);

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() }, { replace: true });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  useEffect(() => {
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      ...pageParam && { page: pageParam }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterList]);

  useEffect(() => {
    setCurrentPage(1);
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      page: '1'
    }, { replace: true });
  }, [setSearchParams, selectedFilterList]);

  useEffect(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.topupTransaction')}
        rightHeader={(
          <Space>
            <Button
              type="primary"
              loading={exportTopupAttemptIncludeAccounLoading}
              disabled={!getPermission(
                roleOther,
                roles.TOPUP_ATTEMPT_EXPORT_ATTEMPTS_INCLUDE_ACCOUNT
              )
                || exportTopupAttemptIncludeAccounLoading}
              onClick={() => exportTopupAttemptIncludeAccountMutate()}
            >
              {t('topupRequest.exportIncludeAccount')}
            </Button>
            <Button
              type="primary"
              loading={exportAttemptLoading}
              disabled={!getPermission(roleOther, roles.TOPUP_ATTEMPT_EXPORT_ATTEMPTS)
                || exportAttemptLoading}
              onClick={() => exportTopupAttemptMutate()}
            >
              {t('system.export')}
            </Button>
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={listLoading || bulkBuyCardLoading || bulkRetryLoading}
          // handleSearch={setKeyword}
          noCheckbox={false}
          roles={{
            roleCreate,
            roleUpdate,
            roleDelete: true,
          }}
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter,
          }}
          tableProps={{
            initShowColumns: [
              'transactionId',
              'telco',
              'telcoCustomer',
              'phone',
              'zaloPhone',
              'source',
              'luckyCode',
              'status',
              'buyCardStatus',
              'responseCode',
              'amount',
              'customerJoiningDate',
              'createdAt',
              'finishedAt',
              'action',
            ],
            columns,
            currentPage,
            pageSize: currentView,
            total: listData?.meta.total || 1,
            handleSetCurrentPage,
            handleSetCurrentView,
            pageData: tableData,
            noBaseCol: true,
            noDeleteLanguage: true,
            filterFields,
          }}
          buckActions={extraOptions}
          handleChangeBulkAction={(action, selected) => {
            switch (action) {
              case 'retryAll':
                bulkRetryMutate({ ids: selected.map((item) => item.id.toString()) });
                break;
              case 'buyCardAll':
                bulkBuyCardMutate({ ids: selected.map((item) => item.id.toString()) });
                break;
              default:
                break;
            }
          }}
          leftCustomForm={(
            <Select
              style={{ width: '100%' }}
              placeholder="Lọc theo phase"
              allowClear
              // onClear={() => onChangeSelect({ id: data.topupData.id.value, type: undefined })}
              value={selectedPhase}
              onChange={(val) => setSelectedPhase(val)}
            >
              {
                customerLuckyCodePhases.map((item, index) => (
                  <Select.Option value={item.value} key={`option-${index.toString()}`}>
                    {t(item.label)}
                  </Select.Option>
                ))
              }
            </Select>
          )}
        />
        <PopupAttempts
          isOpen={!!attemptId}
          handleClose={() => setAttemptId('')}
          info={attempsDataPopup}
        />
        <PopupBuyCardTransactions
          isOpen={buyCardTransactions.length > 0}
          list={buyCardTransactions}
          handleClose={() => setBuyCardTransactions([])}
        />
      </div>
    </>
  );
};

export default TopUpTransactionManagement;
