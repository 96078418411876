const luckyDrawRoundDummy = [
  {
    label: 'customerLuckyDraw.round1',
    value: 1
  },
  {
    label: 'customerLuckyDraw.round2',
    value: 2
  },
  {
    label: 'customerLuckyDraw.round3',
    value: 3
  },
  {
    label: 'customerLuckyDraw.round4',
    value: 4
  },
  {
    label: 'customerLuckyDraw.round5',
    value: 5
  },
  {
    label: 'customerLuckyDraw.round6',
    value: 6
  },
];

export default luckyDrawRoundDummy;
