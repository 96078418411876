import { CustomerLuckyDrawType, SetUpLuckyDrawPrizeParams } from './types';

import axiosInstance from 'common/services/common/instance';

export const getCustomerLuckyDrawService = async (
  params?: BaseFilterParams
): Promise<APIPaginationResponse<CustomerLuckyDrawType[]>> => {
  const res = await axiosInstance.get('customer-lucky-draws', { params });
  return res.data;
};

export const getCustomerLuckyDrawByIdService = async (
  id: number
): Promise<CustomerLuckyDrawType> => {
  const res = await axiosInstance.get(`customer-lucky-draws/${id}`);
  return res.data;
};

export const postFirstPrizeService = async (
  data: SetUpLuckyDrawPrizeParams
): Promise<void> => {
  await axiosInstance.post('customer-lucky-draws/first-prize', data);
};
