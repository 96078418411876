import { BuyCardStatusEnum } from 'common/services/topupRequest/types';

// eslint-disable-next-line import/prefer-default-export
export const attemptStatusDummy = [
  {
    label: 'topupAttempt.pendingTopUpAttemptStatus',
    value: 1,
  },
  {
    label: 'topupAttempt.processingTopUpAttemptStatus',
    value: 2,
  },
  {
    label: 'topupAttempt.successTopUpAttemptStatus',
    value: 3,
  },
  {
    label: 'topupAttempt.failedTopUpAttemptStatus',
    value: 4,
  },
];

export const topupStatusCodeDummy = [
  {
    label: 'Processing - 201',
    value: 201,
  },
  {
    label: 'Success - 200',
    value: 200,
  },
  {
    label: 'Failed - 202',
    value: 202,
  },
  {
    label: 'Phone inactive - 414',
    value: 414,
  },
  {
    label: 'Phone locked - 415',
    value: 415,
  },
  {
    label: 'Postpaid - 416',
    value: 416,
  },
  {
    label: 'Postpaid without EZPay - 417',
    value: 417,
  },
  {
    label: 'Phone locked one way - 418',
    value: 418,
  },
  {
    label: 'Cant add money - 419',
    value: 419,
  },
  {
    label: 'Out of balance - 100',
    value: 100,
  },
  {
    label: 'Amount not available - 101',
    value: 101,
  },
  {
    label: 'Transaction ID exists - 102',
    value: 102,
  },
  {
    label: 'Transaction ID not exists - 104',
    value: 104,
  },
  {
    label: 'Invalid parameter - 106',
    value: 106,
  },
  {
    label: 'Not allowed IP - 108',
    value: 108,
  },
  {
    label: 'Server busy - 120',
    value: 120,
  },
];

export const topupStatusBuyCardDummy = [
  {
    label: BuyCardStatusEnum.Never,
    value: 1,
  },
  {
    label: BuyCardStatusEnum.Processing,
    value: 3,
  },
  {
    label: BuyCardStatusEnum.Success,
    value: 4,
  },
  {
    label: BuyCardStatusEnum.Failed,
    value: 2,
  },
];
