/* eslint-disable max-len */
import { UploadOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Card, Col, DatePicker, Modal, Row,
  Space,
  Spin, Typography, Upload, UploadFile, message
} from 'antd';
import { UploadChangeParam } from 'antd/es/upload/interface';
import TextArea from 'antd/lib/input/TextArea';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { DropdownElement } from 'common/components/DropdownType';
import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import {
  getCustomerPrizeDetailService,
  updateCustomerPrizeService,
  uploadCustomerFileService
} from 'common/services/customer';
import { UpdatePrizeInformation, UpdatePrizeInformationById } from 'common/services/customer/types';
import { updateStatusCustomerTopUpPrizeService } from 'common/services/customerPrizes';
import { CustomerPrizeStatus, ParamsUpdateStatus } from 'common/services/customerPrizes/types';
import { statusOptions, textStatusSMS } from 'common/utils/constant';
import { formatDateTime, resizeImage } from 'common/utils/functions';
import { customerFirstPrize, updateStatusCustomerPrize } from 'common/utils/schemas';
import roles, { getPermission } from 'configs/roles';

type FormType = {
  idCardFront: string
  idCardBack: string
  awardReport: string
  receiveRewardReport: string
  awardImage: string
  address: string
  noteAgent: string
  contactAt: string
  isRequire?: boolean
};

type UpdateStatusFormType = {
  noteCancel: string;
};

type UploadFileType = {
  fieldName: keyof FormType;
  file: File
};

type ModalType = 'previewImg' | 'updateStatus';

const CustomerFirstPrizeDetail: React.FC<ActiveRoles> = ({
  roleIndex, roleUpdate, roleOther
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const idParams = Number(searchParams.get('id'));
  const queryClient = useQueryClient();

  const [selectedStatus, setSelectedStatus] = useState<CustomerPrizeStatus | undefined>(undefined);

  const [imageUrl, setImageUrl] = useState<string>('');
  const [isOpenModal, setIsOpenModal] = useState<ModalType | null>(null);
  const [srcList, setSrcList] = useState<{ [key in keyof FormType]: { edit: boolean, value: string } }>({
    idCardFront: { edit: false, value: '' },
    idCardBack: { edit: false, value: '' },
    awardReport: { edit: false, value: '' },
    receiveRewardReport: { edit: false, value: '' },
    awardImage: { edit: false, value: '' },
    address: { edit: false, value: '' },
    noteAgent: { edit: false, value: '' },
    contactAt: { edit: false, value: '' }
  });

  /* Variables */

  const method = useForm<FormType>({
    resolver: yupResolver(customerFirstPrize),
    mode: 'onChange',
    defaultValues: {
      idCardFront: '',
      idCardBack: '',
      awardReport: '',
      receiveRewardReport: '',
      awardImage: '',
      address: '',
      noteAgent: '',
      contactAt: '',
      isRequire: false
    }
  });

  /* Queries */
  const queryKey = ['getCustomerPrizeDetail', idParams];
  const { data: customerDataDetail, isLoading } = useQuery(
    queryKey,
    () => {
      if (idParams) {
        return getCustomerPrizeDetailService(String(idParams));
      }
      return null;
    },
    {
      enabled: !!idParams && roleIndex,
      onSuccess(data) {
        if (data) {
          setSelectedStatus(data.customerPrize.status);
        }
      },
    }
  );

  const { mutate: updateCustomerPrizeMutate, isLoading: updateLoading } = useMutation(
    ['updateCustomerPrizeMutate'],
    async (params: UpdatePrizeInformationById) => updateCustomerPrizeService(params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries(queryKey);
      },
      onError: () => {
        message.error(t('message.updateError'));
      }
    }
  );

  const { mutate: uploadCustomerFileMutate, isLoading: uploadLoading } = useMutation(
    ['uploadCustomerFileMutate'],
    (params: UploadFileType) => uploadCustomerFileService(params.file),
    {
      onSuccess: (res, vari) => {
        if (res.success) {
          method.setValue(vari.fieldName, res.path);
        }
        message.success(t('message.updateSuccess'));
      },
      onError: () => {
        message.error(t('message.updateError'));
      }
    }
  );

  const updateStatusMethod = useForm<UpdateStatusFormType>({
    resolver: selectedStatus === 3 ? yupResolver(updateStatusCustomerPrize) : undefined,
    mode: 'onChange',
    defaultValues: {
      noteCancel: ''
    }
  });

  const { mutate: updateStatusCustomerPrizeMutate, isLoading: updateStatusLoading } = useMutation(
    ['updateStatusCustomerPrizeMutate'],
    async (params: ParamsUpdateStatus) => updateStatusCustomerTopUpPrizeService(params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries(queryKey);
      },
      onError: () => {
        message.error(t('message.updateError'));
      },
      onSettled: () => {
        setIsOpenModal(null);
        updateStatusMethod.reset();
      }
    }
  );

  const handleChangeFileList = async (info: UploadChangeParam<UploadFile>, fieldName: keyof FormType) => {
    const originFileObj = info.fileList?.[0]?.originFileObj as File;
    await resizeImage(originFileObj, (file) => uploadCustomerFileMutate({ fieldName, file }));
    const url = URL.createObjectURL(originFileObj);
    setSrcList((prev) => ({
      ...prev,
      [fieldName]: {
        edit: true,
        value: url
      }
    }));
  };

  const handleSubmit = async () => {
    const result = await method.trigger();
    if (!result) {
      return;
    }

    const mainData = method.getValues();
    const dataSubmit: UpdatePrizeInformation = {
      address: mainData.address,
      noteAgent: mainData.noteAgent,
      contactAt: mainData.contactAt,
      ...(srcList.idCardFront.edit && { idCardFront: mainData.idCardFront }),
      ...(srcList.idCardBack.edit && { idCardBack: mainData.idCardBack }),
      ...(srcList.awardReport.edit && { awardReport: mainData.awardReport }),
      ...(srcList.receiveRewardReport.edit && { receiveRewardReport: mainData.receiveRewardReport }),
      ...(srcList.awardImage.edit && { awardImage: mainData.awardImage }),
    };

    try {
      if (idParams) {
        updateCustomerPrizeMutate({
          id: idParams,
          ...dataSubmit
        });
      }
    } catch (error: any) {
      message.error(t('message.updateError'));
    }
  };

  const handleUpdateStatus = async () => {
    if (selectedStatus === 3) {
      const isValid = await updateStatusMethod.trigger();
      if (!isValid) return;
    }
    if (idParams && selectedStatus) {
      const data = updateStatusMethod.getValues();
      updateStatusCustomerPrizeMutate({ id: idParams, data: { ...data, status: selectedStatus } });
    }
  };
  useEffect(() => {
    if (customerDataDetail) {
      const dataDetail = {
        idCardFront: customerDataDetail.customerPrize.idCardFront || '',
        idCardBack: customerDataDetail.customerPrize.idCardBack || '',
        awardReport: customerDataDetail.customerPrize.awardReport || '',
        receiveRewardReport: customerDataDetail.customerPrize.receiveRewardReport || '',
        awardImage: customerDataDetail.customerPrize.awardImage || '',
        address: customerDataDetail.customerPrize.address || '',
        noteAgent: customerDataDetail.customerPrize.noteAgent || '',
        contactAt: customerDataDetail.customerPrize.contactAt || ''
      };
      setSrcList({
        address: { edit: false, value: dataDetail.address },
        awardImage: { edit: false, value: dataDetail.awardImage },
        awardReport: { edit: false, value: dataDetail.awardReport },
        idCardBack: { edit: false, value: dataDetail.idCardBack },
        idCardFront: { edit: false, value: dataDetail.idCardFront },
        noteAgent: { edit: false, value: dataDetail.noteAgent },
        receiveRewardReport: { edit: false, value: dataDetail.receiveRewardReport },
        contactAt: { edit: false, value: dataDetail.contactAt }
      });
      method.reset({ ...dataDetail, isRequire: roleUpdate });
    }
  }, [customerDataDetail, method, roleUpdate]);

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.customerFirstPrize')}
        rightHeader={(
          <Space>
            <Button
              type="primary"
              loading={updateLoading}
              disabled={roleUpdate ? false : !getPermission(roleOther, roles.CUSTOMER_PRIZE_UPDATE_CC)}
              onClick={() => handleSubmit()}
            >
              {t('system.save')}
            </Button>
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <Spin size="large" spinning={isLoading}>
          <Row gutter={16}>
            <Col xxl={24}>
              <Card type="inner">
                <Row gutter={16}>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('customerPrize.fullName')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {customerDataDetail?.customer.fullName}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('customerPrize.phone')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {customerDataDetail?.customer.phone}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('customerPrize.zaloPhone')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {customerDataDetail?.customer.zaloPhone}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('customerPrize.luckyCode')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {customerDataDetail?.customerPrize.luckyCode}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Space direction="horizontal">
                      <Typography.Text strong>
                        {t('system.status')}
                        {': '}
                      </Typography.Text>
                      <DropdownElement
                        locale="vi"
                        options={statusOptions}
                        noMargin
                        allowClear={false}
                        placeholder={`${t('system.select')} ${t('callCenter.status')}`}
                        value={selectedStatus}
                        onChange={(val) => setSelectedStatus(val)}
                        readOnly={customerDataDetail?.customerPrize.status !== 2 || !getPermission(roleOther, roles.CUSTOMER_PRIZE_UPDATE_STATUS)}
                      />
                      {
                        customerDataDetail?.customerPrize.status === 2 && getPermission(roleOther, roles.CUSTOMER_PRIZE_UPDATE_STATUS) && (
                          <Button
                            type="primary"
                            onClick={() => {
                              if (selectedStatus === 3) {
                                setIsOpenModal('updateStatus');
                              } else {
                                handleUpdateStatus();
                              }
                            }}
                          >
                            {t('customerPrize.updateStatus')}
                          </Button>
                        )
                      }
                    </Space>
                    {/* <Typography.Text>
                      {t(statusOptions.find((ele) => ele.value === customerDataDetail?.customerPrize.status)?.label || '')}
                    </Typography.Text> */}
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('customerPrize.smsStatus')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {t(textStatusSMS(customerDataDetail?.customerPrize.smsStatus)) || ''}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('location.province')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {customerDataDetail?.customerPrize.province.name || ''}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('location.district')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {customerDataDetail?.customerPrize.district.name || ''}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('customerPrize.address')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {customerDataDetail?.customerPrize.address || ''}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('system.createdAt')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {formatDateTime(customerDataDetail?.customerPrize.createdAt || '')}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('customerPrize.luckyCodeCreatedAt')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {formatDateTime(customerDataDetail?.customerPrize?.luckyCodeCreatedAt || '')}
                    </Typography.Text>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <FormProvider {...method}>
            <Row gutter={24} style={{ marginTop: '12px' }}>
              <Col span={8}>
                <Typography.Text strong style={{ display: 'block', paddingBottom: '12px' }}>
                  {t('customerPrize.idCardFront')}
                  {' '}
                  {roleUpdate && <span style={{ color: 'red' }}>*</span>}
                </Typography.Text>
                <Controller
                  name="idCardFront"
                  render={({ fieldState }) => (
                    <div className="e-customerFirstPrize">
                      <Upload
                        disabled={uploadLoading || !roleUpdate}
                        maxCount={1}
                        accept="image/*"
                        beforeUpload={() => false}
                        onChange={(data) => handleChangeFileList(data, 'idCardFront')}
                      >
                        <Button
                          icon={<UploadOutlined />}
                          disabled={!roleUpdate}
                          loading={uploadLoading}
                        >
                          Upload Hình ảnh
                        </Button>
                      </Upload>
                      {srcList?.idCardFront.value && (
                        <img
                          onClick={() => {
                            setImageUrl(srcList.idCardFront.value);
                            setIsOpenModal('previewImg');
                          }}
                          src={srcList.idCardFront.value}
                          alt="uploaded"
                          style={{ width: '50%', marginTop: '20px', cursor: 'pointer' }}
                        />
                      )}
                      {(fieldState.error && !srcList?.idCardFront.value) && (
                        <div className="e-customerFirstPrize-errorText">
                          {fieldState.error.message}
                        </div>
                      )}
                    </div>
                  )}
                />
              </Col>
              <Col span={8}>
                <Typography.Text strong style={{ display: 'block', paddingBottom: '12px' }}>
                  {t('customerPrize.idCardBack')}
                  {' '}
                  {roleUpdate && <span style={{ color: 'red' }}>*</span>}
                </Typography.Text>
                <Controller
                  name="idCardBack"
                  render={({ fieldState }) => (
                    <div className="e-customerFirstPrize">
                      <Upload
                        disabled={uploadLoading || !roleUpdate}
                        maxCount={1}
                        accept="image/*"
                        beforeUpload={() => false}
                        onChange={(data) => handleChangeFileList(data, 'idCardBack')}
                      >
                        <Button
                          icon={<UploadOutlined />}
                          disabled={!roleUpdate}
                          loading={uploadLoading}
                        >
                          Upload Hình ảnh
                        </Button>
                      </Upload>
                      {srcList?.idCardBack.value && (
                        <img
                          onClick={() => {
                            setImageUrl(srcList.idCardBack.value);
                            setIsOpenModal('previewImg');
                          }}
                          src={srcList.idCardBack.value}
                          alt="uploaded"
                          style={{ width: '50%', marginTop: '20px', cursor: 'pointer' }}
                        />
                      )}
                      {(fieldState.error && !srcList?.idCardBack.value) && (
                        <div className="e-customerFirstPrize-errorText">
                          {fieldState.error.message}
                        </div>
                      )}
                    </div>
                  )}
                />
              </Col>
              <Col span={8}>
                <Typography.Text strong style={{ display: 'block', paddingBottom: '12px' }}>
                  {t('customerPrize.awardReport')}
                </Typography.Text>
                <Controller
                  name="awardReport"
                  render={({ fieldState }) => (
                    <div className="e-customerFirstPrize">
                      <Upload
                        disabled={uploadLoading || !roleUpdate}
                        maxCount={1}
                        accept="image/*"
                        beforeUpload={() => false}
                        onChange={(data) => handleChangeFileList(data, 'awardReport')}
                      >
                        <Button
                          icon={<UploadOutlined />}
                          disabled={!roleUpdate}
                          loading={uploadLoading}
                        >
                          Upload Hình ảnh
                        </Button>
                      </Upload>
                      {srcList?.awardReport.value && (
                        <img
                          onClick={() => {
                            setImageUrl(srcList.awardReport.value);
                            setIsOpenModal('previewImg');
                          }}
                          src={srcList.awardReport.value}
                          alt="uploaded"
                          style={{ width: '50%', marginTop: '20px', cursor: 'pointer' }}
                        />
                      )}
                      {(fieldState.error && !srcList?.awardReport.value) && (
                        <div className="e-customerFirstPrize-errorText">
                          {fieldState.error.message}
                        </div>
                      )}
                    </div>
                  )}
                />
              </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: '32px' }}>
              <Col span={8}>
                <Typography.Text strong style={{ display: 'block', paddingBottom: '12px' }}>
                  {t('customerPrize.receiveRewardReport')}
                </Typography.Text>
                <Controller
                  name="receiveRewardReport"
                  render={({ fieldState }) => (
                    <div className="e-customerFirstPrize">
                      <Upload
                        disabled={uploadLoading || !roleUpdate}
                        maxCount={1}
                        accept="image/*"
                        beforeUpload={() => false}
                        onChange={(data) => handleChangeFileList(data, 'receiveRewardReport')}
                      >
                        <Button
                          icon={<UploadOutlined />}
                          disabled={!roleUpdate}
                          loading={uploadLoading}
                        >
                          Upload Hình ảnh
                        </Button>
                      </Upload>
                      {srcList?.receiveRewardReport.value && (
                        <img
                          onClick={() => {
                            setImageUrl(srcList.receiveRewardReport.value);
                            setIsOpenModal('previewImg');
                          }}
                          src={srcList.receiveRewardReport.value}
                          alt="uploaded"
                          style={{ width: '50%', marginTop: '20px', cursor: 'pointer' }}
                        />
                      )}
                      {(fieldState.error && !srcList?.receiveRewardReport.value) && (
                        <div className="e-customerFirstPrize-errorText">
                          {fieldState.error.message}
                        </div>
                      )}
                    </div>
                  )}
                />
              </Col>
              <Col span={8}>
                <Typography.Text strong style={{ display: 'block', paddingBottom: '12px' }}>
                  {t('customerPrize.awardImage')}
                </Typography.Text>
                <Controller
                  name="awardImage"
                  render={({ fieldState }) => (
                    <div className="e-customerFirstPrize">
                      <Upload
                        disabled={uploadLoading || !roleUpdate}
                        maxCount={1}
                        accept="image/*"
                        beforeUpload={() => false}
                        onChange={(data) => handleChangeFileList(data, 'awardImage')}
                      >
                        <Button
                          icon={<UploadOutlined />}
                          disabled={!roleUpdate}
                          loading={uploadLoading}
                        >
                          Upload Hình ảnh
                        </Button>
                      </Upload>
                      {srcList?.awardImage.value && (
                        <img
                          onClick={() => {
                            setImageUrl(srcList.awardImage.value);
                            setIsOpenModal('previewImg');
                          }}
                          src={srcList.awardImage.value}
                          alt="uploaded"
                          style={{ width: '50%', marginTop: '20px', cursor: 'pointer' }}
                        />
                      )}
                      {(fieldState.error && !srcList?.awardImage.value) && (
                        <div className="e-customerFirstPrize-errorText">
                          {fieldState.error.message}
                        </div>
                      )}
                    </div>
                  )}
                />
              </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: '32px' }}>
              <Col span={12}>
                <Typography.Text strong style={{ display: 'block', marginTop: '12px' }}>
                  {t('customerPrize.address')}
                </Typography.Text>
                <Controller
                  name="address"
                  render={({ field, fieldState }) => (
                    <Input
                      disabled={roleUpdate ? false : !getPermission(roleOther, roles.CUSTOMER_PRIZE_UPDATE_CC)}
                      className="u-mt-8"
                      name="title"
                      value={field.value}
                      onChange={field.onChange}
                      error={fieldState.error?.message}
                      size="large"
                    />
                  )}
                />
              </Col>
              <Col span={12}>
                <Typography.Text strong style={{ display: 'block', marginTop: '12px' }}>
                  {t('customerPrize.contactAt')}
                </Typography.Text>
                <Controller
                  name="contactAt"
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <>
                      <DatePicker
                        disabled={roleUpdate ? false : !getPermission(roleOther, roles.CUSTOMER_PRIZE_UPDATE_CC)}
                        size="large"
                        className="u-mt-8"
                        style={{ width: '100%' }}
                        showTime
                        value={value ? dayjs(value) : null}
                        placeholder="Chọn"
                        format="YYYY-MM-DD HH:mm:ss"
                        allowClear
                        onChange={(quotaDate) => onChange(quotaDate?.toISOString() || '')}
                      />
                      {
                        error && (
                          <span className="a-input_errorMessage">
                            {error.message}
                          </span>
                        )
                      }
                    </>
                  )}
                />
              </Col>
              <Col span={24} className="u-mt-16">
                <Typography.Text strong style={{ display: 'block', marginTop: '12px' }}>
                  {t('customerPrize.noteAgent')}
                </Typography.Text>
                <Controller
                  name="noteAgent"
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <TextArea
                        disabled={roleUpdate ? false : !getPermission(roleOther, roles.CUSTOMER_PRIZE_UPDATE_CC)}
                        style={{ width: '100%' }}
                        className="u-mt-8"
                        name="title"
                        value={field.value}
                        onChange={field.onChange}
                        size="large"
                      />
                      {
                        error && (
                          <span className="a-input_errorMessage">
                            {error.message}
                          </span>
                        )
                      }
                    </>
                  )}
                />
              </Col>
            </Row>
          </FormProvider>
          <Modal
            open={isOpenModal === 'previewImg'}
            title="Image Preview"
            footer={null}
            onCancel={() => {
              setIsOpenModal(null);
              setImageUrl('');
            }}
            className="e-customerFirstPrize-modal"
          >
            <img alt="example" style={{ width: '100%' }} src={imageUrl} />
          </Modal>
          <Modal
            width="30vw"
            confirmLoading={updateStatusLoading}
            open={isOpenModal === 'updateStatus'}
            title={t('customerPrize.updateStatus')}
            // footer={null}
            onCancel={() => {
              setIsOpenModal(null);
            }}
            onOk={handleUpdateStatus}
          // className="e-customerFirstPrize-modal"
          >
            <FormProvider {...updateStatusMethod}>
              <Typography.Text strong>
                {t('callCenter.cancelReason')}
              </Typography.Text>
              <Controller
                name="noteCancel"
                render={({ field, fieldState }) => (
                  <Input
                    className="u-mt-8"
                    name="title"
                    value={field.value}
                    onChange={field.onChange}
                    size="large"
                    error={fieldState.error?.message}
                  />
                )}
              />
            </FormProvider>
          </Modal>
        </Spin>
      </div>
    </>
  );
};

export default CustomerFirstPrizeDetail;
