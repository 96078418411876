import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Space, Tooltip, Typography
} from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { useAppSelector } from 'app/store';
import { getTopupLimitReportService } from 'common/services/dashboard';
import { usedLimitTopUpTooltips } from 'common/utils/constant';
import { formatNumberWithSeparators } from 'common/utils/functions';
import roles, { getPermission } from 'configs/roles';
import { PhaseContext } from 'features/Home';

const ReportLimit: React.FC = () => {
  const { t } = useTranslation();
  const phase = useContext(PhaseContext);
  const rolesUser = useAppSelector((state) => state.auth.roles);

  const { data } = useQuery(
    ['get-limit-data', phase.phaseExecute],
    () => getTopupLimitReportService({
      phase: phase.phaseExecute >= 0 ? Number(phase.phaseExecute) : undefined
    }),
    {
      enabled: getPermission(rolesUser, roles.DASHBOARD_TOPUP_LIMIT_REPORT)
        && phase.phaseExecute !== -1
    }
  );
  return (
    <div className="t-reportLimited">
      <div className="t-reportLimited_title">
        <Space>
          <Typography.Title level={4} className="fs-16x24 color-brand-color2 t-reportLimited_title">{t('dashboard.reportLimitTitle')}</Typography.Title>
          <Tooltip
            placement="right"
            title={() => usedLimitTopUpTooltips.map((item, idx) => (
              <Typography.Text
                style={{ display: 'block' }}
                className="u-mt-16 fs-16x24 color-white"
                key={`tooltip-topup-avg-${idx.toString()}`}
              >
                {`${t(item.label)}: ${t(item.note)}`}
              </Typography.Text>
            ))}
          >
            <QuestionCircleOutlined
              style={{ fontSize: 20 }}
              className="o-reportTopUpAvgTime_note"
            />
          </Tooltip>
        </Space>
      </div>
      <div className="t-reportLimited_content u-mt-8">
        <div className="t-reportLimited_content_item">
          <Space direction="vertical" style={{ width: '100%' }}>
            <Typography.Text className="t-reportLimited_content_item-heading" type="secondary">
              {t('dashboard.totalLimit')}
            </Typography.Text>
            <Typography.Text className="t-reportLimited_content_item-value">{formatNumberWithSeparators(data?.totalLimit || 0)}</Typography.Text>
          </Space>
        </div>
        <div className="t-reportLimited_content_item">
          <Space direction="vertical" style={{ width: '100%' }}>
            <Typography.Text className="t-reportLimited_content_item-heading" type="secondary">
              {t('dashboard.usedLimit')}
            </Typography.Text>
            <Typography.Text className="t-reportLimited_content_item-value">{formatNumberWithSeparators((data?.usedLimit || 0))}</Typography.Text>
          </Space>
        </div>
        <div className="t-reportLimited_content_item">
          <Space direction="vertical" style={{ width: '100%' }}>
            <Typography.Text className="t-reportLimited_content_item-heading" type="secondary">
              {t('dashboard.remainingLimit')}
            </Typography.Text>
            <Typography.Text className="t-reportLimited_content_item-value">{formatNumberWithSeparators(data?.remainingLimit || 0)}</Typography.Text>
          </Space>
        </div>
      </div>
    </div>
  );
};

export default ReportLimit;
