import axiosInstance from '../common/instance';

import {
  PrizeConfigData, PrizeFormData, TopupConfigData, TopupConfigFormData
} from './types';

/* Topup */
export const getTopupConfigService = async (): Promise<TopupConfigData> => {
  const res = await axiosInstance.get('config-topup');
  return res.data.data;
};

export const saveTopupConfigService = async (data: TopupConfigFormData): Promise<void> => {
  await axiosInstance.post('config-topup', data);
};

/* First Prize */
export const getFirstPrizeConfigService = async (): Promise<PrizeConfigData> => {
  const res = await axiosInstance.get('config-first-prize');
  return res.data.data;
};

export const saveFirstPrizeConfigService = async (data: PrizeFormData): Promise<void> => {
  await axiosInstance.post('config-first-prize', data);
};

/* Special Prize */
export const getSpecialPrizeConfigService = async (): Promise<PrizeConfigData> => {
  const res = await axiosInstance.get('config-special-prize');
  return res.data.data;
};

export const saveSpecialPrizeConfigService = async (data: PrizeFormData): Promise<void> => {
  await axiosInstance.post('config-special-prize', data);
};
