import {
  Modal, Typography
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import PageTable from 'common/components/PageTable';
import { StatusBuyCardLabel } from 'common/components/StatusLabel';
import { BuyCardStatusEnum, BuyCardTransactionItemTypes, TopupAttemptItemTypes } from 'common/services/topupRequest/types';
import { formatDateTime } from 'common/utils/functions';

interface PopupBuyCardTransactionsProps {
  list?: TopupAttemptItemTypes['buyCards'];
  isOpen?: boolean;
  isLoading?: boolean;
  handleClose: () => void;
}

const PopupBuyCardTransactions: React.FC<PopupBuyCardTransactionsProps> = ({
  isOpen, list, handleClose
}) => {
  const { t } = useTranslation();

  const detectStatus = (status: boolean | null) => {
    if (status === null) return BuyCardStatusEnum.Processing;
    if (status) return BuyCardStatusEnum.Success;
    return BuyCardStatusEnum.Failed;
  };

  /* Datas */
  const columns: ColumnsType<BuyCardTransactionItemTypes> = useMemo(
    () => [
      {
        title: 'Transaction ID',
        key: 'transactionId',
        align: 'center',
        render: (_name: string, data: BuyCardTransactionItemTypes) => (
          <Typography.Text>{data.uid}</Typography.Text>
        ),
      },
      {
        title: t('topupAttempt.buyCardStatus'),
        dataIndex: 'buyCardStatus',
        key: 'buyCardStatus',
        align: 'center',
        render: (_name: string, data: BuyCardTransactionItemTypes) => (
          <StatusBuyCardLabel status={detectStatus(data.successful)} />
        ),
      },
      // --- Cập nhật
      {
        title: t('system.createdAt'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 200,
        sorter: {
          compare: (a: BuyCardTransactionItemTypes, b: BuyCardTransactionItemTypes) => {
            const aDate = new Date(a.createdAt || 0);
            const bDate = new Date(b.createdAt || 0);
            return aDate.getTime() - bDate.getTime();
          },
        },
        sortDirections: ['descend', 'ascend'],
        render: (_name: string, data: BuyCardTransactionItemTypes) => (
          <Typography.Text>{formatDateTime(data.createdAt)}</Typography.Text>
        ),
      },
    ],
    [t]
  );

  const formatData = useMemo(() => list?.map((item, idx) => ({
    ...item,
    id: idx + 1,
  })), [list]);

  return (
    <Modal
      className="modal-detail"
      width="60vw"
      title={(
        <Typography.Title level={3}>
          {' '}
          {t('topupRequest.buyCardTransactions')}
        </Typography.Title>
      )}
      visible={isOpen}
      centered
      cancelButtonProps={{ style: { display: 'none' } }}
      onOk={handleClose}
      onCancel={handleClose}
    >
      {/* <Spin size="large" spinning={isLoading}> */}
      <PageTable
        isLoading={false}
        noCheckbox
        roles={{
          roleCreate: false,
          roleUpdate: false,
          roleDelete: false,
        }}
        tableProps={{
          initShowColumns: [
            'transactionId',
            'buyCardStatus',
            'createdAt'
          ],
          columns,
          pageData: formatData,
          noBaseCol: true,
          noDeleteLanguage: true,
        }}

      />
      {/* </Spin> */}
    </Modal>
  );
};
export default PopupBuyCardTransactions;
